import { createReducer } from '@reduxjs/toolkit';

import * as SensorActions from './SensorActions';

const initialState = {
  isLoading: false,
  sensors: [],
  hasSensors: false,
  errorMessage: '',
};

export const SensorReducer = createReducer(initialState, {
  [SensorActions.GET_SENSORS_REQUEST]: state => {
    return {
      ...state,
      isLoading: true,
      sensors: [],
      hasSensors: false,
      errorMessage: initialState.errorMessage,
    };
  },
  [SensorActions.GET_SENSORS_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      sensors: payload.rows,
      hasSensors: payload.rows && payload.rows.length > 0,
      errorMessage: initialState.errorMessage,
    };
  },
  [SensorActions.GET_SENSORS_FAILURE]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      isLoading: false,
      sensors: initialState.sensors,
      hasSensors: initialState.hasSensors,
      errorMessage: payload,
    };
  },
});
