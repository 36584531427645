import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { Parser } from 'json2csv';
import { useTranslation } from 'react-i18next';

import SearchBox from '../../Components/Inputs/SearchBox/SearchBox';
import AuthorizedButton from '../../Components/AuthorizedButton/AuthorizedButton';
import MeterHistoryModal from '../../Components/Modal/MeterHistoryModal/MeterHistoryModal';
import GenerateReportModal from '../../Components/Modal/GenerateReportModal/GenerateReportModal';
import { convertReportsTableData, filterData } from '../../Helpers/HelperMethods';
import { CommonSorting, ReportingSorting, UserRoles } from '../../Helpers/Enums';
import * as Common from '../../Helpers/Common';
import * as ReportActions from '../../Store/Report/ReportActions';

export default function ReportsCSV() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openGenerateReportModal, setOpenGenerateReportModal] = useState(false);
  const [openMeterHistoryModal, setOpenMeterHistoryModal] = useState(false);

  const { reportsData, reportsSortedAndFilteredData, reportsExportTableCSVData, reportsSortConfig, reportingSensors } = useSelector(
    state => state.reports
  );

  const json2csvParser = new Parser({
    delimiter: ';',
    includeEmptyRows: true,
    excelStrings: false,
  });

  const openModal = () => {
    setOpenGenerateReportModal(true);
  };

  const handleSearchClick = searchStr => {
    const data = searchStr ? filterData(searchStr, reportsData) : reportsData;

    dispatch(
      ReportActions.setReportSortConfig(
        searchStr
          ? { ...reportsSortConfig, searchStr: searchStr }
          : {
              ...reportsSortConfig,
              searchStr: searchStr,
              direction: CommonSorting.descending,
              key: ReportingSorting.readingTime,
            }
      )
    );
    dispatch(ReportActions.clearReportsExportTableCSVData());
    dispatch(ReportActions.setReportsSortedAndFilteredData(data));
  };

  const handleExportTableData = () => {
    const dataToConvertToCSV = convertReportsTableData(reportsSortedAndFilteredData, reportingSensors);
    dispatch(ReportActions.setReportsExportTableCSVData(json2csvParser.parse(dataToConvertToCSV)));
  };

  return (
    <React.Fragment>
      <div className='grid'>
        <div className='grid--end-sm grid__col--sm-12'>
          <AuthorizedButton
            requiredRole={UserRoles.GENERATE_BILLING}
            className='btn btn--primary'
            onClick={openModal}
            dataTestId='openGenerateReportModal'
            childElement={t('navigation.reports.generate_report')}
          />
        </div>
      </div>
      <div className='grid'>
        <div className='grid__col--lg-12 margin'>
          <div className='grid'>
            <div className='grid__col--sm-6 grid__col--md-3'>
              <SearchBox
                onClick={handleSearchClick}
                placeholder={t('navigation.reports.search')}
                component={'report'}
                action={'reportsList'}
                showClearBtn={true}
              />
            </div>
            <div className='grid__col--sm-6 grid__col--md-3'>
              {!reportsExportTableCSVData && (
                <button className='btn btn--primary' data-testid={'export-btn'}>
                  <span className='btn__text' onClick={handleExportTableData}>
                    {t('navigation.reports.export_table')}
                  </span>
                </button>
              )}
              {reportsExportTableCSVData && (
                <CSVLink
                  data={reportsExportTableCSVData}
                  filename={`readings-${moment(moment.now()).format(Common.fileNameDateTimeFormat)}.csv`}
                  className='btn btn--primary'
                  target='_blank'
                  data-testid={'download-btn'}
                >
                  {t('navigation.reports.download_report')}
                </CSVLink>
              )}
            </div>
            <div className='grid__col--sm-6 grid__col--md-6 grid--end-md'>
              <button className='btn btn--primary' data-testid={'history-btn'}>
                <span className='btn__text' onClick={() => setOpenMeterHistoryModal(true)}>
                  {t('navigation.reports.meter_history')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {openGenerateReportModal && <GenerateReportModal open={openGenerateReportModal} setOpen={setOpenGenerateReportModal} />}
      {openMeterHistoryModal && <MeterHistoryModal open={openMeterHistoryModal} setOpen={setOpenMeterHistoryModal} />}
    </React.Fragment>
  );
}
