import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MetersToGroupModal from '../Modal/MetersToGroupModal/MetersToGroupModal';
import ResourceGroupModal from '../Modal/ResourceGroupModal/ResourceGroupModal';
import ConfirmationModal from '../Modal/ConfirmationModal/ConfirmationModal';
import AuthorizedButton from '../AuthorizedButton/AuthorizedButton';
import { showWarnNotification } from '../../Services/Notification/NotificationHandler';
import { isRealResourceGroup } from '../../Helpers/HelperMethods';
import { ROUTE_PATHS } from '../../Routes/Routes';
import { ResourceGroupCrud, UserRoles } from '../../Helpers/Enums';
import * as CompareActions from '../../Store/Compare/CompareActions';
import * as ResourceGroupActions from '../../Store/ResourceGroup/ResourceGroupActions';
import * as ManagementActions from '../../Store/Management/ManagementActions';

export default function ActionsWithMeter() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [metersToGroupModalOpen, setMetersToGroupModalOpen] = useState(false);
  const [resourceGroupModalOpen, setResourceGroupModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [groupMode, setGroupMode] = useState(ResourceGroupCrud.edit);

  const { activeGroup, selectedMeters } = useSelector(state => state.management);

  const goToCompare = () => {
    if (selectedMeters.length < 2 || selectedMeters.length > 10) {
      showWarnNotification(t('notification.warn.compareInvalidNoOfDevices'));
      return;
    }
    dispatch(CompareActions.setDevicesFromManageView(selectedMeters));
    history.push(ROUTE_PATHS.COMPARE);
  };

  const openMetersToGroupModal = () => {
    if (selectedMeters.length < 1) {
      showWarnNotification(t('notification.warn.metersToGroupModal'));
      return;
    }
    setMetersToGroupModalOpen(true);
  };

  const openAddNewGroupModal = mode => {
    setGroupMode(mode);
    setResourceGroupModalOpen(true);
  };

  const deleteGroup = () => {
    dispatch(
      ResourceGroupActions.resourceGroupRequest({
        id: activeGroup ? activeGroup.id : null,
        mode: ResourceGroupCrud.delete,
      })
    );
    dispatch(ManagementActions.setActiveGroup({}));
    setConfirmationModalOpen(false);
  };

  const closeGroupModalOpenConfirm = () => {
    setResourceGroupModalOpen(false);
    setConfirmationModalOpen(true);
  };

  return (
    <React.Fragment>
      <div className='btn-group btn-group--right'>
        <AuthorizedButton
          requiredRole={UserRoles.EDIT}
          className='btn'
          onClick={() => openMetersToGroupModal()}
          dataTestId='btnEditMeters'
          childElement={t('navigation.manage.resource_group.manage_meters')}
        />

        {isRealResourceGroup(activeGroup) && activeGroup.id && (
          <AuthorizedButton
            requiredRole={UserRoles.EDIT}
            className='btn'
            onClick={() => openAddNewGroupModal(ResourceGroupCrud.edit)}
            dataTestId='btnEditGroup'
            childElement={t('navigation.manage.resource_group.edit_group')}
          />
        )}

        {(!isRealResourceGroup(activeGroup) || !activeGroup.id) && (
          <AuthorizedButton
            requiredRole={UserRoles.EDIT}
            className='btn'
            onClick={() => openAddNewGroupModal(ResourceGroupCrud.add)}
            dataTestId='btnAddNewGroup'
            childElement={t('navigation.manage.resource_group.add_new_group')}
          />
        )}

        <button className='btn' onClick={() => goToCompare()} data-testid='btnGoToCompare'>
          {t('navigation.manage.meter_list.headers.compare')}
        </button>
      </div>
      {metersToGroupModalOpen && <MetersToGroupModal open={metersToGroupModalOpen} setOpen={setMetersToGroupModalOpen} />}
      {resourceGroupModalOpen && (
        <ResourceGroupModal
          open={resourceGroupModalOpen}
          setOpen={setResourceGroupModalOpen}
          existingItem={activeGroup}
          mode={groupMode}
          confirmFunc={closeGroupModalOpenConfirm}
        />
      )}
      {confirmationModalOpen && (
        <ConfirmationModal
          open={confirmationModalOpen}
          setOpen={setConfirmationModalOpen}
          headerText={t('navigation.alerts.alert_list.close_confirmation')}
          modalText={t('navigation.manage.resource_group.delete_group', {
            name: activeGroup.name ? activeGroup.name : '-',
          })}
          confirmFunc={deleteGroup}
        />
      )}
    </React.Fragment>
  );
}
