export const isDevelopment = process.env.NODE_ENV === 'development';
export const userLocale = window.navigator.language.slice(0, 2);

export const getIsIE = () => {
  const userAgent = window.navigator.userAgent;
  const msie = userAgent.indexOf('MSIE ') > -1;
  const msie11 = userAgent.indexOf('Trident/') > -1;
  const isEdge = userAgent.indexOf('Edge/') > -1;

  return msie || msie11 || isEdge;
};
