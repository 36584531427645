import styled from 'styled-components';

export const ResourceGroupItemStyles = styled.div`
  .pricebar__price {
    background-color: var(--light-grey);
  }

  .selected {
    background-color: white;
  }

  .pricebar-group {
    display: block;
    border-left: none;
    border-right: none;
    background-color: var(--light-grey2);
  }
`;
