import React from 'react';
import moment from 'moment';

import { dateTimeFormat } from '../../../../Helpers/Common';
import ExpandedRow from '../ExpandedRow/ExpandedRow';

export default function DataRow(props) {
  const { deviceEventLog } = props;

  return (
    <tbody>
      {deviceEventLog.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <tr>
              <td>{moment(item.createdDate).format(dateTimeFormat)}</td>
              <td>{item.meterId}</td>
              <td>{item.address}</td>
              <td className='descTableData'>{item.description}</td>
              <td className='cell--icon'>
                <button
                  className='btn btn--icon collapse-trigger collapsed'
                  data-toggle='collapse'
                  data-target={`#${item.id}`}
                  data-testid={'btnDeviceEventLogExpandedRow'}
                >
                  <svg className='icon  btn__icon collapse-trigger__icon'>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-down`} />
                  </svg>
                </button>
              </td>
            </tr>
            <ExpandedRow item={item} />
          </React.Fragment>
        );
      })}
    </tbody>
  );
}
