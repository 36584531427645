import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ReportingDataRow from './Data/ReportingDataRow';
import Loader from '../../Loader/Loader';
import Column from '../Column/Column';
import { sortReportsTableData } from '../../../Helpers/HelperMethods';
import { ReportingSorting } from '../../../Helpers/Enums';
import * as ReportActions from '../../../Store/Report/ReportActions';

export default function ReportTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    reportsData,
    reportsSortedAndFilteredData,
    isReportsLoading,
    isReportsSensorsLoading,
    reportsSortConfig,
    reportingSensors,
    reportingDataErrorMessage,
    reportingSensorsErrorMessage,
  } = useSelector(state => state.reports);

  useEffect(() => {
    if (reportsData.length === 0 && !reportingDataErrorMessage && !isReportsLoading) {
      dispatch(ReportActions.getReportsRequest());
    }
  }, [dispatch, reportsData, reportingDataErrorMessage, isReportsLoading]);

  useEffect(() => {
    if (reportingSensors.length === 0 && !reportingSensorsErrorMessage && !isReportsSensorsLoading) {
      dispatch(ReportActions.getReportingSensorsRequest());
    }
  }, [dispatch, reportingSensors, reportingSensorsErrorMessage, isReportsSensorsLoading]);

  const setSortConfig = sortConfig => {
    const dataToSort = reportsSortConfig.searchStr ? reportsSortedAndFilteredData.slice() : reportsData.slice();
    const sortedData = sortReportsTableData(dataToSort, sortConfig);

    dispatch(ReportActions.setReportSortConfig(sortConfig));
    dispatch(ReportActions.setReportsSortedAndFilteredData(sortedData));
  };

  return (
    <>
      {!isReportsLoading ? (
        <div className='grid grid--center-lg'>
          <div className='grid__col--lg-12 margin'>
            <table className='tbl tbl--borders tbl--light tbl--row-highlight ' data-testid={'reports-table'}>
              <thead>
                <tr>
                  {Object.keys(ReportingSorting).map(o => {
                    return (
                      <Column
                        key={ReportingSorting[o]}
                        text={t(`navigation.reports.${[ReportingSorting[o]]}`)}
                        sortKey={ReportingSorting[o]}
                        sortConfig={reportsSortConfig}
                        setSortConfig={setSortConfig}
                      />
                    );
                  })}
                  {reportingSensors.map(sensor => {
                    return (
                      <Column
                        key={sensor.sensorId}
                        text={sensor.name}
                        sortKey={sensor.sensorId}
                        sortConfig={reportsSortConfig}
                        setSortConfig={setSortConfig}
                      />
                    );
                  })}
                  <th />
                </tr>
              </thead>
              <tbody>
                {reportsSortedAndFilteredData.map(device => {
                  return <ReportingDataRow key={device.deviceIdentifier} sensors={reportingSensors} device={device} />;
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
