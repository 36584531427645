import { createAction } from '@reduxjs/toolkit';

export const GET_DEVICE_FOR_COMPARE_REQUEST = createAction('GET_DEVICE_FOR_COMPARE_REQUEST');
export const getDeviceForCompareRequest = payload => GET_DEVICE_FOR_COMPARE_REQUEST(payload);

export const GET_DEVICE_FOR_COMPARE_SUCCESS = createAction('GET_DEVICE_FOR_COMPARE_SUCCESS');
export const getDeviceForCompareSuccess = payload => GET_DEVICE_FOR_COMPARE_SUCCESS(payload);

export const GET_DEVICE_FOR_COMPARE_FAILURE = createAction('GET_DEVICE_FOR_COMPARE_FAILURE');
export const getDeviceForCompareFailure = payload => GET_DEVICE_FOR_COMPARE_FAILURE(payload);

export const GET_READINGS_FOR_COMPARE_REQUEST = createAction('GET_READINGS_FOR_COMPARE_REQUEST');
export const getReadingsForCompareRequest = payload => GET_READINGS_FOR_COMPARE_REQUEST(payload);

export const GET_READINGS_FOR_COMPARE_SUCCESS = createAction('GET_READINGS_FOR_COMPARE_SUCCESS');
export const getReadingsForCompareSuccess = payload => GET_READINGS_FOR_COMPARE_SUCCESS(payload);

export const GET_READINGS_FOR_COMPARE_FAILURE = createAction('GET_READINGS_FOR_COMPARE_FAILURE');
export const getReadingsForCompareFailure = payload => GET_READINGS_FOR_COMPARE_FAILURE(payload);

export const SET_SENSOR_TO_COMPARE = createAction('SET_SENSOR_TO_COMPARE');
export const setSensorToCompare = payload => SET_SENSOR_TO_COMPARE(payload);

export const REMOVE_DEVICE_TO_COMPARE = createAction('REMOVE_DEVICE_TO_COMPARE');
export const removeDeviceToCompare = payload => REMOVE_DEVICE_TO_COMPARE(payload);

export const TOGGLE_DEVICE_TO_COMPARE = createAction('TOGGLE_DEVICE_TO_COMPARE');
export const toggleDeviceToCompare = payload => TOGGLE_DEVICE_TO_COMPARE(payload);

export const CHANGE_COMPARE_DURATION = createAction('CHANGE_COMPARE_DURATION');
export const changeCompareDuration = payload => CHANGE_COMPARE_DURATION(payload);

export const CHANGE_COMPARE_END_TIME = createAction('CHANGE_COMPARE_END_TIME');
export const changeCompareEndTime = payload => CHANGE_COMPARE_END_TIME(payload);

export const CLEAR_READINGS_TO_COMPARE = createAction('CLEAR_READINGS_TO_COMPARE');
export const clearReadingsToCompare = payload => CLEAR_READINGS_TO_COMPARE(payload);

export const CLEAR_DEVICES_TO_COMPARE = createAction('CLEAR_DEVICES_TO_COMPARE');
export const clearDevicesToCompare = payload => CLEAR_DEVICES_TO_COMPARE(payload);

export const REFRESH_COMPARE_READINGS = createAction('REFRESH_COMPARE_READINGS');
export const refreshCompareReadings = payload => REFRESH_COMPARE_READINGS(payload);

export const DEVICES_FROM_MANAGE_VIEW = createAction('DEVICES_FROM_MANAGE_VIEW');
export const setDevicesFromManageView = payload => DEVICES_FROM_MANAGE_VIEW(payload);
