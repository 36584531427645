import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';

import * as DeviceEventLogActions from '../../../Store/DeviceEventLog/DeviceEventLogActions';
import { getAddEventLogRequestData, getLatestAlert, hideError } from '../../../Helpers/HelperMethods';
import Header from '../Header/Header';

export default function DeviceEventLogModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { open, setOpen, alertsList, device } = props;
  const [description, setDescription] = useState('');
  const [requestData, setRequestData] = useState({});
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (alertsList) {
      setRequestData(getLatestAlert(alertsList.slice()));
    } else if (device) {
      setRequestData(getAddEventLogRequestData(device));
    }
  }, [alertsList, device]);

  const addDeviceEventLog = () => {
    if (!description) {
      setShowError(true);
      return;
    }
    const payload = {
      description: description,
      alertId: requestData.id,
      deviceId: requestData.deviceId,
      virtualLocationId: requestData.virtualLocationId,
    };

    dispatch(DeviceEventLogActions.addDeviceEventLogRequest(payload));
    handleClose();
  };

  const handleInputChange = event => {
    setDescription(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getDeviceAddress = () => {
    if (alertsList) {
      return requestData.alertData ? JSON.parse(requestData.alertData).deviceAddress : '';
    } else if (device) {
      return requestData.deviceAddress ? requestData.deviceAddress : '';
    }
    return '';
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <Header headerText={`${t('navigation.alerts.alert_list.device_event_log')} - ${getDeviceAddress()}`} handleClose={handleClose} />
      <div className='modal__body'>
        <div className='grid'>
          <div className='grid__col--sm-3'>
            <span className='h4'>{t('navigation.alerts.description')}</span>
          </div>
          <div className='grid__col--sm-9'>
            <textarea
              id={'deviceEventLog-description'}
              value={description}
              onChange={handleInputChange}
              rows='6'
              data-testid='deviceEventLogDesc'
              onFocus={() => hideError(setShowError)}
            />
            {showError && <span className='error'>{t('action.required')}</span>}
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='btn-group btn-group--eq '>
          <button className='btn' onClick={handleClose}>
            <span className='btn__text'>{t('navigation.manage.resource_group.btn_cancel')}</span>
          </button>
          <button className='btn btn--primary' onClick={addDeviceEventLog} data-testid='deviceEventLogBtn'>
            <span className='btn__text'>{t('navigation.alerts.alert_list.device_event_log')}</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
}
