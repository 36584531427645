import styled from 'styled-components';
import 'animate.css/animate.min.css';

export const ArchiveDeviceModalStyles = styled.div`
  .modal__title {
    margin: 0px;
  }

  .activate-btn,
  .activate-btn:not(:disabled):focus,
  .activate-btn:not(:disabled):hover {
    background-color: var(--activate-bg);
  }

  .deactivate-btn,
  .deactivate-btn:not(:disabled):focus,
  .deactivate-btn:not(:disabled):hover {
    background-color: var(--deactivate-bg);
  }
`;
