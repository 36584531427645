import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';

import Header from '../Header/Header';
import { showWarnNotification } from '../../../Services/Notification/NotificationHandler';
import { checkMeterInfoParams, getParamFromDeviceData } from '../../../Helpers/HelperMethods';
import { DeviceParameters } from '../../../Helpers/Enums';

export default function ChangeDeviceIdDiameter(props) {
  const { t } = useTranslation();

  const { open, setOpen, device, changeMeterParams } = props;

  const [deviceId, setDeviceId] = useState(
    getParamFromDeviceData(device, DeviceParameters.meterId) ? getParamFromDeviceData(device, DeviceParameters.meterId) : ''
  );
  const [diameter, setDiameter] = useState(
    getParamFromDeviceData(device, DeviceParameters.diameter) ? getParamFromDeviceData(device, DeviceParameters.diameter) : ''
  );
  const [deviceInfo, setDeviceInfo] = useState(
    getParamFromDeviceData(device, DeviceParameters.deviceInfo) ? getParamFromDeviceData(device, DeviceParameters.deviceInfo) : ''
  );
  const [deviceAddress, setDeviceAddress] = useState(
    getParamFromDeviceData(device, DeviceParameters.address) ? getParamFromDeviceData(device, DeviceParameters.address) : ''
  );
  const [isEndUserMeter, setEndUserMeter] = useState(device.endUserMeter);

  const handleChange = () => {
    setEndUserMeter(!isEndUserMeter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const errorInfo = checkMeterInfoParams(deviceId, diameter, deviceInfo, deviceAddress);
    if (errorInfo.hasError) {
      showWarnNotification(t(errorInfo.errorMsg));
      return;
    }
    changeMeterParams({
      deviceId: device.id,
      newMeterId: deviceId,
      newDiameter: diameter,
      newDeviceInfo: deviceInfo,
      newDeviceAddress: deviceAddress,
      endUserMeter: isEndUserMeter,
      version: device.version,
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <>
        <Header headerText={t('navigation.manage.change_meter_id_modal.title')} handleClose={handleClose} />
        <div className='modal__body'>
          <div className='grid'>
            <div className='grid__col--lg-12'>
              <div className='textfield '>
                <label className='textfield__label' htmlFor='input-1'>
                  {t('navigation.manage.meter_list.headers.address')}
                </label>
                <input
                  type='text'
                  id='changeDeviceAddress'
                  data-testid='changeDeviceAddress'
                  name='input'
                  className='textfield__input '
                  value={deviceAddress}
                  onChange={event => setDeviceAddress(event.target.value)}
                  maxLength={70}
                />
              </div>
            </div>
            <div className='grid__col--lg-12'>
              <div className='textfield '>
                <label className='textfield__label' htmlFor='input-1'>
                  {t('navigation.manage.meter_list.headers.device_info')}
                </label>
                <input
                  type='text'
                  id='changeDeviceInfo'
                  data-testid='changeDeviceInfo'
                  name='input'
                  className='textfield__input '
                  value={deviceInfo}
                  onChange={event => setDeviceInfo(event.target.value)}
                  maxLength={20}
                />
              </div>
            </div>
            <div className='grid__col--lg-12'>
              <div className='textfield '>
                <label className='textfield__label' htmlFor='input-1'>
                  {t('navigation.manage.meter_list.headers.meter_id')}
                </label>
                <input
                  type='text'
                  id='changeDeviceId'
                  data-testid='changeDeviceId'
                  name='input'
                  className='textfield__input '
                  value={deviceId}
                  onChange={event => setDeviceId(event.target.value)}
                />
              </div>
            </div>
            <div className='grid__col--lg-12'>
              <div className='textfield '>
                <label className='textfield__label' htmlFor='input-1'>
                  {t('navigation.manage.meter_list.headers.dn')}
                </label>
                <input
                  type='text'
                  id='changeDiameter'
                  data-testid='changeDiameter'
                  name='input'
                  className='textfield__input '
                  value={diameter}
                  onChange={event => setDiameter(event.target.value)}
                />
              </div>
            </div>
            <div className='grid__col--lg-12'>
              <label className='textfield__label'>Is end user meter</label>
              <label className='checkbox checkbox--styled'>
                <input type='checkbox' className='checkbox__input' checked={isEndUserMeter} onChange={handleChange} />
                <span className='checkbox__indicator'>
                  <svg className='icon checkbox__icon'>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#tick`} />
                  </svg>
                </span>
              </label>
            </div>
          </div>
        </div>
        <div className='modal__footer'>
          <div className='btn-group btn-group--eq '>
            <button className='btn' onClick={handleClose}>
              <span className='btn__text'>{t('navigation.manage.resource_group.btn_cancel')}</span>
            </button>
            <button className='btn btn--primary' onClick={() => handleSubmit()} data-testid='changeDeviceIdDiameter'>
              <span className='btn__text'>{t('navigation.manage.resource_group.btn_save')}</span>
            </button>
          </div>
        </div>
      </>
    </Dialog>
  );
}
