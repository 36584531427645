import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import moment from 'moment';

export default function DateTimePicker(props) {
  const { id, label, value, disableFuture, ariaLabel, setDate } = props;

  const handleDateChange = newValue => {
    setDate(moment(newValue).format());
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} className={'date-picker'}>
      <KeyboardDateTimePicker
        disableToolbar
        variant='inline'
        format='dd.MM.yyyy HH:mm'
        margin='normal'
        id={id}
        label={label}
        value={value}
        onChange={handleDateChange}
        autoOk
        disableFuture={disableFuture}
        ampm={false}
        KeyboardButtonProps={{
          'aria-label': ariaLabel,
          'data-testid': 'dateTimePicker',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

DateTimePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  setDate: PropTypes.func,
  disableFuture: PropTypes.bool,
  ariaLabel: PropTypes.string,
};
