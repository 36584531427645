import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import RouterProvider from '../../Providers/Router/RouterProvider';
import useAuthentication from '../../Hooks/useAuthentication';
import { ROUTE_PATHS, routes } from '../../Routes/Routes';
import { AppMainStyles } from './AppMain.styles';
import { useDispatch, useSelector } from 'react-redux';
import * as CommonActions from '../../Store/Common/CommonActions';
import { NavLink } from 'react-router-dom';

export default function AppMain() {
  const { logout, getAccount } = useAuthentication();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { apiVersion, clientVersion } = useSelector(state => state.common);

  useEffect(() => {
    const getApiVersion = () => {
      dispatch(CommonActions.getApiVersion());
    };

    getApiVersion();
  }, [dispatch]);

  return (
    <AppMainStyles>
      <div className='header'>
        <div className='header__group'>
          <NavLink to={ROUTE_PATHS.DASHBOARD}>
            <img src={`${process.env.PUBLIC_URL}/resources/images/nsv_logo.png`} alt='' className='lazyload img-responsive' />
          </NavLink>
          <nav className='nav nav--horizontal nav--tabs'>
            <ul className='nav__list ' role='menu'>
              <li className='nav__item '>
                <NavLink to={ROUTE_PATHS.DASHBOARD} className='nav__link'>
                  <span className='nav__text'>{t('navigation.dashboard.title')}</span>
                </NavLink>
              </li>
              <li className='nav__item '>
                <NavLink to={ROUTE_PATHS.ALERTS} className='nav__link'>
                  <span className='nav__text'>{t('navigation.alerts.title')}</span>
                </NavLink>
              </li>
              <li className='nav__item '>
                <NavLink to={ROUTE_PATHS.DEVICE_EVENT_LOG} className='nav__link'>
                  <span className='nav__text'>{t('navigation.device_event_log.title')}</span>
                </NavLink>
              </li>
              <li className='nav__item '>
                <NavLink to={ROUTE_PATHS.MANAGE} className='nav__link'>
                  <span className='nav__text'>{t('navigation.manage.title')}</span>
                </NavLink>
              </li>
              <li className='nav__item '>
                <NavLink to={ROUTE_PATHS.REPORT} className='nav__link'>
                  <span className='nav__text'>{t('navigation.reports.title')}</span>
                </NavLink>
              </li>
              <li className='nav__item '>
                <NavLink to={ROUTE_PATHS.COMPARE} className='nav__link'>
                  <span className='nav__text'>{t('navigation.compare.title')}</span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
        <div className='header__group'>
          <div className='header__item header__item--border'>
            <div className='header__user'>
              <h4 className='header__user-name'>
                <div className={'nav__link'}>{getAccount().userName}</div>
              </h4>
            </div>
          </div>
          <div className='header__item header__item--border'>
            <div className='header__user'>
              <h4 className='header__user-name'>
                <button className='btn btn--primary' onClick={logout}>
                  <span className='btn__text'>{t('action.logout')}</span>
                </button>
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div className={'ls__content ls--wrapper '}>
        <RouterProvider routes={routes} />
        <div className={'footer'}>
          <div className={'version'}>
            <span>
              {t('generic.version.api', { version: apiVersion })} {t('generic.version.client', { version: clientVersion })}
            </span>
          </div>
        </div>
      </div>
    </AppMainStyles>
  );
}
