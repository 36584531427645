import React from 'react';

export default function DropDown(props) {
  const { handleChange, data, returnFunction, value, label } = props;

  return (
    <div className='select '>
      <svg className='icon select__icon'>
        <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-updown`} />
      </svg>
      <label className='select__label left-oriented'>{label}</label>
      <select
        className='select__input'
        defaultValue={value}
        onChange={event => {
          handleChange(event.target.value);
        }}
      >
        {data.map((item, index) => {
          return returnFunction(item, index);
        })}
      </select>
    </div>
  );
}
