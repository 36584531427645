import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import RootSaga from './Saga';
import { RootReducer } from './Reducer';
import { isDevelopment } from '../Services/Development/Development';

const sagaMiddleware = createSagaMiddleware();

const reducer = RootReducer;
const middleware = [...getDefaultMiddleware(), sagaMiddleware];

export const store = configureStore({
  reducer,
  middleware,
  devTools: isDevelopment,
});

sagaMiddleware.run(RootSaga);
