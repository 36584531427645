import { put, select } from 'redux-saga/effects';
import moment from 'moment';

import { getExistingDevicesMeterHistory } from './ReportReducer';
import { handleError } from '../../Services/Fetch/HttpResponseHandler';
import { mapIdentifiers } from '../../Helpers/HelperMethods';
import { API_ENDPOINTS, getData } from '../../Services/Fetch/FetchService';
import { NotificationType } from '../../Helpers/Enums';
import { CommonError, dateTimeFormatDashed } from '../../Helpers/Common';
import * as ReportActions from './ReportActions';

export function* getReportsData() {
  try {
    const response = yield getData(`${API_ENDPOINTS.REPORTING}/reading/latest`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(ReportActions.getReportsSuccess(data));
    } else {
      yield put(ReportActions.getReportsFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(ReportActions.getReportsFailure({ responseMessage: handleError(error) }));
  }
}

export function* getReportingSensors() {
  try {
    const response = yield getData(`${API_ENDPOINTS.REPORTING}/sensor/all`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(ReportActions.getReportingSensorsSuccess(data));
    } else {
      yield put(ReportActions.getReportingSensorsFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(ReportActions.getReportingSensorsFailure({ responseMessage: handleError(error) }));
  }
}

export function* getReportsCSVData(payload) {
  const startDate = encodeURIComponent(payload.payload.reportsCSVDate.startDate);
  const endDate = encodeURIComponent(payload.payload.reportsCSVDate.endDate);
  try {
    const response = yield getData(`${API_ENDPOINTS.BILLING}/download?startDate=${startDate}&endDate=${endDate}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(ReportActions.getReportCSVSuccess(data));
    } else {
      yield put(ReportActions.getReportCSVFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(ReportActions.getReportCSVFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* getDeviceForMeterHistory(payload) {
  const searchStr = payload.payload.searchStr ? `&searchStr=${payload.payload.searchStr}` : '';
  try {
    const response = yield getData(`${API_ENDPOINTS.DEVICE_LIST}/?page=0&size=1${searchStr}`);
    if (response.status === 200) {
      const data = yield response.data;
      if (data.content && data.content[0]) {
        (yield select(getExistingDevicesMeterHistory)).forEach(existingDevice => {
          if (data.content[0].id === existingDevice.id) {
            throw new CommonError('error.common.meterHistoryDeviceAlreadyExists', NotificationType.warning, 10000);
          }
        });
        yield put(ReportActions.getDeviceForMeterHistorySuccess(data.content[0]));
      } else {
        yield put(
          ReportActions.getDeviceForMeterHistoryFailure({
            responseMessage: `No device ${payload.payload.searchStr} found`,
          })
        );
      }
    } else {
      yield put(ReportActions.getDeviceForMeterHistoryFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(
      ReportActions.getDeviceForMeterHistoryFailure({
        responseMessage: handleError(error),
      })
    );
    console.error(error);
  }
}

export function* getMeterHistoryCSVData(payload) {
  const startDate = encodeURIComponent(payload.payload.meterHistoryDates.startDate);
  const endDate = encodeURIComponent(payload.payload.meterHistoryDates.endDate);
  const identifiers = mapIdentifiers(payload.payload.devicesForMeterHistory);
  try {
    const response = yield getData(
      `${API_ENDPOINTS.REPORTING}/history/meter?startDateTime=${startDate}&endDateTime=${endDate}&deviceIdentifiers=${identifiers}`,
      'arraybuffer'
    );
    if (response.status === 200 || response.status === 201) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `history-${moment(payload.payload.meterHistoryDates.startDate).format(dateTimeFormatDashed)}.xlsx`);
      document.body.appendChild(link);
      link.click();
      yield put(ReportActions.getMeterHistorySuccess());
    } else {
      yield put(ReportActions.getMeterHistoryFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(ReportActions.getMeterHistoryFailure({ responseMessage: handleError(error.response) }));
  }
}
