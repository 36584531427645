import React from 'react';
import { useSelector } from 'react-redux';

import Compare from '../../Components/Compare/Compare';
import { CompareViewStyles } from './CompareView.styles';

export default function CompareView() {
  const { endTime, duration, devicesForCompare, readingsForCompare, sensorToCompare, isLoading } = useSelector(state => state.compare);

  return (
    <CompareViewStyles>
      <div className='grid grid--center-lg'>
        <div className='grid__col--lg-11 margin'>
          <Compare
            readingsForCompare={readingsForCompare}
            devicesForCompare={devicesForCompare}
            sensorToCompare={sensorToCompare}
            duration={duration}
            endTime={endTime}
            isLoading={isLoading}
          />
        </div>
      </div>
    </CompareViewStyles>
  );
}
