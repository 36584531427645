import React from 'react';
import { useTranslation } from 'react-i18next';

import Input from './Input';
import { showWarnNotification } from '../../../Services/Notification/NotificationHandler';
import { AlertDifferenceTypeEnum } from '../../../Helpers/Enums';

export default function DataRow(props) {
  const { alertCriteries, setAlertCriteries } = props;
  const { t } = useTranslation();

  const tmpCriteries = [...alertCriteries.rows];

  const handleCheckbox = (event, item) => {
    tmpCriteries.map((criteria, index) => {
      if (criteria.name === item.name) {
        tmpCriteries[index] = { ...criteria, enabled: event.target.checked };
      }
      return tmpCriteries;
    });
    setAlertCriteries({ ...alertCriteries, rows: tmpCriteries });
  };

  const handleInput = (parameter, event, item) => {
    if (!event.target.validity.valid) {
      showWarnNotification(t('notification.warn.onlyNumbers'));
      return;
    }
    tmpCriteries.map((criteria, index) => {
      if (criteria.name === item.name) {
        tmpCriteries[index] = { ...criteria, [parameter]: event.target.value };
      }
      return tmpCriteries;
    });
    setAlertCriteries({ ...alertCriteries, rows: tmpCriteries });
  };

  return (
    <tbody>
      {alertCriteries &&
        alertCriteries.rows &&
        alertCriteries.rows.map(param => {
          return (
            <tr key={param.sensorTypeId}>
              <td>
                <label className='checkbox checkbox--styled '>
                  <input
                    type='checkbox'
                    className='checkbox__input'
                    checked={param.enabled}
                    onChange={event => handleCheckbox(event, param)}
                    data-testid='btnParamActive'
                  />
                  <span className='checkbox__indicator'>
                    <svg className='icon checkbox__icon'>
                      <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#tick`} />
                    </svg>
                  </span>
                </label>
              </td>
              <td>{param.name}</td>
              <td>
                <Input
                  disabled={param.differenceType === AlertDifferenceTypeEnum.MIN_MAX}
                  param={param}
                  defaultValue={param.threshold}
                  onChange={handleInput}
                  valueId='threshold'
                  testId='btnHandleValue'
                />
              </td>
              <td>
                <Input
                  disabled={param.differenceType !== AlertDifferenceTypeEnum.MIN_MAX}
                  param={param}
                  defaultValue={param.valueMin}
                  onChange={handleInput}
                  valueId='valueMin'
                  testId='btnHandleValueMin'
                />
              </td>
              <td>
                <Input
                  disabled={param.differenceType !== AlertDifferenceTypeEnum.MIN_MAX}
                  param={param}
                  defaultValue={param.valueMax}
                  onChange={handleInput}
                  valueId='valueMax'
                  testId='btnHandleValueMax'
                />
              </td>
              <td>
                <div className='select '>
                  <svg className='icon select__icon'>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-updown`} />
                  </svg>
                  <select
                    className='select__input'
                    defaultValue={param.differenceType}
                    onChange={event => handleInput('differenceType', event, param)}
                    data-testid='btnHandleValueType'
                  >
                    {Object.keys(AlertDifferenceTypeEnum).map((item, index) => {
                      return (
                        item !== AlertDifferenceTypeEnum.CONTRACT && (
                          <option key={index} value={item}>
                            {t(`navigation.manage.group_params_table.${item}`)}
                          </option>
                        )
                      );
                    })}
                  </select>
                </div>
              </td>
            </tr>
          );
        })}
    </tbody>
  );
}
