import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AlertFilter from '../../Filter/AlertFilter';
import Loader from '../../Loader/Loader';
import ColumnRow from './ColumnRow';
import DataRow from './Data/DataRow';
import Pagination from '../Pagination/Pagination';
import DeviceEventLogModal from '../../Modal/DeviceEventLogModal/DeviceEventLogModal';
import PostponeAlertModal from '../../Modal/PostponeAlertModal/PostponeAlertModal';
import { collapseAllRows, createErrorMsgPrams, customSortForAlertTable, filterAlerts } from '../../../Helpers/HelperMethods';
import * as AlertActions from '../../../Store/Alert/AlertActions';
import { AlertSorting, CommonSorting } from '../../../Helpers/Enums';
import CloseAlertModal from '../../Modal/CloseAlertModal/CloseAlertModal';

export default function AlertTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { alertGroups, hasAlertGroups, isAlertGroupsLoading, alertSortConfig } = useSelector(state => state.alertGroups);

  const [sortedTable, setSortedTable] = useState(alertGroups);
  const [alertsList, setAlertsList] = useState();
  const [postponeModalOpen, setPostponeModalOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [deviceEventLogModalOpen, setDeviceEventLogModalOpen] = useState(false);

  const indexOfLastAlert = alertSortConfig.currentPage * alertSortConfig.perPage;
  const indexOfFirstAlert = indexOfLastAlert - alertSortConfig.perPage;
  const currentAlerts = sortedTable.slice(indexOfFirstAlert, indexOfLastAlert);

  const setSortConfig = sortConfig => {
    dispatch(AlertActions.setAlertSortConfig(sortConfig));
  };

  useEffect(() => {
    if (!hasAlertGroups) {
      dispatch(AlertActions.getAlertsRequest());
    }
  }, [dispatch, hasAlertGroups]);

  useEffect(() => {
    const sortedItems = () => {
      collapseAllRows();
      const sortableItems = filterAlerts(alertGroups, alertSortConfig);
      const customSorted = sortableItems.sort((a, b) => {
        if (alertSortConfig.key === AlertSorting.address) {
          a = JSON.parse(a.alertData);
          b = JSON.parse(b.alertData);
        } else if (alertSortConfig.key === AlertSorting.errorMessage) {
          a = {
            errorMessage: t(`navigation.alerts.alert_type.${a.alertType}`, createErrorMsgPrams(a)),
          };
          b = {
            errorMessage: t(`navigation.alerts.alert_type.${b.alertType}`, createErrorMsgPrams(b)),
          };
        }
        return customSortForAlertTable(a, b, alertSortConfig);
      });

      const statusSorted = customSorted.sort((a, b) => {
        return customSortForAlertTable(a, b, {
          key: AlertSorting.alertStatus,
          direction: alertSortConfig.key === AlertSorting.alertStatus ? alertSortConfig.direction : CommonSorting.ascending,
        });
      });

      setSortedTable(statusSorted);
    };
    sortedItems();
    // eslint-disable-next-line
  }, [alertSortConfig, alertGroups]);

  const openPostponeAlertModal = alerts => {
    setAlertsList(alerts.alerts);
    setPostponeModalOpen(true);
  };

  const openDeviceEventLogModal = alerts => {
    setAlertsList(alerts.alerts);
    setDeviceEventLogModalOpen(true);
  };

  const openCloseAlertModal = alerts => {
    setAlertsList(alerts.alerts);
    setCloseModalOpen(true);
  };

  const totalPages = Math.ceil(sortedTable.length / alertSortConfig.perPage);

  return (
    <>
      {closeModalOpen && <CloseAlertModal open={closeModalOpen} setOpen={setCloseModalOpen} alertsToClose={alertsList} />}
      {postponeModalOpen && <PostponeAlertModal open={postponeModalOpen} setOpen={setPostponeModalOpen} alertsToPostpone={alertsList} />}
      {deviceEventLogModalOpen && (
        <DeviceEventLogModal open={deviceEventLogModalOpen} setOpen={setDeviceEventLogModalOpen} alertsList={alertsList} />
      )}
      {!isAlertGroupsLoading ? (
        <div className='grid grid--center-lg'>
          <div className='grid__col--lg-12 margin'>
            <AlertFilter sortConfig={alertSortConfig} setSortConfig={setSortConfig} />
          </div>
          <div className='grid__col--lg-12 margin'>
            <table className='tbl tbl--transparent tbl--borders tbl--row-highlight'>
              <ColumnRow sortConfig={alertSortConfig} setSortConfig={setSortConfig} />
              <DataRow
                sortedTable={currentAlerts}
                openPostponeAlertModal={openPostponeAlertModal}
                openDeviceEventLogModal={openDeviceEventLogModal}
                openCloseAlertModal={openCloseAlertModal}
              />
            </table>
            <Pagination
              sortConfig={alertSortConfig}
              setSortConfig={setSortConfig}
              totalPages={totalPages}
              label={t('navigation.alerts.alert_list.perPage')}
              totalElements={sortedTable.length}
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
