import React from 'react';
import { useTranslation } from 'react-i18next';

import { AlertSorting } from '../../../Helpers/Enums';
import Column from '../Column/Column';

export default function ColumnRow(props) {
  const { t } = useTranslation();

  const { sortConfig, setSortConfig } = props;

  return (
    <thead>
      <tr>
        <Column
          text={t('navigation.alerts.alert_list.address')}
          sortKey={AlertSorting.address}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
        />
        <Column
          text={t('navigation.alerts.alert_list.count')}
          sortKey={AlertSorting.count}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
        />
        <Column
          text={t('navigation.alerts.alert_list.last_update')}
          sortKey={AlertSorting.maxCreatedDate}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
        />
        <Column
          text={t('navigation.alerts.alert_list.error_text')}
          sortKey={AlertSorting.errorMessage}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
        />
        <Column
          text={t('navigation.alerts.alert_list.status')}
          sortKey={AlertSorting.alertStatus}
          sortConfig={sortConfig}
          setSortConfig={setSortConfig}
        />
        <th />
        <th />
      </tr>
    </thead>
  );
}
