import React from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceEventLog } from '../../../Helpers/Enums';
import Column from '../Column/Column';

export default function ColumnRow(props) {
  const { t } = useTranslation();

  const { sortConfig, setSortConfig } = props;

  return (
    <thead>
      <tr>
        {Object.keys(DeviceEventLog).map(o => {
          return (
            <Column
              key={DeviceEventLog[o]}
              text={t(`navigation.device_event_log.device_event_log_table.${[DeviceEventLog[o]]}`)}
              sortKey={DeviceEventLog[o]}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
            />
          );
        })}
        <th />
      </tr>
    </thead>
  );
}
