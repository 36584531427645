import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { dateTimeFormat, dateTimeFormatShort } from '../../../../Helpers/Common';
import { AlertDifferenceTypeEnum, UserRoles } from '../../../../Helpers/Enums';
import AuthorizedButton from '../../../AuthorizedButton/AuthorizedButton';
import { getColumnCount } from '../../../../Helpers/HelperMethods';

export default function ExpandedRow(props) {
  const { t } = useTranslation();

  const {
    id,
    resourceGroupName,
    meterId,
    deviceIdentifier,
    openPostponeAlertModal,
    openDeviceEventLogModal,
    deviceInfo,
    openCloseAlertModal,
  } = props;

  const { alertsById } = useSelector(state => state.alertByIds);

  const createText = alert => {
    const parseAlertData = JSON.parse(alert.alertData);
    const previousReadingValue = parseFloat(parseAlertData.previousReadingValue).toFixed(1);
    const readingValue = parseFloat(parseAlertData.readingValue).toFixed(1);
    const outsideTemperature = parseFloat(parseAlertData.outsideTemperature).toFixed(1);
    const difference = parseFloat(parseAlertData.difference).toFixed(1);
    const threshold = parseFloat(parseAlertData.threshold);
    const thresholdMinMax = parseAlertData.threshold.split(' ');
    const differenceType = parseAlertData.differenceType;
    if (previousReadingValue && readingValue && difference && threshold) {
      if (differenceType === AlertDifferenceTypeEnum.PERCENTAGE) {
        return ` ${previousReadingValue} -> ${readingValue} (${difference}% > ${threshold}%)`;
      }
      return ` ${previousReadingValue} -> ${readingValue} (${difference} > ${threshold})`;
    } else if (previousReadingValue && readingValue && difference && differenceType === AlertDifferenceTypeEnum.MIN_MAX) {
      return getResponseForMinMax(readingValue, thresholdMinMax);
    } else if (
      previousReadingValue &&
      outsideTemperature &&
      readingValue &&
      difference &&
      differenceType === AlertDifferenceTypeEnum.CONTRACT
    ) {
      return getResponseOutsideTemp(readingValue, thresholdMinMax, outsideTemperature);
    } else {
      return '';
    }
  };

  const getResponseForMinMax = (value, threshold) => {
    const min = parseFloat(threshold[1]);
    const max = parseFloat(threshold[3]);
    const parsedValueToFloat = parseFloat(value);
    if (parsedValueToFloat <= min) {
      return ` ${parsedValueToFloat.toFixed(1)} < ${min}`;
    } else if (parsedValueToFloat >= max) {
      return ` ${parsedValueToFloat.toFixed(1)} > ${max}`;
    } else {
      return ` ${parsedValueToFloat}`;
    }
  };

  const getResponseOutsideTemp = (value, threshold, outside) => {
    const outsideText = t('navigation.alerts.outside');
    const min = parseFloat(threshold[1]);
    const parsedValue = parseFloat(value);
    if (parsedValue < min) {
      return ` ${parsedValue.toFixed(1)} < ${min} (${outsideText}: ${outside})`;
    } else if (parsedValue > min) {
      return ` ${parsedValue.toFixed(1)} > ${min} (${outsideText}: ${outside})`;
    } else {
      return ` (${outsideText}: ${outside})`;
    }
  };

  return (
    <tr className='collapse' id={id}>
      <td colSpan='7'>
        {alertsById &&
          alertsById.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {item[id] && (
                  <div className='grid grid--no-gutter'>
                    <div className='grid__col--sm-12 grid__col--md-7'>
                      <div className='grid__col--sm-12 grid__col--md-6'>
                        <span>{`${t('navigation.alerts.alert_list.group')}: `}</span>
                        <span className='btn--link'>{resourceGroupName ? resourceGroupName : '-'}</span>
                      </div>
                      <div className='grid__col--sm-12 grid__col--md-6'>
                        <span>{`${t('navigation.alerts.alert_list.meterId')}: `}</span>
                        <span className='btn--link'>{meterId ? meterId : '-'}</span>
                      </div>
                      <div className='grid__col--sm-12 grid__col--md-6'>
                        <span>{`${t('navigation.alerts.alert_list.deviceId')}: `}</span>
                        <span className='btn--link'>{deviceIdentifier ? deviceIdentifier : '-'}</span>
                      </div>
                      <div className='grid__col--sm-12 grid__col--md-6'>
                        <span>{`${t('navigation.alerts.alert_list.device_info')}: `}</span>
                        <span className='btn--link'>{deviceInfo ? deviceInfo : '-'}</span>
                      </div>
                      {item[id] && item[id].processingEffectiveUntil && (
                        <div className='grid__col--sm-12 grid__col--md-6'>
                          <span>{`${t('navigation.alerts.alert_list.postponed_until')}: `}</span>
                          <span className='btn--link'>{moment(item[id].processingEffectiveUntil).format(dateTimeFormat)}</span>
                        </div>
                      )}
                    </div>

                    <div className='grid__col--sm-12 grid__col--md-5'>
                      <div className='btn-group '>
                        <button className='btn' onClick={() => openDeviceEventLogModal(item[id])} data-testid='openDeviceEventLogModalBtn'>
                          <span>{t('navigation.alerts.alert_list.device_event_log')}</span>
                        </button>
                        {item[id].alerts[0].alertStatus === 0 && (
                          <AuthorizedButton
                            requiredRole={UserRoles.EDIT}
                            className='btn'
                            onClick={() => openPostponeAlertModal(item[id])}
                            dataTestId='openPostponeModalBtn'
                            childElement={t('navigation.alerts.alert_list.postpone')}
                          />
                        )}
                        <AuthorizedButton
                          requiredRole={UserRoles.EDIT}
                          className='btn'
                          onClick={() => openCloseAlertModal(item[id])}
                          dataTestId='openCloseModalBtn'
                          childElement={t('navigation.alerts.alert_list.close')}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <hr className='separator separator--medium separator--dark ' />
                <div
                  style={{
                    columnCount: getColumnCount(item[id], null, id),
                  }}
                >
                  {item[id] &&
                    item[id].alerts &&
                    item[id].alerts.map((alert, index2) => {
                      return (
                        <div key={index2} className='separator'>
                          <span>[ {moment(alert.createdDate).format(dateTimeFormatShort)} ]</span>
                          {alert.alertType === 3 && <span className='text-error'>{createText(alert)}</span>}
                        </div>
                      );
                    })}
                </div>
              </React.Fragment>
            );
          })}
      </td>
    </tr>
  );
}
