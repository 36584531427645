import { createReducer } from '@reduxjs/toolkit';

import * as ReadingActions from './ReadingActions';

const initialState = {
  isLoading: false,
  readings: [],
  hasReadings: false,
  errorMessage: '',
};

export const ReadingReducer = createReducer(initialState, {
  [ReadingActions.READING_REQUEST]: state => {
    return {
      ...state,
      isLoading: true,
      readings: [],
      hasReadings: false,
      errorMessage: initialState.errorMessage,
    };
  },
  [ReadingActions.READING_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      readings: payload,
      hasReadings: payload && payload.length > 0,
      errorMessage: initialState.errorMessage,
    };
  },
  [ReadingActions.READING_FAILURE]: (state, action) => {
    const { payload } = action;

    return {
      ...state,
      isLoading: false,
      readings: initialState.readings,
      hasReadings: initialState.hasReadings,
      errorMessage: payload,
    };
  },
});

export const getHasReadings = state => {
  return state.readings.hasReadings;
};

export const getReadings = state => {
  return state.readings.readings;
};
