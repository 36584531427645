import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { parseNumberFixed } from '../../../../Helpers/HelperMethods';
import { BoxesStyles } from '../../AlertsRow/Boxes/Boxes.styles';
import NumberWithLabel from '../../Items/NumberWithLabel';

export default function FirstBox() {
  const { t } = useTranslation();

  const { flowReadings } = useSelector(state => state.dashboard);

  return (
    <BoxesStyles>
      <div className='grid box grid--no-gutter padding-bottom flow-top-padding'>
        <div className=' grid__col--sm-6'>
          <NumberWithLabel
            count={flowReadings.flowIn ? parseNumberFixed(flowReadings.flowIn, 1) : '-'}
            label={t('navigation.dashboard.flow.flow_in')}
            large
          />
        </div>
        <div className='grid__col--sm-6'>
          <NumberWithLabel
            count={flowReadings.flowOut ? parseNumberFixed(flowReadings.flowOut, 1) : '-'}
            label={t('navigation.dashboard.flow.flow_out')}
            large
          />
        </div>
      </div>
    </BoxesStyles>
  );
}
