import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import LineGraph from '../../Components/Graphs/LineGraph/LineGraph';
import { GraphicsViewStyles } from './GraphicsView.styles';

export default function GraphicsView() {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <GraphicsViewStyles>
      <div className='grid grid--center-lg'>
        <div className='grid__col--lg-11'>
          <div className='text-right '>
            <button className='btn btn--sm' onClick={() => history.goBack()}>
              {t('navigation.common.back')}
            </button>
          </div>
          <LineGraph />
        </div>
      </div>
    </GraphicsViewStyles>
  );
}
