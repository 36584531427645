import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ROUTE_PATHS } from '../../../../Routes/Routes';
import { UserRoles } from '../../../../Helpers/Enums';
import AuthorizedButton from '../../../AuthorizedButton/AuthorizedButton';
import { DataRowStyles } from './DataRow.styles';

export default function DataRow(props) {
  const {
    devices,
    handleOpenChangeMeterParamsModal,
    handleSelection,
    handleOpenAddEventLogModal,
    handleOpenArchiveDeviceModal,
    selectedMeters,
  } = props;

  const { t } = useTranslation();

  const history = useHistory();

  const goToGraphPage = (virtualLocationId, deviceId) => {
    history.push(`${ROUTE_PATHS.GRAPHICS}?vlId=${virtualLocationId}&deviceId=${deviceId}`);
  };

  const getInactiveLabel = device => {
    return device.archived ? (
      <DataRowStyles>
        <span className='inactive'>{t('navigation.manage.manage_table.inactive_label')}</span>
      </DataRowStyles>
    ) : (
      ''
    );
  };

  const getArchiveIcon = device => {
    return device.archived
      ? `${process.env.PUBLIC_URL}/assets/svg/iconsELV.svg#plus`
      : `${process.env.PUBLIC_URL}/assets/svg/iconsELV.svg#close`;
  };

  const getArchiveTooltip = device => {
    return device.archived ? t('navigation.manage.tooltip.activateDevice') : t('navigation.manage.tooltip.deactivateDevice');
  };

  return (
    <tbody>
      {devices.map(item => {
        return (
          <tr key={item.id}>
            <td>
              <label className='checkbox checkbox--styled '>
                <input
                  type='checkbox'
                  className='checkbox__input'
                  checked={selectedMeters.indexOf(item) !== -1}
                  onChange={() => handleSelection(item)}
                />
                <span className='checkbox__indicator'>
                  <svg className='icon checkbox__icon'>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#tick`} />
                  </svg>
                </span>
              </label>
            </td>
            <td>{getInactiveLabel(item)}</td>
            <td>{item.address}</td>
            <td>{item.identifier}</td>
            <td>{item.meterId}</td>
            <td>{item.deviceInfo}</td>
            <td>{item.diameter}</td>
            <td className='cell--icon'>
              <Tooltip title={<span className='h6'>{t('navigation.manage.tooltip.graphic')}</span>}>
                <button
                  className='btn btn--icon'
                  onClick={() => goToGraphPage(item.virtualLocationId, item.id)}
                  data-testid={'btnGoToGraph'}
                >
                  <svg className='icon  btn__icon'>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/iconsELV.svg#data-lg`} />
                  </svg>
                </button>
              </Tooltip>
            </td>
            <td className='cell--icon'>
              <AuthorizedButton
                withTooltip
                tooltip={t('navigation.manage.tooltip.edit')}
                requiredRole={UserRoles.EDIT}
                className='btn btn--icon'
                onClick={() => handleOpenChangeMeterParamsModal(item)}
                dataTestId='btnOpenChangeDeviceParams'
                childElement={
                  <svg className='icon  btn__icon'>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#pen`} />
                  </svg>
                }
              />
            </td>
            <td className='cell--icon'>
              <Tooltip title={<span className='h6'>{t('navigation.manage.tooltip.addEventLog')}</span>}>
                <button className='btn btn--icon' onClick={() => handleOpenAddEventLogModal(item)} data-testid={'btnAddEventLog'}>
                  <svg className='icon  btn__icon'>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/iconsELV.svg#contract-lg`} />
                  </svg>
                </button>
              </Tooltip>
            </td>

            <td className='cell--icon'>
              <Tooltip title={<span className='h6'>{getArchiveTooltip(item)}</span>}>
                <button className='btn btn--icon' onClick={() => handleOpenArchiveDeviceModal(item)} data-testid={'btnMakeDeviceInactive'}>
                  <svg className='icon  btn__icon'>
                    <use xlinkHref={getArchiveIcon(item)} />
                  </svg>
                </button>
              </Tooltip>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}
