import React from 'react';
import { useTranslation } from 'react-i18next';

import { getAddress, getMeterId } from '../../../Helpers/HelperMethods';

export default function CompareLegendItem(props) {
  const { address, meterId, deviceIdentifier, toggle, remove, seriesVisible } = props;
  const { t } = useTranslation();

  return (
    <div className='grid grid--no-gutter'>
      <div className=' grid__col--lg-5'>
        <div className='grid__col--lg checkbox-margin'>
          <label className='checkbox checkbox--styled '>
            <input
              type='checkbox'
              className='checkbox__input'
              checked={seriesVisible}
              onChange={() => {
                toggle(deviceIdentifier);
              }}
              data-testid='btnParamActive'
            />
            <span className='checkbox__indicator'>
              <svg className='icon checkbox__icon'>
                <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#tick`} />
              </svg>
            </span>
          </label>
        </div>
        <div className='grid__col--lg-5'>
          <p>{getAddress(address)}</p>
        </div>
        <div className='grid__col--lg-3'>
          <p>
            {t('navigation.compare.meterId')} {getMeterId(meterId)}
          </p>
        </div>
        <div className='grid__col--lg-1'>
          <button
            className='btn btn--border btn--xs'
            data-testid='removeDeviceIdentifier'
            onClick={() => {
              remove(deviceIdentifier);
            }}
          >
            <svg className='icon'>
              <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#x`} />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
