import { put } from 'redux-saga/effects';

import * as SensorActions from './SensorActions';
import { API_ENDPOINTS, getData } from '../../Services/Fetch/FetchService';
import { handleError } from '../../Services/Fetch/HttpResponseHandler';

export function* readSensorList() {
  try {
    const response = yield getData(`${API_ENDPOINTS.RESOURCE_GROUP}/config/default`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(SensorActions.getSensorsSuccess(data));
    } else {
      yield put(SensorActions.getSensorsFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(SensorActions.getSensorsFailure({ responseMessage: handleError(error.response) }));
  }
}
