import { createReducer } from '@reduxjs/toolkit';

import * as SearchBoxActions from './SearchBoxActions';

const initialState = {
  management: {
    deviceList: {
      searchBoxInput: '',
    },
  },
  alert: {
    alertList: {
      searchBoxInput: '',
    },
  },
  report: {
    reportsList: {
      searchBoxInput: '',
    },
  },
};

export const SearchBoxReducer = createReducer(initialState, {
  [SearchBoxActions.SAVE_SEARCH_BOX_INPUT]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      [payload.component]: {
        [payload.action]: {
          searchBoxInput: payload.input,
        },
      },
    };
  },
});
