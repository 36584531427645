import { store } from 'react-notifications-component';

import { NotificationType } from '../../Helpers/Enums';

const notifications = [];

const showNotification = (type, text, duration) => {
  if (notifications.length > 4) {
    for (let i = 0; i < notifications.length - 4; i++) {
      store.removeNotification(notifications[i]);
    }
  }

  return store.addNotification({
    message: text,
    type: type,
    insert: 'top',
    container: 'bottom-left',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    width: 450,
    onRemoval: id => {
      notifications.splice(notifications.indexOf(id), 1);
    },
    dismiss: {
      duration: duration ? duration : 5000,
    },
  });
};

export const showInfoNotification = text => {
  notifications.push(showNotification(NotificationType.info, text));
};

export const showSuccessNotification = text => {
  notifications.push(showNotification(NotificationType.success, text, 3000));
};

export const showWarnNotification = text => {
  notifications.push(showNotification(NotificationType.warning, text, 10000));
};

export const showErrorNotification = text => {
  notifications.push(showNotification(NotificationType.danger, text));
};
