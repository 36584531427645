import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../Header/Header';
import Loader from '../../Loader/Loader';
import AuthorizedButton from '../../AuthorizedButton/AuthorizedButton';
import StartEndDateCombo from '../../Inputs/DateTimePicker/StartEndDateCombo';
import { showWarnNotification } from '../../../Services/Notification/NotificationHandler';
import { UserRoles } from '../../../Helpers/Enums';
import { dateTimeFormatDashed } from '../../../Helpers/Common';
import * as ReportActions from '../../../Store/Report/ReportActions';

export default function GenerateReportModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { open, setOpen } = props;

  const { reportsCSVData, reportsCSVLoading, reportsCSVDate } = useSelector(state => state.reports);

  const [startDate, setStartDate] = useState(reportsCSVDate.startDate);
  const [endDate, setEndDate] = useState(reportsCSVDate.endDate);

  useEffect(() => {
    clearReport();
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const handleDownload = () => {
    if (moment(startDate).isAfter(moment(endDate))) {
      showWarnNotification(t('notification.warn.wrongDates'));
      return;
    }
    dispatch(ReportActions.getReportCSVRequest({ reportsCSVDate }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearReport = () => {
    dispatch(ReportActions.clearReportCSVData());
    dispatch(
      ReportActions.setReportCSVDate({
        startDate: startDate,
        endDate: endDate,
      })
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <>
        <Header headerText={t('navigation.reports.generate_report')} handleClose={handleClose} />
        <div className='modal__body'>
          <StartEndDateCombo startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
        </div>
        <div className='modal__footer'>
          <div className='btn-group btn-group--eq '>
            <button className='btn' onClick={handleClose}>
              <span className='btn__text'>{t('navigation.manage.resource_group.btn_cancel')}</span>
            </button>
            {!reportsCSVLoading && reportsCSVData.length === 0 && (
              <AuthorizedButton
                requiredRole={UserRoles.GENERATE_BILLING}
                className='btn btn--primary'
                onClick={handleDownload}
                dataTestId='getReportBtn'
                childElement={t('navigation.reports.generate_report')}
              />
            )}
            {!reportsCSVLoading && reportsCSVData.length > 0 && (
              <CSVLink
                data={reportsCSVData}
                filename={`bill-${moment(reportsCSVDate.startDate).format(dateTimeFormatDashed)}.csv`}
                className='btn btn--primary'
                target='_blank'
                data-testid='downloadCsvBtn'
                onClick={() => setOpen(false)}
              >
                {t('navigation.reports.download_report')}
              </CSVLink>
            )}
            {reportsCSVLoading && <Loader />}
          </div>
        </div>
      </>
    </Dialog>
  );
}
