import { createAction } from '@reduxjs/toolkit';

export const UPDATE_DEVICE_ARCHIVE_REQUEST = createAction('UPDATE_DEVICE_ARCHIVE_REQUEST');
export const updateDeviceArchiveRequest = payload => UPDATE_DEVICE_ARCHIVE_REQUEST(payload);

export const UPDATE_DEVICE_ARCHIVE_SUCCESS = createAction('UPDATE_DEVICE_ARCHIVE_SUCCESS');
export const updateDeviceArchiveSuccess = payload => UPDATE_DEVICE_ARCHIVE_SUCCESS(payload);

export const UPDATE_DEVICE_ARCHIVE_FAILURE = createAction('UPDATE_DEVICE_ARCHIVE_FAILURE');
export const updateDeviceArchiveFailure = payload => UPDATE_DEVICE_ARCHIVE_FAILURE(payload);
