import { put } from 'redux-saga/effects';

import * as DeviceEventLogActions from './DeviceEventLogActions';
import { API_ENDPOINTS, getData, postData } from '../../Services/Fetch/FetchService';
import { handleError, handleSuccess } from '../../Services/Fetch/HttpResponseHandler';

export function* getDeviceEventLogList(payload) {
  const page = payload.payload.page;
  const size = payload.payload.size;
  const sortableRow = payload.payload.sortableRow;
  const sortDirection = payload.payload.sortDirection;
  const searchStr = payload.payload.searchStr ? `&searchStr=${payload.payload.searchStr}` : '';

  try {
    const response = yield getData(
      `${API_ENDPOINTS.DEVICE_EVENT_LOG}/list?page=${page - 1}&size=${size}&sort=${sortableRow},${sortDirection}${searchStr}`
    );
    if (response.status === 200) {
      const data = yield response.data;
      yield put(DeviceEventLogActions.getDeviceEventLogSuccess(data));
    } else {
      yield put(
        DeviceEventLogActions.getDeviceEventLogFailure({
          responseMessage: handleError(response),
        })
      );
    }
  } catch (error) {
    yield put(DeviceEventLogActions.getDeviceEventLogFailure({ responseMessage: handleError(error) }));
  }
}

export function* addDeviceEventLog(payload) {
  try {
    const response = yield postData(`${API_ENDPOINTS.DEVICE_EVENT_LOG}/create`, payload.payload);
    if (response.status === 200) {
      handleSuccess('notification.success.addEventLog');
      yield put(DeviceEventLogActions.addDeviceEventLogSuccess());
    } else {
      yield put(
        DeviceEventLogActions.addDeviceEventLogFailure({
          responseMessage: handleError(response),
        })
      );
    }
  } catch (error) {
    yield put(DeviceEventLogActions.addDeviceEventLogFailure({ responseMessage: handleError(error) }));
  }
}
