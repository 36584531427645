import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FirstBox from './Boxes/FirstBox';
import SecondBox from './Boxes/SecondBox';
import TemperatureBox from './Boxes/TemperatureBox';
import TemperatureBoxReverse from './Boxes/TemperatureBoxReverse';
import { StationTempParams } from '../../../Helpers/Enums';
import { FlowRowStyles } from './FlowRow.styles';

export default function FlowRow() {
  const { t } = useTranslation();

  const { stationTemperatureReadings, hasStationTemperatureReadings } = useSelector(state => state.dashboard);

  const getStationTempParam = (temp, m) => {
    if (!hasStationTemperatureReadings) {
      return '-';
    }
    const tempObject = stationTemperatureReadings.plantData.find(({ sensor, identifier }) => sensor === temp && identifier === m);
    if (!tempObject) {
      return '-';
    }
    return parseFloat(tempObject.readingValue).toFixed(0);
  };

  return (
    <FlowRowStyles>
      <div className='grid'>
        <div className='grid__col--sm-12 grid__col--lg-6'>
          <h3>{t('navigation.dashboard.flow.title')}</h3>
          <div className='grid'>
            <div className='grid__col--sm-12'>
              <div className='grid'>
                <div className='grid__col--sm-6'>
                  <FirstBox />
                </div>
                <div className='grid__col--sm-6'>
                  <SecondBox />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid__col--sm-12 grid__col--lg-6'>
          <h3>{t('navigation.dashboard.flow.circ_temps')}</h3>
          <div className='grid'>
            <div className='grid__col--sm-12'>
              <div className='grid grid--between-sm grid--middle-sm'>
                <div className='grid__col--sm-5'>
                  <TemperatureBox
                    m1={getStationTempParam(StationTempParams.T2, StationTempParams.M1)}
                    m2={getStationTempParam(StationTempParams.T2, StationTempParams.M2)}
                    avg={stationTemperatureReadings.t2Avg ? parseFloat(stationTemperatureReadings.t2Avg).toFixed(0) : '-'}
                    m1Label={t('navigation.dashboard.flow.station_temps.t2m1')}
                    m2Label={t('navigation.dashboard.flow.station_temps.t2m2')}
                    avgLabel={t('navigation.dashboard.flow.station_temps.t2avg')}
                  />
                </div>
                <div className='grid__col--sm-2 grid--center-sm'>
                  <svg className='icon icon--medium plant-icon '>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/iconsELV.svg#nuclear-power-plant`} />
                  </svg>
                </div>
                <div className='grid__col--sm-5'>
                  <TemperatureBoxReverse
                    m1={getStationTempParam(StationTempParams.T1, StationTempParams.M1)}
                    m2={getStationTempParam(StationTempParams.T1, StationTempParams.M2)}
                    avg={stationTemperatureReadings.t1Avg ? parseFloat(stationTemperatureReadings.t1Avg).toFixed(0) : '-'}
                    m1Label={t('navigation.dashboard.flow.station_temps.t1m1')}
                    m2Label={t('navigation.dashboard.flow.station_temps.t1m2')}
                    avgLabel={t('navigation.dashboard.flow.station_temps.t1avg')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FlowRowStyles>
  );
}
