import React, { useState } from 'react';

import { SearchBoxStyles } from './SearchBox.styles';
import { disableClick } from '../../../Helpers/HelperMethods';
import { useDispatch, useSelector } from 'react-redux';
import * as SearchBoxActions from '../../../Store/Input/SearchBox/SearchBoxActions';

export default function SearchBox(props) {
  const { onClick, placeholder, showClearBtn, showLabel, component, action, emptyAfterSearch } = props;

  const dispatch = useDispatch();

  const { searchBoxInput } = useSelector(state => getSearchBoxInputFromState(state));
  const [input, setInput] = useState(searchBoxInput);

  function getSearchBoxInputFromState(state) {
    if (state.searchBox && state.searchBox[component] && state.searchBox[component][action]) {
      return state.searchBox[component][action];
    }
    return { searchBoxInput: '' };
  }

  function handleChange(event) {
    setInput(event.target.value);
    if (event.key === 'Enter') {
      handleClick();
    }
  }

  function handleClick() {
    const inputTrim = input.trim();
    setInput(inputTrim);
    if (disableClick(inputTrim, 2)) {
      return;
    }
    createDispatch(inputTrim);
    onClick(inputTrim);
    document.getElementById('searchBox').blur();
    if (emptyAfterSearch) {
      setInput('');
    }
  }

  const handleClear = () => {
    setInput('');
    createDispatch('');
    onClick('');
  };

  const createDispatch = inputStr => {
    if (component && action) {
      const data = {
        component: component,
        action: action,
        input: inputStr,
      };
      dispatch(SearchBoxActions.saveSearchBoxInput(data));
    }
  };

  return (
    <SearchBoxStyles>
      {showLabel && <label className='select__label'>{placeholder}</label>}
      <div className='textfield'>
        <input
          className='textfield__input'
          aria-label={'searchBox'}
          id={'searchBox'}
          data-testid={'searchBox'}
          type='text'
          value={input}
          onChange={handleChange}
          onKeyPress={handleChange}
          placeholder={placeholder}
        />
        {input && showClearBtn && (
          <button className='btn btn--icon' onClick={handleClear} data-testid={'clearButton'}>
            <svg className='icon'>
              <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#x`} />
            </svg>
          </button>
        )}
        <button className='btn btn--icon' disabled={disableClick(input, 2)} onClick={handleClick} data-testid={'searchButton'}>
          <svg className='icon'>
            <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#search`} />
          </svg>
        </button>
      </div>
    </SearchBoxStyles>
  );
}
