import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StationDataRowStyles } from './StationDataRow.styles';
import StationDataBox from './Boxes/StationDataBox';
import { timeFormat } from '../../../Helpers/Common';
import moment from 'moment';
import Loader from '../../Loader/Loader';

export default function StationDataRow() {
  const { t } = useTranslation();

  const {
    plantPowerReadings,
    hasPlantPowerReadings,
    plantPowerReadingsLoading,
    plantFlowOutReadings,
    hasPlantFlowOutReadings,
    plantFlowOutReadingsLoading,
  } = useSelector(state => state.dashboard);

  const getPlantReadings = plantData => {
    return plantData && plantData.readings
      ? plantData.readings.map(reading => {
          return {
            key: moment(reading.time).format(timeFormat),
            value: Number(parseFloat(reading.value).toFixed(2)),
          };
        })
      : {};
  };
  const plantPowerData = hasPlantPowerReadings ? getPlantReadings(plantPowerReadings) : {};
  const flowOutData = hasPlantFlowOutReadings ? getPlantReadings(plantFlowOutReadings) : {};

  return (
    <StationDataRowStyles>
      <h3>{t('navigation.dashboard.station.title')}</h3>
      <div className='grid'>
        <div className='grid__col--sm-12 grid__col--lg-6'>
          {plantPowerReadingsLoading && !hasPlantPowerReadings && <Loader />}
          {hasPlantPowerReadings && !plantPowerReadingsLoading && (
            <StationDataBox
              data={plantPowerData}
              title={t('navigation.dashboard.station.plant_power')}
              yAxisLabel={t('navigation.dashboard.station.mw')}
              xAxisLabel={t('navigation.dashboard.station.time')}
            />
          )}
        </div>
        <div className='grid__col--sm-12 grid__col--lg-6'>
          {plantFlowOutReadingsLoading && !hasPlantFlowOutReadings && <Loader />}
          {hasPlantFlowOutReadings && !plantFlowOutReadingsLoading && (
            <StationDataBox
              data={flowOutData}
              title={t('navigation.dashboard.station.flow_out')}
              yAxisLabel={t('navigation.dashboard.station.th')}
              xAxisLabel={t('navigation.dashboard.station.time')}
            />
          )}
        </div>
      </div>
    </StationDataRowStyles>
  );
}
