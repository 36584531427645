import { createAction } from '@reduxjs/toolkit';

export const GET_DEVICE_EVENT_LOG_REQUEST = createAction('GET_DEVICE_EVENT_LOG_REQUEST');
export const getDeviceEventLogRequest = payload => GET_DEVICE_EVENT_LOG_REQUEST(payload);

export const GET_DEVICE_EVENT_LOG_SUCCESS = createAction('GET_DEVICE_EVENT_LOG_SUCCESS');
export const getDeviceEventLogSuccess = payload => GET_DEVICE_EVENT_LOG_SUCCESS(payload);

export const GET_DEVICE_EVENT_LOG_FAILURE = createAction('GET_DEVICE_EVENT_LOG_FAILURE');
export const getDeviceEventLogFailure = payload => GET_DEVICE_EVENT_LOG_FAILURE(payload);

export const ADD_DEVICE_EVENT_LOG_REQUEST = createAction('ADD_DEVICE_EVENT_LOG_REQUEST');
export const addDeviceEventLogRequest = payload => ADD_DEVICE_EVENT_LOG_REQUEST(payload);

export const ADD_DEVICE_EVENT_LOG_SUCCESS = createAction('ADD_DEVICE_EVENT_LOG_SUCCESS');
export const addDeviceEventLogSuccess = payload => ADD_DEVICE_EVENT_LOG_SUCCESS(payload);

export const ADD_DEVICE_EVENT_LOG_FAILURE = createAction('ADD_DEVICE_EVENT_LOG_FAILURE');
export const addDeviceEventLogFailure = payload => ADD_DEVICE_EVENT_LOG_FAILURE(payload);

export const SET_EVENT_LOG_SORT_CONFIG = createAction('SET_EVENT_LOG_SORT_CONFIG');
export const setEventLogSortConfig = payload => SET_EVENT_LOG_SORT_CONFIG(payload);
