import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ResourceGroupItem from '../ResourceGroupItem/ResourceGroupItem';
import * as ResourceGroupActions from '../../../Store/ResourceGroup/ResourceGroupActions';
import * as SensorActions from '../../../Store/Sensor/SensorActions';
import { ResourceGroupsStyles } from './ResourceGroups.styles';
import Loader from '../../Loader/Loader';
import { isRealResourceGroup } from '../../../Helpers/HelperMethods';

export default function ResourceGroups() {
  const dispatch = useDispatch();

  const { resourceGroups, isGetAllLoading, hasResourceGroups } = useSelector(state => state.resourceGroup);

  const { hasSensors, sensors } = useSelector(state => state.sensor);

  useEffect(() => {
    if (!hasResourceGroups) {
      dispatch(ResourceGroupActions.getAllResourceGroupsRequest());
    }
  }, [dispatch, hasResourceGroups]);

  useEffect(() => {
    if (!hasSensors) {
      dispatch(SensorActions.getSensorsRequest());
    }
  }, [dispatch, hasSensors]);

  return (
    <ResourceGroupsStyles>
      {isGetAllLoading && <Loader />}

      <div className='pricebar'>
        {hasResourceGroups &&
          resourceGroups.map(group => {
            return (
              isRealResourceGroup(group) && (
                <ResourceGroupItem
                  group={{
                    ...group,
                    alertConfigData: group && group.alertConfigData ? group.alertConfigData : sensors,
                  }}
                  key={group.id}
                />
              )
            );
          })}
      </div>

      <hr className='separator separator--small ' />

      <div className='pricebar'>
        {hasResourceGroups &&
          resourceGroups.map(group => {
            return (
              !isRealResourceGroup(group) && (
                <ResourceGroupItem
                  group={{
                    ...group,
                    alertConfigData: group && group.alertConfigData ? group.alertConfigData.rows : sensors,
                  }}
                  key={group.id}
                />
              )
            );
          })}
      </div>
    </ResourceGroupsStyles>
  );
}
