import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

import Header from '../Header/Header';

export default function ConfirmationModal(props) {
  const { t } = useTranslation();
  const { open, setOpen, headerText, modalText, confirmFunc } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <Header headerText={headerText} handleClose={handleClose} />
      <div className='modal__body'>
        <h5>{modalText}</h5>
      </div>
      <div className='modal__footer'>
        <div className='btn-group btn-group--eq '>
          <button className='btn' onClick={handleClose} data-testid={'btnNo'}>
            <span className='btn__text'>{t('navigation.manage.resource_group.btn_no')}</span>
          </button>
          <button className='btn btn--primary' onClick={confirmFunc} data-testid='btnYes'>
            <span className='btn__text'>{t('navigation.manage.resource_group.btn_yes')}</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  headerText: PropTypes.string,
  modalText: PropTypes.string,
  confirmFunc: PropTypes.func,
};
