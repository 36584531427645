import { put } from 'redux-saga/effects';

import * as ArchiveDeviceActions from './ArchiveDeviceActions';
import { API_ENDPOINTS, postData } from '../../Services/Fetch/FetchService';
import { handleError, handleSuccess } from '../../Services/Fetch/HttpResponseHandler';
import * as ManagementActions from '../Management/ManagementActions';
import * as ResourceGroupActions from '../ResourceGroup/ResourceGroupActions';

export function* updateDeviceArchive(payload) {
  try {
    const response = yield postData(`${API_ENDPOINTS.DEVICE_INFO}/archive`, payload.payload);
    if (response.status === 200) {
      handleSuccess('notification.success.deviceArchiveUpdate');
      yield put(ArchiveDeviceActions.updateDeviceArchiveSuccess());
      yield put(ManagementActions.deviceListRequest(payload.payload));
      yield put(ResourceGroupActions.getAllResourceGroupsRequest());
    } else {
      yield put(
        ArchiveDeviceActions.updateDeviceArchiveFailure({
          responseMessage: handleError(response),
        })
      );
    }
  } catch (error) {
    yield put(ArchiveDeviceActions.updateDeviceArchiveFailure({ responseMessage: handleError(error) }));
  }
}
