import styled from 'styled-components';

export const FlowRowStyles = styled.div`
  margin-bottom: 2em;

  .plant-icon {
    font-size: 5em;
  }

  .power-item-margin {
    margin-bottom: 0.1em;
  }

  .flow-top-padding {
    padding-top: 3em;
  }

  .temperature-box-padding {
    padding-top: 3.5em;
    padding-bottom: 3.5em;
  }

  .flow-labels {
    margin-top: -1.5em;
  }

  .flow-labels > * {
    margin-top: 1em;
  }
`;
