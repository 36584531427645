import { store } from 'react-notifications-component';
import i18n from '../../i18n';

import { NotificationText, NotificationType } from '../../Helpers/Enums';

const showNotification = (type, text, duration) => {
  store.addNotification({
    message: text,
    type: type,
    insert: 'top',
    container: 'bottom-left',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    width: 450,
    dismiss: {
      duration: duration ? duration : 5000,
    },
  });
};

export function handleSuccess(text) {
  showNotification(NotificationType.success, i18n.t(text), 3000);
  return i18n.t(text);
}

export function handleError(res) {
  if (res) {
    if (res.name === 'CommonError') {
      showNotification(res.type, i18n.t(res.message), res.notificationDuration);
      return i18n.t(res.message);
    }
    switch (res.status) {
      case 400:
        if (res.data === 'Out-of-date data') {
          showNotification(NotificationType.danger, i18n.t(NotificationText.outOfDateData));
          return i18n.t(NotificationText.outOfDateData);
        } else {
          showNotification(NotificationType.danger, i18n.t(NotificationText.bad));
          return i18n.t(NotificationText.bad);
        }
      case 401:
      case 403:
        showNotification(NotificationType.danger, i18n.t(NotificationText.unauthorized));
        return i18n.t(NotificationText.unauthorized);
      case 500:
        showNotification(NotificationType.danger, i18n.t(NotificationText.technical));
        return i18n.t(NotificationText.technical);
      case 404:
      case 503:
        showNotification(NotificationType.danger, i18n.t(NotificationText.unavailable));
        return i18n.t(NotificationText.unavailable);
      default:
        showNotification(NotificationType.danger, i18n.t(NotificationText.main));
        return i18n.t(NotificationText.main);
    }
  } else {
    showNotification(NotificationType.danger, i18n.t(NotificationText.main));
    return i18n.t(NotificationText.main);
  }
}
