import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../Loader/Loader';
import ColumnRow from './ColumnRow';
import Pagination from '../Pagination/Pagination';
import DataRow from './Data/DataRow';
import DeviceEventLogFilter from '../../Filter/DeviceEventLogFilter';
import * as DeviceEventLogActions from '../../../Store/DeviceEventLog/DeviceEventLogActions';
import { camelCaseToSnakeCase } from '../../../Helpers/HelperMethods';

export default function DeviceEventLogTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { deviceEventLog, deviceEventLogLoading, deviceEventLogSortConfig, pageableInfo } = useSelector(state => state.deviceEventLog);

  useEffect(() => {
    dispatch(
      DeviceEventLogActions.getDeviceEventLogRequest({
        page: deviceEventLogSortConfig.currentPage,
        size: deviceEventLogSortConfig.perPage,
        sortableRow: camelCaseToSnakeCase(deviceEventLogSortConfig.key),
        sortDirection: deviceEventLogSortConfig.direction,
        searchStr: deviceEventLogSortConfig.searchStr,
      })
    );
    // eslint-disable-next-line
  }, [dispatch]);

  const setSortConfig = sortConfig => {
    dispatch(DeviceEventLogActions.setEventLogSortConfig(sortConfig));
    dispatch(
      DeviceEventLogActions.getDeviceEventLogRequest({
        page: sortConfig.currentPage,
        size: sortConfig.perPage,
        sortableRow: camelCaseToSnakeCase(sortConfig.key),
        sortDirection: sortConfig.direction,
        searchStr: sortConfig.searchStr,
      })
    );
  };

  return (
    <React.Fragment>
      <div className='grid grid--center-lg'>
        <div className='grid__col--lg-12 margin'>
          <DeviceEventLogFilter sortConfig={deviceEventLogSortConfig} setSortConfig={setSortConfig} />
        </div>
        <div className='grid__col--lg-12 margin'>
          <table className='tbl tbl--transparent tbl--borders tbl--row-highlight'>
            <ColumnRow sortConfig={deviceEventLogSortConfig} setSortConfig={setSortConfig} />
            {!deviceEventLogLoading ? (
              <DataRow deviceEventLog={deviceEventLog} />
            ) : (
              <tbody>
                <tr>
                  <td colSpan='5'>
                    <Loader />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <Pagination
            sortConfig={deviceEventLogSortConfig}
            setSortConfig={setSortConfig}
            totalPages={pageableInfo.totalPages}
            label={t('navigation.device_event_log.device_event_log_table.perPage')}
            totalElements={deviceEventLog.length}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
