import { combineReducers } from 'redux';

import { ManagementReducer } from './Management/ManagementReducer';
import { ReadingReducer } from './Reading/ReadingReducer';
import { ResourceGroupReducer } from './ResourceGroup/ResourceGroupReducer';
import { SearchBoxReducer } from './Input/SearchBox/SearchBoxReducer';
import { CompareReducer } from './Compare/CompareReducer';
import { AlertReducer, AlertReducerByIds } from './Alert/AlertReducer';
import { CommonReducer } from './Common/CommonReducer';
import { SensorReducer } from './Sensor/SensorReducer';
import { ReportReducer } from './Report/ReportReducer';
import { DeviceEventLogReducer } from './DeviceEventLog/DeviceEventLogReducer';
import { DashboardReducer } from './Dashboard/DashboardReducer';
import { ArchiveDeviceReducer } from './ArchiveDevice/ArchiveDeviceReducer';

export const RootReducer = combineReducers({
  management: ManagementReducer,
  readings: ReadingReducer,
  resourceGroup: ResourceGroupReducer,
  searchBox: SearchBoxReducer,
  alertGroups: AlertReducer,
  alertByIds: AlertReducerByIds,
  compare: CompareReducer,
  common: CommonReducer,
  sensor: SensorReducer,
  reports: ReportReducer,
  deviceEventLog: DeviceEventLogReducer,
  dashboard: DashboardReducer,
  archiveDevice: ArchiveDeviceReducer,
});
