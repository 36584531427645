import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { any, array, arrayOf, bool, shape, string } from 'prop-types';

import NotFoundView from '../../Views/NotFound/NotFoundView';
import LoginView from '../../Views/Login/LoginView';
import useAuthentication from '../../Hooks/useAuthentication';

export default function RouterProvider(props) {
  const { routes } = props;
  const { getIsAuthenticated } = useAuthentication();

  function RouteWithSubRoutes(routeConfig) {
    const View = routeConfig.component;
    const isRouteProtected = routeConfig.isProtected;

    return isRouteProtected && getIsAuthenticated() ? (
      <Route
        exact={routeConfig.exact}
        path={routeConfig.path}
        render={routeProps => <View {...routeProps} routes={routeConfig.routes} />}
      />
    ) : (
      <Route>
        <Redirect to={'/login'} />
        <LoginView />
      </Route>
    );
  }

  return (
    <Switch>
      {routes.map((route, index) => (
        <RouteWithSubRoutes key={index} {...route} />
      ))}
      <Route path={'*'}>
        <NotFoundView />
      </Route>
    </Switch>
  );
}

RouterProvider.propTypes = {
  children: any,
  routes: arrayOf(
    shape({
      component: any.isRequired,
      exact: bool,
      isProtected: bool,
      path: string.isRequired,
      routes: array,
    })
  ).isRequired,
};
