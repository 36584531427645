import React from 'react';
import PropTypes from 'prop-types';

import { BoxesStyles } from '../../AlertsRow/Boxes/Boxes.styles';
import NumberWithLabel from '../../Items/NumberWithLabel';

export default function TemperatureBox(props) {
  const { m1, m2, avg, m1Label, m2Label, avgLabel } = props;

  return (
    <BoxesStyles>
      <div className='grid box grid--middle-sm grid--no-gutter grid--between-sm temperature-box-padding'>
        <div className=' grid__col--sm-2'>
          <svg className='icon icon--large '>
            <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/iconsELV.svg#meters-lg`} />
          </svg>
        </div>
        <div className=' grid__col--sm-4'>
          <NumberWithLabel count={`${avg} °C`} label={avgLabel} />
        </div>
        <div className=' grid__col--sm-2'>
          <svg className='icon icon--medium '>
            <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#arrow-right`} />
          </svg>
        </div>
        <div className=' grid__col--sm-4 flow-labels'>
          <NumberWithLabel count={`${m1} °C`} label={m1Label} />
          <NumberWithLabel count={`${m2} °C`} label={m2Label} />
        </div>
      </div>
    </BoxesStyles>
  );
}

TemperatureBox.propTypes = {
  m1: PropTypes.string,
  m2: PropTypes.string,
  avg: PropTypes.string,
  m1Label: PropTypes.string,
  m2Label: PropTypes.string,
  avgLabel: PropTypes.string,
};
