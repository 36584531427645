import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';
import moment from 'moment';
import LinearProgress from '@material-ui/core/LinearProgress';

import GraphInfo from '../GraphInfo/GraphInfo';
import GraphLengthSelector from '../GraphLengthSelector/GraphLengthSelector';
import { getGraphSeries, getParamFromUrl, getGraphOptions } from '../../../Helpers/HelperMethods';
import * as ReadingsActions from '../../../Store/Reading/ReadingActions';
import * as ManagementActions from '../../../Store/Management/ManagementActions';
import * as Common from '../../../Helpers/Common';
import { LineGraphStyles } from './LineGraph.styles';
import { DurationEnum } from '../../../Helpers/Enums';

const LineGraph = () => {
  const [deviceId] = useState(getParamFromUrl(Common.deviceId));
  const [vlId] = useState(getParamFromUrl(Common.vlId));
  const [duration, setDuration] = useState(DurationEnum.ONE_DAY);
  const [endTime, setEndTime] = useState(moment(moment.now()).format());

  const { readings, hasReadings, isLoading } = useSelector(state => state.readings);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(ManagementActions.deviceInfoRequest({ deviceId: deviceId }));
  }, [dispatch, deviceId]);

  useEffect(() => {
    dispatch(
      ReadingsActions.readingRequest({
        vlId: vlId,
        deviceId: deviceId,
        duration: duration,
        endTime: endTime,
      })
    );
  }, [dispatch, deviceId, duration, vlId, endTime]);

  const handleDurationChange = value => {
    setDuration(value);
  };

  const handleEndTimeChange = value => {
    setEndTime(value);
  };

  return (
    <LineGraphStyles>
      {isLoading && <LinearProgress />}
      <GraphInfo />
      <GraphLengthSelector
        handleDurationChange={handleDurationChange}
        handleEndTimeChange={handleEndTimeChange}
        duration={duration}
        endTime={endTime}
      />
      {!hasReadings && !isLoading && <div>{t('navigation.graph.no_data')}</div>}
      {hasReadings && (
        <Chart options={getGraphOptions(readings, deviceId, true)} series={getGraphSeries(readings)} height='650' type='line' />
      )}
    </LineGraphStyles>
  );
};

export default LineGraph;
