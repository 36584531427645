import React from 'react';
import PropTypes from 'prop-types';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { BoxesStyles } from '../../AlertsRow/Boxes/Boxes.styles';

export default function StationDataBox(props) {
  const { data, title, xAxisLabel, yAxisLabel } = props;

  return (
    <BoxesStyles>
      {data.length > 0 && (
        <React.Fragment>
          <h4 className='chart--title'>{title}</h4>
          <ResponsiveContainer width='99%' height={200}>
            <LineChart className='box--border' width={600} height={200} data={data} margin={{ top: 30, right: 20, left: -15, bottom: 15 }}>
              <XAxis dataKey='key' label={{ value: xAxisLabel, position: 'insideBottom', offset: -5 }} />
              <YAxis label={{ value: yAxisLabel, angle: -90, position: 'insideBottomLeft', offset: 30 }} />
              <Tooltip />
              <Line type='monotone' dataKey='value' stroke='#A8A8A8' />
            </LineChart>
          </ResponsiveContainer>
        </React.Fragment>
      )}
    </BoxesStyles>
  );
}

StationDataBox.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  xAxisLabel: PropTypes.string,
  yAxisLabel: PropTypes.string,
};
