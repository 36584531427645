import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PowerItem from './PowerItem';
import { BoxesStyles } from '../../AlertsRow/Boxes/Boxes.styles';

import { getShortTime } from '../../../../Helpers/HelperMethods';

export default function SecondBox() {
  const { t } = useTranslation();

  const { powerReadings } = useSelector(state => state.dashboard);

  const getReadingTime = () => {
    return `${t('navigation.dashboard.flow.readings')} ${getShortTime(powerReadings.heatPowerReadingTime)} `;
  };

  return (
    <BoxesStyles>
      <div className='grid grid--no-gutter box'>
        <div className='grid__col--sm-11'>
          <PowerItem value={powerReadings.heatPower} label={t('navigation.dashboard.flow.heat_power')} info={getReadingTime()} />
          <PowerItem value={powerReadings.meterPower} label={t('navigation.dashboard.flow.meter_power')} info={getReadingTime()} />
          <PowerItem value={powerReadings.powerLoss} label={t('navigation.dashboard.flow.power_loss')} />
        </div>
      </div>
    </BoxesStyles>
  );
}
