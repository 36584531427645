import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-notifications-component/dist/theme.css';
import ReactNotification from 'react-notifications-component';

import AppMain from '../AppMain/AppMain';
import RouterProvider from '../../Providers/Router/RouterProvider';
import useAuthentication from '../../Hooks/useAuthentication';
import { routes } from '../../Routes/Routes';
import { GlobalStyles } from '../../Styles/GlobalStyles';

export default function App() {
  const { getIsAuthenticated } = useAuthentication();

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ReactNotification />
      <GlobalStyles />
      {getIsAuthenticated() && <AppMain data-testid={'header'} />}
      {!getIsAuthenticated() && <RouterProvider routes={routes} />}
    </BrowserRouter>
  );
}
