import React from 'react';
import PropTypes from 'prop-types';

import { parseNumberFixed } from '../../../../Helpers/HelperMethods';

export default function PowerItem(props) {
  const { value, label, info } = props;

  return (
    <React.Fragment>
      <div className='grid power-item-margin'>
        <div className='grid__col--sm-6'>
          <p className='text-lead text-bold'>{label}</p>
        </div>
        <div className='grid--end-sm grid__col--sm-6'>
          <div>
            <p className='text-lead'>
              <span className='text-bold'>{parseNumberFixed(value, 1)}</span>
            </p>
          </div>
          {info && (
            <div className='grid--end-sm '>
              <span className='text-grey'>{info}</span>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

PowerItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  info: PropTypes.string,
};
