import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ExpandedRow(props) {
  const { t } = useTranslation();

  const { item } = props;

  return (
    <tr className='collapse' id={item.id}>
      <td colSpan='5'>
        <div>
          <span className='text-bold'>{t('navigation.device_event_log.device_event_log_table.description')}</span>: {item.description}
        </div>

        <div>
          <span className='text-bold'>{t('navigation.device_event_log.device_event_log_table.creator')}</span>: {item.author}
        </div>
      </td>
    </tr>
  );
}
