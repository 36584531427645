import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ResponsiveContainer, Tooltip, XAxis, YAxis, ReferenceLine, LineChart, Line } from 'recharts';
import { useTranslation } from 'react-i18next';

import Loader from '../../../Loader/Loader';
import { timeFormat } from '../../../../Helpers/Common';
import { BoxesStyles } from './Boxes.styles';

export default function TemperatureBox() {
  const { t } = useTranslation();
  const { outsideTemperatureLoading, outsideTemperatureReadings, hasOutsideTemperatureReadings } = useSelector(state => state.dashboard);

  const data =
    hasOutsideTemperatureReadings && outsideTemperatureReadings.readings
      ? outsideTemperatureReadings.readings.map(reading => {
          return {
            time: moment(reading.time).format(timeFormat),
            temp: Number(parseFloat(reading.value).toFixed(0)),
          };
        })
      : {};

  const yAxisLabel = t('navigation.dashboard.alerts.temperature');
  const xAxisLabel = t('navigation.dashboard.alerts.time');

  return (
    <BoxesStyles>
      {outsideTemperatureLoading && !hasOutsideTemperatureReadings && <Loader />}
      {data.length > 0 && !outsideTemperatureLoading && hasOutsideTemperatureReadings && (
        <React.Fragment>
          <h4 className='chart--title'>{t('navigation.dashboard.alerts.outside_temp')}</h4>
          <ResponsiveContainer width='99%' height={230}>
            <LineChart className='box--border' width={600} height={200} data={data} margin={{ top: 30, right: 20, left: -15, bottom: 15 }}>
              <XAxis dataKey='time' label={{ value: xAxisLabel, position: 'insideBottom', offset: -5 }} />
              <YAxis label={{ value: yAxisLabel, angle: -90, position: 'insideBottomLeft', offset: 30 }} allowDecimals={false} />
              <Tooltip />
              <ReferenceLine y={0} stroke='#CCCCCC' strokeDasharray='3 3' alwaysShow='false' />
              <Line type='monotone' dataKey='temp' stroke='#666666' strokeWidth='2' />
            </LineChart>
          </ResponsiveContainer>
        </React.Fragment>
      )}
    </BoxesStyles>
  );
}
