import { put } from 'redux-saga/effects';

import * as AlertActions from './AlertActions';
import { handleError, handleSuccess } from '../../Services/Fetch/HttpResponseHandler';
import { API_ENDPOINTS, getData, postData } from '../../Services/Fetch/FetchService';

export function* readAlertList() {
  try {
    const response = yield getData(`${API_ENDPOINTS.ALERT_LIST_WITH_COUNT}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(AlertActions.getAlertsSuccess(data));
    } else {
      yield put(AlertActions.getAlertsFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(AlertActions.getAlertsFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* readAlertListByIds(payload) {
  const vlId = payload.payload.virtualLocationId;
  const deviceId = payload.payload.deviceId;
  const stId = payload.payload.sensorTypeId;
  const rgId = payload.payload.resourceGroupId;
  const alertStatus = payload.payload.alertStatus;
  const alertType = payload.payload.alertType;
  try {
    const response = yield getData(
      `${API_ENDPOINTS.ALERT_LIST_BY_ID}\n?virtualLocationId=${vlId}
      \n&deviceId=${deviceId}
      \n&sensorTypeId=${stId}
      \n&resourceGroupId=${rgId}
      \n&alertStatus=${alertStatus}
      \n&alertType=${alertType}`
    );
    if (response.status === 200) {
      const data = yield response.data;
      yield put(AlertActions.getAlertsDetailSuccess(data));
    } else {
      yield put(AlertActions.getAlertsDetailFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(AlertActions.getAlertsDetailFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* changeAlertStatus(payload) {
  try {
    const response = yield postData(`${API_ENDPOINTS.ALERT_STATUS}`, payload.payload);
    if (response.status === 200) {
      handleSuccess(`notification.success.${payload.payload.newStatus}`);
      yield put(AlertActions.getAlertsRequest());
    } else {
      yield put(AlertActions.changeAlertStatusFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(AlertActions.changeAlertStatusFailure({ responseMessage: handleError(error.response) }));
  }
}
