import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getNameForResourceGroup, isRealResourceGroup, isGroupForAllMeters } from '../../../Helpers/HelperMethods';
import * as ManagementActions from '../../../Store/Management/ManagementActions';
import { ResourceGroupItemStyles } from './ResourceGroupItem.styles';
import { AlertDifferenceTypeEnum, CustomAlertNames } from '../../../Helpers/Enums';

export default function ResourceGroupItem(props) {
  const { t } = useTranslation();
  const { group } = props;

  const dispatch = useDispatch();

  const { activeGroup } = useSelector(state => state.management);

  const setActiveGroup = newGroup => {
    dispatch(ManagementActions.setActiveGroup(newGroup));
  };

  const getGroupMeters = () => {
    setActiveGroup(group);
  };

  const groupActive = () => {
    return activeGroup.id === group.id || (!activeGroup.id && isGroupForAllMeters(group));
  };

  const getValueText = item => {
    if (item.differenceType === AlertDifferenceTypeEnum.PERCENTAGE) {
      return `${item.threshold}%`;
    } else if (item.differenceType === AlertDifferenceTypeEnum.MIN_MAX) {
      return `${item.valueMin} - ${item.valueMax}`;
    }
    return item.threshold;
  };

  const getGroupName = resourceGroup => {
    if (isRealResourceGroup(resourceGroup) || isGroupForAllMeters(resourceGroup)) {
      return resourceGroup.name;
    }
    return t(getNameForResourceGroup(resourceGroup));
  };

  return (
    <ResourceGroupItemStyles>
      <div
        className={`pricebar__price pointer ${groupActive() ? 'selected' : ''}`}
        onClick={() => getGroupMeters()}
        data-testid='selectActiveGroup'
      >
        <div className='grid grid--no-horizontal-gutter'>
          <div className='grid__col--sm-10'>{getGroupName(group)}</div>
          <div className='grid__col--sm-2 grid--end-sm'>
            <span className='badge badge--grey'>
              {group.resourceGroupVirtualLocationCount ? group.resourceGroupVirtualLocationCount : 0}
            </span>
          </div>
        </div>
      </div>
      {groupActive() && isRealResourceGroup(group) && (
        <div className='pricebar-group'>
          <div className='pricebar__date'>{group.description}</div>
          <div className='pricebar__date grid grid--no-vertical-gutter'>
            <div className='grid__col--sm-12'>
              {group &&
              group.alertConfigData &&
              group.alertConfigData.customAlertNames &&
              group.alertConfigData.customAlertNames.includes(CustomAlertNames.CONTRACT_KEY) ? (
                <p className='text-bold'>{t('navigation.manage.resource_group.using_contract_values')}</p>
              ) : (
                <p className='text-bold'>{t('navigation.manage.resource_group.not_using_contract_values')}</p>
              )}
            </div>
            <div className='grid__col--sm-8'>
              <p className='text-bold'>{t('navigation.manage.resource_group.table.parameter')}</p>
            </div>
            <div className='grid__col--sm-4'>
              <p>{t('navigation.manage.resource_group.table.threshold')}</p>
            </div>
            {group &&
              group.alertConfigData &&
              group.alertConfigData.rows &&
              group.alertConfigData.rows.map((item, index) => {
                return (
                  item.enabled && (
                    <React.Fragment key={index}>
                      <div className='grid__col--sm-8'>
                        <p className='text-bold'>{item.name}</p>
                      </div>
                      <div className='grid__col--sm-4'>
                        <p>{getValueText(item)}</p>
                      </div>
                    </React.Fragment>
                  )
                );
              })}
          </div>
        </div>
      )}
    </ResourceGroupItemStyles>
  );
}
