import { put, select } from 'redux-saga/effects';

import * as CompareActions from './CompareActions';
import { API_ENDPOINTS, getData } from '../../Services/Fetch/FetchService';
import { handleError } from '../../Services/Fetch/HttpResponseHandler';
import { getDuration, getEndTime, getExistingDevices } from './CompareReducer';
import { CommonError } from '../../Helpers/Common';
import { NotificationType } from '../../Helpers/Enums';

export function* getDeviceForCompare(payload) {
  const page = payload.payload.page;
  const size = payload.payload.size;
  const searchStr = payload.payload.searchStr ? `&searchStr=${payload.payload.searchStr}` : '';
  try {
    const response = yield getData(`${API_ENDPOINTS.DEVICE_LIST}/?page=${page - 1}&size=${size}${searchStr}`);
    if (response.status === 200) {
      const data = yield response.data;
      if (data.content && data.content[0]) {
        (yield select(getExistingDevices)).forEach(existingDevice => {
          if (data.content[0].id === existingDevice.id) {
            throw new CommonError('error.common.compareDeviceAlreadyExists', NotificationType.warning, 10000);
          }
        });

        yield put(CompareActions.getDeviceForCompareSuccess(data.content[0]));
        yield put(CompareActions.getReadingsForCompareRequest(data.content[0]));
      } else {
        yield put(
          CompareActions.getDeviceForCompareFailure({
            responseMessage: `No device ${payload.payload.searchStr} found`,
          })
        );
      }
    } else {
      yield put(CompareActions.getDeviceForCompareFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(
      CompareActions.getDeviceForCompareFailure({
        responseMessage: handleError(error),
      })
    );
    console.error(error);
  }
}

export function* readDeviceReadings(payload) {
  const vlId = payload.payload.virtualLocationId;
  const deviceId = payload.payload.id;
  const duration = yield select(getDuration);
  const endTime = encodeURIComponent(yield select(getEndTime));
  try {
    const response = yield getData(`${API_ENDPOINTS.READING}?vlId=${vlId}&duration=${duration}&deviceId=${deviceId}&endTime=${endTime}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(
        CompareActions.getReadingsForCompareSuccess({
          readings: data,
          device: payload.payload,
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

export function* refreshCompareReadings(payload) {
  yield put(CompareActions.clearReadingsToCompare());
  for (const d in payload.payload) {
    if (payload.payload.hasOwnProperty(d)) {
      const device = payload.payload[d];
      yield put(CompareActions.getReadingsForCompareRequest(device));
    }
  }
}

export function* setDevicesFromManageView(payload) {
  const devices = payload.payload;

  if (!devices || devices.length > 10) {
    console.error('Maximum no. of devices allowed: 10');
    return;
  }

  yield put(CompareActions.clearReadingsToCompare());
  yield put(CompareActions.clearDevicesToCompare());

  for (const device in devices) {
    if (devices.hasOwnProperty(device)) {
      yield put(CompareActions.getDeviceForCompareSuccess(devices[device]));
      yield put(CompareActions.getReadingsForCompareRequest(devices[device]));
    }
  }
}
