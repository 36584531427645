import React from 'react';

export default function Loader() {
  return (
    <div className='loader '>
      <svg className='icon loader__icon'>
        <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#loader`} />
      </svg>
    </div>
  );
}
