import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';

import * as ArchiveDeviceActions from '../../../Store/ArchiveDevice/ArchiveDeviceActions';
import Header from '../Header/Header';
import { camelCaseToSnakeCase, getGroupId } from '../../../Helpers/HelperMethods';
import { ArchiveDeviceModalStyles } from './ArchiveDeviceModal.styles';

export default function ArchiveDeviceModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { open, setOpen, device } = props;

  const { activeGroup, managementSortConfig } = useSelector(state => state.management);

  const updateDeviceArchive = () => {
    const payload = {
      deviceId: device.id,
      archive: !device.archived,
      version: device.version,
      page: managementSortConfig.currentPage,
      size: managementSortConfig.perPage,
      sortableRow: camelCaseToSnakeCase(managementSortConfig.key),
      sortDirection: managementSortConfig.direction,
      searchStr: managementSortConfig.searchStr,
      groupId: getGroupId(activeGroup),
    };
    dispatch(ArchiveDeviceActions.updateDeviceArchiveRequest(payload));
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAddressLine = () => {
    return device.address ? ' - ' + device.address : '';
  };

  const getHeaderText = () => {
    return device.archived
      ? t('navigation.manage.make_device_inactive_modal.modal_header_active') + getAddressLine()
      : t('navigation.manage.make_device_inactive_modal.modal_header_inactive') + getAddressLine();
  };

  const getModalText = () => {
    return device.archived
      ? t('navigation.manage.make_device_inactive_modal.modal_text_active')
      : t('navigation.manage.make_device_inactive_modal.modal_text_inactive')
          .split('\n')
          .map(line => (
            <p>
              {line} <br />
            </p>
          ));
  };

  const getButtonColor = () => {
    return device.archived ? 'activate-btn' : 'deactivate-btn';
  };

  const getButtonText = () => {
    return device.archived
      ? t('navigation.manage.make_device_inactive_modal.btn_activate')
      : t('navigation.manage.make_device_inactive_modal.btn_deactivate');
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <ArchiveDeviceModalStyles>
        <Header headerText={getHeaderText()} handleClose={handleClose} />
        <div className='modal__body'>
          <div className='grid'>
            <div className='grid__col--sm-12'>
              <span className='h4'>{getModalText()}</span>
            </div>
          </div>
        </div>
        <div className='modal__footer'>
          <div className='btn-group btn-group--eq '>
            <button className='btn' onClick={handleClose}>
              <span className='btn__text'>{t('navigation.manage.make_device_inactive_modal.btn_cancel')}</span>
            </button>
            <button className={`btn btn--primary ${getButtonColor()}`} onClick={updateDeviceArchive} data-testid='updateDeviceArchiveBtn'>
              <span className='btn__text'>{getButtonText()}</span>
            </button>
          </div>
        </div>
      </ArchiveDeviceModalStyles>
    </Dialog>
  );
}
