import styled from 'styled-components';

export const LoginViewStyles = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin: 1rem;

  .login-container {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    max-width: 400px;
  }

  .login-title {
    text-transform: capitalize;
  }

  .app-icon {
    display: block;
    margin: 1rem;
  }

  .button-login {
    font-size: 1.5rem;
    margin: 1.5rem 0 0 0;
    padding: 1rem;
  }

  .authors {
    bottom: 0;
    font-family: monospace;
    margin: 1rem 0;
    position: absolute;
  }
`;
