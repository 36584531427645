import React from 'react';
import PropTypes from 'prop-types';

import NumberWithLabel from '../../Items/NumberWithLabel';
import { BoxesStyles } from './Boxes.styles';

export default function AlertCountBox(props) {
  const { firstCount, firstLabel, secondCount, secondLabel } = props;

  return (
    <BoxesStyles>
      <div className='grid box grid--no-gutter'>
        <div className='alert-box-padding  grid__col--sm-5 '>
          <NumberWithLabel count={firstCount} label={firstLabel} large />
        </div>
        <div className='alert-box-padding grid__col--sm-5'>
          <NumberWithLabel count={secondCount} label={secondLabel} large />
        </div>
        <div className='alert-box-padding grid__col--sm-2 grid--end-sm box--icon'>
          <svg className='icon icon--large'>
            <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/iconsELV.svg#bell-md`} />
          </svg>
        </div>
      </div>
    </BoxesStyles>
  );
}

AlertCountBox.propTypes = {
  firstCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstLabel: PropTypes.string,
  secondCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondLabel: PropTypes.string,
};
