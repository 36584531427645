import { createReducer } from '@reduxjs/toolkit';

import * as DashboardActions from './DashboardActions';
import { Hours, Sensors } from '../../Helpers/Enums';
import { findByMetaDataIdentifier } from '../../Helpers/HelperMethods';

const initialState = {
  outsideTemperatureLoading: false,
  hasOutsideTemperatureReadings: false,
  outsideTemperatureReadings: {},
  outsideTemperatureErrorMsg: '',

  stationTemperatureLoading: false,
  hasStationTemperatureReadings: false,
  stationTemperatureReadings: {},
  stationTemperatureErrorMsg: '',

  noReadingsCountLoading: false,
  noReadingsCount: {
    '24H': {},
    '1H': {},
  },
  noReadingsCountErrorMsg: '',

  alertsCountLoading: false,
  hasAlertsCount: false,
  alertsCount: [],
  alertsCountErrorMsg: '',

  powerReadingsLoading: false,
  hasPowerReadings: false,
  powerReadings: {},
  powerReadingsErrorMsg: '',

  flowReadingsLoading: false,
  hasFlowReadings: false,
  flowReadings: {},
  flowReadingsErrorMsg: '',

  plantPowerReadingsLoading: false,
  hasPlantPowerReadings: false,
  plantPowerReadings: {},
  plantPowerReadingsErrorMsg: '',

  plantFlowOutReadingsLoading: false,
  hasPlantFlowOutReadings: false,
  plantFlowOutReadings: {},
  plantFlowOutReadingsErrorMsg: '',
};

export const DashboardReducer = createReducer(initialState, {
  [DashboardActions.GET_OUTSIDE_TEMP_REQUEST]: state => {
    return {
      ...state,
      outsideTemperatureLoading: true,
    };
  },
  [DashboardActions.GET_OUTSIDE_TEMP_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      outsideTemperatureLoading: false,
      hasOutsideTemperatureReadings: payload.length > 0,
      outsideTemperatureReadings: payload.length > 0 ? payload[0] : {},
    };
  },
  [DashboardActions.GET_OUTSIDE_TEMP_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      outsideTemperatureLoading: false,
      outsideTemperatureErrorMsg: payload,
    };
  },

  [DashboardActions.GET_STATION_TEMP_REQUEST]: state => {
    return {
      ...state,
      stationTemperatureLoading: true,
    };
  },
  [DashboardActions.GET_STATION_TEMP_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      stationTemperatureLoading: false,
      hasStationTemperatureReadings: !!(payload.t1Avg && payload.t2Avg && payload.plantData),
      stationTemperatureReadings: payload,
    };
  },
  [DashboardActions.GET_STATION_TEMP_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      stationTemperatureLoading: false,
      stationTemperatureErrorMsg: payload,
    };
  },

  [DashboardActions.GET_NO_READINGS_COUNT_REQUEST]: state => {
    return {
      ...state,
      noReadingsCountLoading: true,
    };
  },
  [DashboardActions.GET_NO_READINGS_COUNT_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      noReadingsCountLoading: false,
      noReadingsCount: createNoReadingResponse(JSON.stringify(state.noReadingsCount), payload),
    };
  },
  [DashboardActions.GET_NO_READINGS_COUNT_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      noReadingsCountLoading: false,
      noReadingsCountErrorMsg: payload,
    };
  },

  [DashboardActions.GET_ALERTS_COUNT_REQUEST]: state => {
    return {
      ...state,
      alertsCountLoading: true,
    };
  },
  [DashboardActions.GET_ALERTS_COUNT_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      alertsCountLoading: false,
      hasAlertsCount: payload.length > 0,
      alertsCount: payload,
    };
  },
  [DashboardActions.GET_ALERTS_COUNT_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      alertsCountLoading: false,
      alertsCountErrorMsg: payload,
    };
  },

  [DashboardActions.GET_POWER_READINGS_REQUEST]: state => {
    return {
      ...state,
      powerReadingsLoading: true,
    };
  },
  [DashboardActions.GET_POWER_READINGS_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      powerReadingsLoading: false,
      hasPowerReadings: !!(payload.heatPower && payload.meterPower && payload.powerLoss),
      powerReadings: payload,
    };
  },
  [DashboardActions.GET_POWER_READINGS_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      powerReadingsLoading: false,
      powerReadingsErrorMsg: payload,
    };
  },

  [DashboardActions.GET_FLOW_READINGS_REQUEST]: state => {
    return {
      ...state,
      flowReadingsLoading: true,
    };
  },
  [DashboardActions.GET_FLOW_READINGS_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      flowReadingsLoading: false,
      hasFlowReadings: !!(payload.flowIn && payload.flowOut),
      flowReadings: payload,
    };
  },
  [DashboardActions.GET_FLOW_READINGS_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      flowReadingsLoading: false,
      flowReadingsErrorMsg: payload,
    };
  },

  [DashboardActions.GET_PLANT_POWER_READINGS_REQUEST]: state => {
    return {
      ...state,
      plantPowerReadingsLoading: true,
    };
  },
  [DashboardActions.GET_PLANT_POWER_READINGS_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      plantPowerReadingsLoading: false,
      hasPlantPowerReadings: payload.length > 0,
      plantPowerReadings: payload.length > 0 ? payload[0] : {},
    };
  },
  [DashboardActions.GET_PLANT_POWER_READINGS_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      plantPowerReadingsLoading: false,
      plantPowerErrorMsg: payload,
    };
  },

  [DashboardActions.GET_PLANT_FLOW_OUT_READINGS_REQUEST]: state => {
    return {
      ...state,
      plantFlowOutReadingsLoading: true,
    };
  },
  [DashboardActions.GET_PLANT_FLOW_OUT_READINGS_SUCCESS]: (state, action) => {
    const { payload } = action;
    const sensorData = findByMetaDataIdentifier(payload, Sensors.flowOut.toString());
    return {
      ...state,
      plantFlowOutReadingsLoading: false,
      hasPlantFlowOutReadings: !!sensorData,
      plantFlowOutReadings: sensorData,
    };
  },
  [DashboardActions.GET_PLANT_FLOW_OUT_READINGS_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      plantFlowOutReadingsLoading: false,
      plantFlowOutReadingsErrorMsg: payload,
    };
  },
});

const createNoReadingResponse = (existingData, data) => {
  const existingDataJson = JSON.parse(existingData);
  if (data.duration === 24) {
    existingDataJson[Hours['24']] = data.data;
  } else {
    existingDataJson[Hours['1']] = data.data;
  }
  return existingDataJson;
};
