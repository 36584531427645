import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tooltip from '@material-ui/core/Tooltip';
import useAuthentication from '../../Hooks/useAuthentication';
import { showErrorNotification } from '../../Services/Notification/NotificationHandler';

export default function AuthorizedButton(props) {
  const { className, onClick, childElement, requiredRole, dataTestId, withTooltip, tooltip } = props;
  const { t } = useTranslation();
  const { getUserRoles } = useAuthentication();

  const authorize = () => {
    const hasAccess = getUserRoles().indexOf(requiredRole) !== -1;

    if (!hasAccess) {
      showErrorNotification(t('error.common.noPermission'));
      return;
    }

    onClick();
  };

  return (
    <React.Fragment>
      {withTooltip ? (
        <Tooltip title={<span className='h6'>{tooltip}</span>}>
          <button onClick={authorize} className={className} data-testid={dataTestId}>
            {childElement}
          </button>
        </Tooltip>
      ) : (
        <button onClick={authorize} className={className} data-testid={dataTestId}>
          {childElement}
        </button>
      )}
    </React.Fragment>
  );
}

AuthorizedButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  childElement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  requiredRole: PropTypes.string,
  dataTestId: PropTypes.string,
  withTooltip: PropTypes.bool,
  tooltip: PropTypes.string,
};
