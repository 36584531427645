export const MultimeteringStatusEnum = {
  CREATED: 'CREATED',
  BOOKED: 'BOOKED',
  DELETED: 'DELETED',
  PAUSED: 'PAUSED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  TIMED_OUT: 'TIMED_OUT',
  CONFIRMED: 'CONFIRMED',
  INTERRUPTED: 'INTERRUPTED',
  CANCELED: 'CANCELED',
};

export const DurationEnum = {
  ONE_DAY: 'ONE_DAY',
  TWO_DAYS: 'TWO_DAYS',
  THREE_DAYS: 'THREE_DAYS',
  SEVEN_DAYS: 'SEVEN_DAYS',
  ONE_MONTH: 'ONE_MONTH',
  SIX_MONTHS: 'SIX_MONTHS',
  ONE_YEAR: 'ONE_YEAR',
  ONE_HALF_YEARS: 'ONE_HALF_YEARS',
};

export const AlertStatusEnumMap = {
  0: 'NEW',
  1: 'POSTPONED',
  2: 'IN_PROGRESS',
  3: 'CLOSED',
};

export const AlertTypeEnumMap = {
  1: 'ZERO_POWER',
  2: 'NO_READING_FOR_24H',
  3: 'CUSTOM',
};

export const MeterListTableColumn = {
  meter_id: 'meter_id',
  meter_address: 'address',
  meter_type: 'type',
  meter_dn: 'diameter',
  device_id: 'device_id',
  device_info: 'device_info',
};

export const CommonSorting = {
  ascending: 'asc',
  descending: 'desc',
};

export const AlertSorting = {
  address: 'deviceAddress',
  errorMessage: 'errorMessage',
  count: 'count',
  maxCreatedDate: 'maxCreatedDate',
  alertStatus: 'alertStatus',
};

export const DeviceEventLog = {
  createdDate: 'createdDate',
  meterId: 'meterId',
  address: 'address',
  description: 'description',
};

export const MeterListSorting = {
  address: 'address',
  deviceId: 'identifier',
  meterId: 'meterId',
  deviceInfo: 'deviceInfo',
  diameter: 'diameter',
};

// key order = Report view table column order
export const ReportingSorting = {
  readingTime: 'readingTime',
  meterIdentifier: 'meterIdentifier',
  address: 'address',
};

export const AlertType = {
  1: 'POWER0',
  2: 'NoReading',
  3: 'Custom',
};

export const ResourceGroupCrud = {
  add: 'add',
  edit: 'edit',
  delete: 'delete',
  move: 'move',
  copy: 'copy',
  remove: 'remove',
  removeAll: 'removeAll',
};

export const ResourceGroupTable = {
  active: 'active',
  parameter: 'parameter',
  threshold: 'threshold',
  min: 'min',
  max: 'max',
  differenceType: 'differenceType',
};

export const GroupParams = {
  all_id: '00000000-0000-0000-0000-000000000000',
  all_name: 'All',
  all_desc: 'Count of all meters',
  none: 'none',

  ungrouped_id: '00000000-0000-0000-0000-000000000001',
  ungrouped_name: 'UN_GROUPED',
  ungrouped_desc: 'Count of UN_GROUPED',

  unarchived_id: '00000000-0000-0000-0000-000000000002',
  unarchived_name: 'NOT_ARCHIVED',
  unarchived_desc: 'Count of NOT_ARCHIVED',

  archived_id: '00000000-0000-0000-0000-000000000003',
  archived_name: 'IS_ARCHIVED',
  archived_desc: 'Count of IS_ARCHIVED',

  unarchived_ungrouped_id: '00000000-0000-0000-0000-000000000004',
  unarchived_ungrouped_name: 'NOT_ARCHIVED_AND_UN_GROUPED',
  unarchived_ungrouped_desc: 'Count of NOT_ARCHIVED_AND_UN_GROUPED',

  archived_ungrouped_id: '00000000-0000-0000-0000-000000000005',
  archived_ungrouped_name: 'IS_ARCHIVED_AND_UN_GROUPED',
  archived_ungrouped_desc: 'Count of IS_ARCHIVED_AND_UN_GROUPED',
};

export const NotificationType = {
  success: 'success',
  danger: 'danger',
  info: 'info',
  default: 'default',
  warning: 'warning',
};

export const NotificationText = {
  bad: 'error.fetch.response.bad',
  unauthorized: 'error.fetch.response.unauthorized',
  technical: 'error.fetch.response.technical',
  unavailable: 'error.fetch.response.unavailable',
  main: 'error.fetch.main',
  outOfDateData: 'error.fetch.response.outOfDateData',
};

export const DeviceParameters = {
  meterId: 'meterId',
  diameter: 'diameter',
  deviceInfo: 'deviceInfo',
  address: 'address',
};

export const AlertDifferenceTypeEnum = {
  ABSOLUTE_CHANGE: 'ABSOLUTE_CHANGE',
  PERCENTAGE: 'PERCENTAGE',
  MIN_MAX: 'MIN_MAX',
  CONTRACT: 'CONTRACT',
};

export const UserRoles = {
  READ: 'Read',
  EDIT: 'Edit',
  GENERATE_BILLING: 'GenerateBilling',
};

export const Identifiers = {
  OUTSIDE_TEMPERATURE: 'OUTSIDE_TEMPERATURE',
  PLANT_OUTPUT_POWER: 'PLANT_OUTPUT_POWER',
  PLANT_TOTAL_FLOW: 'PLANT_TOTAL_FLOW',
};

export const StationTempParams = {
  T1: 'T1 (C)',
  T2: 'T2 (C)',
  M1: 'MAGISTRAAL_1',
  M2: 'MAGISTRAAL_2',
};

export const DeviceSource = {
  nas: 'Nas',
  narva: 'Narva',
};

export const Sensors = {
  t1Sensor: 23,
  t2Sensor: 24,
  flowSensor: 503,
  powerSensor: 32,
  flowIn: 505,
  flowOut: 504,
  powerHeat: 508,
};

export const CustomAlertNames = {
  CONTRACT_KEY: 'Contract',
};

export const Units = {
  mw: 'MW',
  percent: '%',
};

export const deviceParams = {
  identifier: 'identifier',
};

export const Hours = {
  1: '1H',
  24: '24H',
};
