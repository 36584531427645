import React from 'react';
import PropTypes from 'prop-types';

export default function NumberWithLabel(props) {
  const { count, label, large } = props;

  return (
    <React.Fragment>
      <div className={`text-bold ${large ? 'box--nr' : 'box--nr-sm'}`}>{count ? count : '-'}</div>
      {large ? <h4>{label}</h4> : <span className='text-bold'>{label}</span>}
    </React.Fragment>
  );
}

NumberWithLabel.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  large: PropTypes.bool,
};
