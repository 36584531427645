import React from 'react';
import { useTranslation } from 'react-i18next';

import ColumnNoSort from '../Column/ColumnNoSort';
import { ResourceGroupTable } from '../../../Helpers/Enums';

export default function ColumnRow() {
  const { t } = useTranslation();

  return (
    <thead>
      <tr>
        {Object.keys(ResourceGroupTable).map(o => {
          return (
            <ColumnNoSort
              key={ResourceGroupTable[o]}
              text={t(`navigation.manage.group_params_table.${[ResourceGroupTable[o]]}`)}
              sortKey={ResourceGroupTable[o]}
            />
          );
        })}
      </tr>
    </thead>
  );
}
