import styled from 'styled-components';

export const SearchBoxStyles = styled.div`
  .btn {
    background-color: transparent;
    border: 2px solid #d1d7df;
    border-left: 0;
    border-radius: 0;
  }

  .btn:not(:last-child) {
    border-right: 0;
  }

  .btn:not(:disabled):hover {
    background-color: transparent;
  }

  .btn:not(:disabled):active {
    box-shadow: none;
    transform: none;
  }

  .btn:not(:disabled):focus,
  .btn:not(:disabled):hover {
    background-color: transparent;
  }

  .textfield {
    display: flex;
    height: 2.25em;
  }

  .textfield__input {
    border-right: 0;
  }

  .textfield__input:focus {
    border-color: var(--primary-color1);
    box-shadow: none;
  }

  .textfield__input:focus ~ .btn {
    border-color: var(--primary-color1);
  }
`;
