import React from 'react';

export default function ColumnNoSort(props) {
  const { text } = props;

  return (
    <th>
      <div>{text}</div>
    </th>
  );
}
