import { put } from 'redux-saga/effects';

import * as ReadingActions from './ReadingActions';
import { API_ENDPOINTS, getData } from '../../Services/Fetch/FetchService';
import { handleError } from '../../Services/Fetch/HttpResponseHandler';

export function* readDeviceReadings(payload) {
  const deviceId = payload.payload.deviceId;
  const duration = payload.payload.duration;
  const vlId = payload.payload.vlId;
  const endTime = encodeURIComponent(payload.payload.endTime);
  try {
    const response = yield getData(`${API_ENDPOINTS.READING}?vlId=${vlId}&duration=${duration}&deviceId=${deviceId}&endTime=${endTime}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(ReadingActions.readingSuccess(data));
    } else {
      yield put(ReadingActions.readingFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(ReadingActions.readingFailure({ responseMessage: handleError(error.response) }));
  }
}
