import { createReducer } from '@reduxjs/toolkit';
import * as ArchiveDeviceActions from './ArchiveDeviceActions';

const initialState = {
  updateDeviceArchiveLoading: false,
  errorMessage: '',
};

export const ArchiveDeviceReducer = createReducer(initialState, {
  [ArchiveDeviceActions.UPDATE_DEVICE_ARCHIVE_REQUEST]: state => {
    return {
      ...state,
      updateDeviceArchiveLoading: true,
    };
  },
  [ArchiveDeviceActions.UPDATE_DEVICE_ARCHIVE_SUCCESS]: state => {
    return {
      ...state,
      updateDeviceArchiveLoading: false,
      errorMessage: '',
    };
  },
  [ArchiveDeviceActions.UPDATE_DEVICE_ARCHIVE_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      updateDeviceArchiveLoading: false,
      errorMessage: payload,
    };
  },
});
