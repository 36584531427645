import React from 'react';

import { scrollToTop } from '../../../Helpers/HelperMethods';

export default function Pagination(props) {
  const { totalPages, sortConfig, setSortConfig, label, totalElements } = props;

  const handlePerPageChange = value => {
    setSortConfig({ ...sortConfig, perPage: value, currentPage: 1 });
    scrollToTop();
  };

  const handleDecrement = toBeginning => {
    if (toBeginning) {
      setSortConfig({ ...sortConfig, currentPage: 1 });
    } else {
      setSortConfig({ ...sortConfig, currentPage: sortConfig.currentPage - 1 });
    }
    scrollToTop();
  };

  const handleIncrement = toEnd => {
    if (toEnd) {
      setSortConfig({ ...sortConfig, currentPage: totalPages });
    } else {
      setSortConfig({ ...sortConfig, currentPage: sortConfig.currentPage + 1 });
    }
    scrollToTop();
  };

  return (
    <div className='pagination flex flex--right'>
      <span className='pagination__per-page-label'>{label}</span>
      <div className='select select--no-label select--no-margin pagination__per-page-select'>
        <svg className='icon select__icon'>
          <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-updown`} />
        </svg>
        <select
          data-testid='paginationSelect'
          className='select__input'
          defaultValue={sortConfig.perPage}
          onChange={event => handlePerPageChange(event.target.value)}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={totalElements}>{totalElements}</option>
        </select>
      </div>
      <button
        className='btn btn--icon btn--border pagination__btn pagination__btn--start'
        onClick={() => handleDecrement(true)}
        disabled={sortConfig.currentPage === 1}
        data-testid='paginationDecrementToBeginning'
      >
        <svg className='icon btn__icon'>
          <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#arrow-left`} />
        </svg>
      </button>
      <button
        className='btn btn--icon btn--border pagination__btn pagination__btn--start'
        onClick={() => handleDecrement(false)}
        disabled={sortConfig.currentPage === 1}
        data-testid='paginationDecrement'
      >
        <svg className='icon btn__icon'>
          <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-left`} />
        </svg>
      </button>
      <span className='pagination__num-pages'>{`${sortConfig.currentPage} /`}</span>
      <span className='pagination__num-pages'>{totalPages ? totalPages : 1}</span>
      <button
        className='btn btn--icon btn--border pagination__btn'
        onClick={() => handleIncrement(false)}
        disabled={sortConfig.currentPage === totalPages}
        data-testid='paginationIncrement'
      >
        <svg className='icon btn__icon'>
          <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-right`} />
        </svg>
      </button>
      <button
        className='btn btn--icon btn--border pagination__btn'
        onClick={() => handleIncrement(true)}
        disabled={sortConfig.currentPage === totalPages}
        data-testid='paginationIncrementToEnd'
      >
        <svg className='icon btn__icon'>
          <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#arrow-right`} />
        </svg>
      </button>
    </div>
  );
}
