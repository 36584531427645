import { createAction } from '@reduxjs/toolkit';

export const GET_SENSORS_REQUEST = createAction('GET_SENSORS_REQUEST');
export const getSensorsRequest = payload => GET_SENSORS_REQUEST(payload);

export const GET_SENSORS_SUCCESS = createAction('GET_SENSORS_SUCCESS');
export const getSensorsSuccess = payload => GET_SENSORS_SUCCESS(payload);

export const GET_SENSORS_FAILURE = createAction('GET_SENSORS_FAILURE');
export const getSensorsFailure = payload => GET_SENSORS_FAILURE(payload);
