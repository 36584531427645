import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../Loader/Loader';
import ColumnRow from './ColumnRow';
import Pagination from '../Pagination/Pagination';
import DataRow from './Data/DataRow';
import ChangeDeviceIdDiameter from '../../Modal/ChangeDeviceIdDiameter/ChangeDeviceIdDiameter';
import ManagementFilter from '../../Filter/ManagementFilter';
import { camelCaseToSnakeCase, getGroupId } from '../../../Helpers/HelperMethods';
import * as ManagementActions from '../../../Store/Management/ManagementActions';
import DeviceEventLogModal from '../../Modal/DeviceEventLogModal/DeviceEventLogModal';
import ArchiveDeviceModal from '../../Modal/ArchiveDeviceModal/ArchiveDeviceModal';
import ResourceGroups from '../../ResourceGroup/ResourceGroups/ResourceGroups';

export default function ManageTable() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { devices, isLoadingDeviceList, managementSortConfig, pageableInfo, activeGroup, selectedMeters, hasDevices } = useSelector(
    state => state.management
  );

  const [changeMeterIdModalOpen, setChangeMeterIdModalOpen] = useState(false);
  const [deviceEventLogModalOpen, setDeviceEventLogModalOpen] = useState(false);
  const [archiveDeviceModalOpen, setArchiveDeviceModalOpen] = useState(false);

  const [device, setDevice] = useState(false);

  useEffect(() => {
    if (!hasDevices) {
      updateTableData(managementSortConfig);
    }
    // eslint-disable-next-line
  }, [dispatch, activeGroup]);

  const updateTableData = config => {
    setSelectedMeters([]);
    dispatch(
      ManagementActions.deviceListRequest({
        page: config.currentPage,
        size: config.perPage,
        sortableRow: camelCaseToSnakeCase(config.key),
        sortDirection: config.direction,
        searchStr: config.searchStr,
        groupId: getGroupId(activeGroup),
      })
    );
  };

  const setSortConfig = sortConfig => {
    dispatch(ManagementActions.setManageSortConfig(sortConfig));
    updateTableData(sortConfig);
  };

  const handleOpenChangeMeterParamsModal = item => {
    setDevice(item);
    setChangeMeterIdModalOpen(true);
  };

  const handleOpenAddEventLogModal = item => {
    setDevice(item);
    setDeviceEventLogModalOpen(true);
  };

  const handleOpenArchiveDeviceModal = item => {
    setDevice(item);
    setArchiveDeviceModalOpen(true);
  };

  const changeMeterParams = newMeterInfo => {
    dispatch(
      ManagementActions.setDeviceIdDiameterRequest({
        deviceId: newMeterInfo.deviceId,
        newMeterId: newMeterInfo.newMeterId,
        newDiameter: newMeterInfo.newDiameter,
        newDeviceInfo: newMeterInfo.newDeviceInfo,
        newDeviceAddress: newMeterInfo.newDeviceAddress,
        endUserMeter: newMeterInfo.endUserMeter,
        version: newMeterInfo.version,
        managementSortConfig,
      })
    );
    setChangeMeterIdModalOpen(false);
  };

  const handleSelection = (item, checked) => {
    if (!item) {
      // select all
      setSelectedMeters(checked ? [...devices] : []);
      return;
    }

    const tmpSelection = [...selectedMeters];
    const selectedIndex = tmpSelection.indexOf(item);
    if (selectedIndex !== -1) {
      tmpSelection.splice(selectedIndex, 1);
      setSelectedMeters(tmpSelection);
    } else {
      setSelectedMeters([...tmpSelection, item]);
    }
  };

  const setSelectedMeters = meterList => {
    dispatch(ManagementActions.setSelectedMeters(meterList));
  };

  const getAllRowsSelected = () => {
    return devices.length !== 0 && devices.length === selectedMeters.length;
  };

  return (
    <React.Fragment>
      <div className='grid grid--end-md'>
        <div className='grid__col--md-9 margin'>
          <ManagementFilter sortConfig={managementSortConfig} setSortConfig={setSortConfig} />
        </div>
      </div>
      <div className='grid grid--center-lg'>
        <div className='grid__col--md-3 margin'>
          <ResourceGroups />
        </div>
        <div className='grid__col--md-9 margin'>
          <table data-testid={'management-table'} className='tbl tbl--transparent tbl--borders tbl--row-highlight'>
            <ColumnRow
              sortConfig={managementSortConfig}
              setSortConfig={setSortConfig}
              totalElements={pageableInfo.totalElements}
              handleSelection={handleSelection}
              allRowsChecked={getAllRowsSelected()}
            />
            {!isLoadingDeviceList ? (
              <DataRow
                devices={devices}
                handleOpenChangeMeterParamsModal={value => handleOpenChangeMeterParamsModal(value)}
                handleOpenAddEventLogModal={value => handleOpenAddEventLogModal(value)}
                handleOpenArchiveDeviceModal={value => handleOpenArchiveDeviceModal(value)}
                handleSelection={handleSelection}
                selectedMeters={selectedMeters}
              />
            ) : (
              <tbody>
                <tr>
                  <td colSpan='8'>
                    <Loader />
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <Pagination
            sortConfig={managementSortConfig}
            setSortConfig={setSortConfig}
            totalPages={pageableInfo.totalPages}
            label={t('navigation.device_event_log.device_event_log_table.perPage')}
            totalElements={pageableInfo.totalElements}
          />
        </div>
      </div>
      {changeMeterIdModalOpen && (
        <ChangeDeviceIdDiameter
          open={changeMeterIdModalOpen}
          setOpen={setChangeMeterIdModalOpen}
          device={device}
          changeMeterParams={changeMeterParams}
        />
      )}
      {deviceEventLogModalOpen && (
        <DeviceEventLogModal open={deviceEventLogModalOpen} setOpen={setDeviceEventLogModalOpen} device={device} />
      )}
      {archiveDeviceModalOpen && <ArchiveDeviceModal open={archiveDeviceModalOpen} setOpen={setArchiveDeviceModalOpen} device={device} />}
    </React.Fragment>
  );
}
