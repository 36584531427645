import React from 'react';
import PropTypes from 'prop-types';

export default function Input(props) {
  const { defaultValue, disabled, param, onChange, testId, valueId } = props;

  return (
    <div className='textfield'>
      <input
        disabled={disabled}
        defaultValue={defaultValue}
        onChange={event => onChange(valueId, event, param)}
        type='text'
        pattern='[0-9]*'
        className='textfield__input'
        data-testid={testId}
      />
    </div>
  );
}

Input.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  param: PropTypes.object,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  valueId: PropTypes.string,
};
