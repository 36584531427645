export const deviceId = 'deviceId';
export const vlId = 'vlId';
export const cumulativeSeries = ['h', 'kWh', 'm3'];

export const chartColors = [
  '#696969',
  '#6b8e23',
  '#ff00ff',
  '#00008b',
  '#ff0000',
  '#ffa500',
  '#ffff00',
  '#00ff00',
  '#00fa9a',
  '#00ffff',
  '#0000ff',
  '#6495ed',
  '#ffe4b5',
  '#ff69b4',
  '#7f0000',
];

export const fileNameDateTimeFormat = 'DD_MM_YYYY-HH_mm';

export const dateTimeFormat = 'DD.MM.YYYY HH:mm';
export const dateTimeFormatDashed = 'DD-MM-YYYY-HH:mm';
export const dateTimeFormatShort = 'DD.MM HH:mm';
export const timeFormat = 'HH:ss';
export const shortTimeFormat = 'HH:mm';

export class CommonError extends Error {
  constructor(message, type, notificationDuration) {
    super(message);
    this.notificationDuration = notificationDuration;
    this.name = 'CommonError';
    this.type = type;
  }
}
