import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DateTimePicker from './DateTimePicker';

export default function StartEndDateCombo(props) {
  const { t } = useTranslation();
  const { startDate, setStartDate, endDate, setEndDate } = props;

  return (
    <div className='grid'>
      <div className='grid__col--sm-12 grid__col--lg-6'>
        <DateTimePicker
          label={t('navigation.reports.start_date')}
          value={moment(startDate).valueOf()}
          setDate={setStartDate}
          disableFuture={true}
          ariaLabel={'change start date'}
          id={'generateReportStart'}
        />
      </div>
      <div className='grid__col--sm-12 grid__col--lg-6'>
        <DateTimePicker
          label={t('navigation.reports.end_date')}
          value={moment(endDate).valueOf()}
          setDate={setEndDate}
          disableFuture={true}
          ariaLabel={'change end date'}
          id={'generateReportEnd'}
        />
      </div>
    </div>
  );
}

StartEndDateCombo.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setStartDate: PropTypes.func,
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setEndDate: PropTypes.func,
};
