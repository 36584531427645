import { UserAgentApplication } from 'msal';

import { getIsIE } from '../Services/Development/Development';

export default function useAuthentication() {
  const AzureScope = {
    scopes: ['https://graph.microsoft.com/.default'],
  };
  const AzureConfig = {
    auth: {
      clientId: window._env_.MULTIMETERING_AZURE_CLIENT_ID,
      redirectUri: window._env_.MULTIMETERING_REDIRECT_URI,
      postLogoutRedirectUri: window._env_.MULTIMETERING_REDIRECT_URI,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: getIsIE(),
    },
    system: {
      navigateFrameWait: 0,
      tokenRenewalOffsetSeconds: 60 * 5,
    },
  };

  const AzureADAuthentication = new UserAgentApplication(AzureConfig);
  AzureADAuthentication.handleRedirectCallback(redirectCallback);

  const silentLoginRequest = {
    scopes: [AzureConfig.auth.clientId],
  };

  function handleError() {
    return AzureADAuthentication.acquireTokenRedirect(silentLoginRequest);
  }

  async function getToken() {
    return AzureADAuthentication.acquireTokenSilent(silentLoginRequest).catch(handleError);
  }

  function getIsAuthenticated() {
    return Boolean(getAccount());
  }

  function getAccount() {
    return AzureADAuthentication.getAccount();
  }

  function forceLogin() {
    return AzureADAuthentication.loginRedirect(AzureScope);
  }

  function logout() {
    return AzureADAuthentication.logout();
  }

  function getUserRoles() {
    try {
      const roles = getAccount().idTokenClaims.roles;
      return roles ? roles : [];
    } catch (error) {
      console.error('Unable to get user roles');
    }
    return [];
  }

  function redirectCallback() {
    // This is intentional
  }

  return {
    handleError,
    getAccount,
    getIsAuthenticated,
    forceLogin,
    getToken,
    logout,
    getUserRoles,
    AzureADAuthentication,
  };
}
