import styled, { createGlobalStyle, css } from 'styled-components';
import * as normalize from 'normalize.css/normalize.css';
import * as modalStyles from './ModalStyles';

const appStyles = css`
  :root {
    --base-color: #394a57;
    --base-color-light: #6a6e71;
    --base-color-light2: #3a3b3e14;
    --base-color-dark: #243690;
    --base-color-dark2: #3a3b3e;

    --sidebar-color: #394a57;
    --topbar-color: #435563;
    --background-color-grey: #f7f8f8;
    --foreground-color: #ffffff;

    --highlight-color: #f27332;
    --shadow-color: #c3c8c8;
    --shadow-color-light: rgba(50, 50, 50, 0.15);
    --shadow-color-dark: rgba(50, 50, 50, 0.65);
    --text-color-light-grey: rgba(0, 0, 0, 0.4);
    --success-color: var(--highlight-color);
    --error-color: #ea2839;
    --warning-color: #f0ab00;
    --white: #fff;
    --black: #000;

    --add-resource-color: #c7dd03;

    --border-radius-button: 0px;
    --border-radius-round: 4px;
    --border-radius: 0.5em;
    --border-radius-left: 0.5em 0 0 0.5em;
    --border-radius-right: 0 0.5em 0.5em 0;
    --border-radius-input: 0px;

    --main-window-margin: 0 0.25rem 0 0.25rem;
    --main-window-margin-right: 0 0.25rem 0 0;
  }

  * {
    box-sizing: border-box;
  }

  .shadow {
    box-shadow: var(--box-shadow);
    border-radius: 0.4rem 0.4rem 0 0;
  }

  .white {
    color: var(--white);
  }

  .panel-header {
    font-size: 1.5rem;
    background-color: var(--topbar-color);
    padding: 0.5rem 0.5rem 0.5rem 2rem;
    margin: 0;
    border-radius: 0.4rem 0.4rem 0 0;
  }

  .panel-body {
    padding: 0;
    margin: 0;
  }

  .flex {
    display: flex;
  }

  input,
  textarea {
    border: 1px solid var(--shadow-color);
    border-radius: var(--border-radius-input);
    color: var(--base-color);
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0.5rem;

    text-align: left;
    width: 100%;

    ::placeholder {
      color: var(--shadow-color-dark);
    }

    :focus {
      outline-color: var(--base-color-dark);
    }
  }

  textarea {
    min-width: 20vw;
  }

  .active-group {
    background-color: var(--highlight-color) !important;
  }

  .pointer {
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }

  .no__pointer {
    cursor: not-allowed;
  }

  .descTableData {
    max-width: 25em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .select__input:focus,
  .textfield__input:focus {
    box-shadow: none;
  }

  @media screen and (min-width: 1200px) {
    .modal__body,
    .modal__footer,
    .modal__header {
      padding: 1em;
    }
  }
`;

export const Spinner = styled.div`
  margin: 2em;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const GlobalStyles = createGlobalStyle`
  ${normalize}
  ${appStyles}
  ${modalStyles}
`;
