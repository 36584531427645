import { createReducer } from '@reduxjs/toolkit';

import * as ManagementActions from './ManagementActions';
import { createPageableObject } from '../../Helpers/HelperMethods';
import { CommonSorting, MeterListSorting } from '../../Helpers/Enums';

const initialState = {
  isLoadingDeviceList: false,
  isLoadingDeviceInfo: false,
  devices: [],
  hasDevices: false,
  pageableInfo: {},
  deviceInfo: {},
  hasDeviceInfo: false,
  errorMessage: '',
  activeGroup: {},
  managementSortConfig: {
    key: MeterListSorting.address,
    direction: CommonSorting.descending,
    currentPage: 1,
    perPage: 25,
    searchStr: '',
  },
  selectedMeters: [],
};

export const ManagementReducer = createReducer(initialState, {
  [ManagementActions.SET_CURRENT_PAGE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      currentPage: payload,
    };
  },

  [ManagementActions.SET_ACTIVE_GROUP]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      hasDevices: initialState.hasDevices,
      devices: initialState.devices,
      activeGroup: payload,
      managementSortConfig: { ...state.managementSortConfig, currentPage: 1 },
    };
  },

  [ManagementActions.DEVICE_LIST_REQUEST]: state => {
    return {
      ...state,
      isLoadingDeviceList: true,
      devices: [],
      errorMessage: initialState.errorMessage,
    };
  },
  [ManagementActions.DEVICE_LIST_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoadingDeviceList: false,
      devices: payload.content,
      hasDevices: payload.content && payload.content.length > 0,
      pageableInfo: createPageableObject(payload),
      errorMessage: initialState.errorMessage,
    };
  },
  [ManagementActions.DEVICE_LIST_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoadingDeviceList: false,
      devices: initialState.devices,
      hasDevices: initialState.hasDevices,
      pageableInfo: initialState.pageableInfo,
      errorMessage: payload,
    };
  },

  [ManagementActions.DEVICE_REQUEST]: state => {
    return {
      ...state,
      isLoadingDeviceInfo: true,
      deviceInfo: {},
      hasDeviceInfo: false,
      errorMessage: initialState.errorMessage,
    };
  },
  [ManagementActions.DEVICE_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoadingDeviceInfo: false,
      deviceInfo: payload,
      hasDeviceInfo: !!payload.id,
      errorMessage: initialState.errorMessage,
    };
  },
  [ManagementActions.DEVICE_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoadingDeviceInfo: false,
      deviceInfo: initialState.deviceInfo,
      hasDeviceInfo: initialState.hasDeviceInfo,
      errorMessage: payload,
    };
  },

  [ManagementActions.SET_MANAGE_SORT_CONFIG]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      managementSortConfig: payload,
    };
  },

  [ManagementActions.SET_SELECTED_METERS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      selectedMeters: payload,
    };
  },
});
