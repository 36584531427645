import styled from 'styled-components';
import 'animate.css/animate.min.css';

export const GraphicsViewStyles = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 100%;

  .graphics {
    height: 100%;
    background-color: var(--foreground-color);
  }
`;
