import useAuthentication from '../../Hooks/useAuthentication';
import axios from 'axios';

const { getToken, handleError } = useAuthentication();

export const API_ENDPOINTS = {
  DEVICE_LIST: `${window._env_.MULTIMETERING_API}/v1/meter/devices`,
  DEVICE_INFO: `${window._env_.MULTIMETERING_API}/v1/meter/device`,
  READING: `${window._env_.MULTIMETERING_API}/v1/meter/reading`,
  RESOURCE_GROUP: `${window._env_.MULTIMETERING_API}/v1/resourcegroup`,
  ALERT_LIST: `${window._env_.MULTIMETERING_API}/v1/alert/all`,
  ALERT_LIST_WITH_COUNT: `${window._env_.MULTIMETERING_API}/v1/alert/allWithCount`,
  ALERT_LIST_BY_ID: `${window._env_.MULTIMETERING_API}/v1/alert/allByIds`,
  ALERT_STATUS: `${window._env_.MULTIMETERING_API}/v1/alert/status`,
  API_VERSION: `${window._env_.MULTIMETERING_API}/v1/version`,
  REPORTING: `${window._env_.MULTIMETERING_API}/v1/meter/reporting`,
  BILLING: `${window._env_.MULTIMETERING_API}/v1/bill`,
  DEVICE_EVENT_LOG: `${window._env_.MULTIMETERING_API}/v1/deviceeventlog`,
  DASHBOARD: `${window._env_.MULTIMETERING_API}/v1/dashboard`,
};

export function getFetchOptions(token, responseType) {
  return {
    cache: 'no-cache',
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    mode: 'cors',
    redirect: 'follow',
    referrer: 'no-referrer',
    responseType: responseType,
  };
}

async function getAccessToken() {
  try {
    const { idToken } = await getToken();
    const { rawIdToken } = idToken;
    return rawIdToken;
  } catch (error) {
    return undefined;
  }
}

export async function getData(url, responseType) {
  const token = await getAccessToken();
  const fetchOptions = getFetchOptions(token, responseType);
  return axios.get(url, fetchOptions).catch(handleHttpError);
}

export async function postData(url, data) {
  const token = await getAccessToken();
  const fetchOptions = getFetchOptions(token);
  return axios.post(url, data, fetchOptions).catch(handleHttpError);
}

function handleHttpError(error) {
  if (error.message.indexOf('401') !== -1) {
    handleError();
  } else {
    throw error;
  }
}
