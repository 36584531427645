import { createAction } from '@reduxjs/toolkit';

export const DEVICE_LIST_REQUEST = createAction('DEVICE_LIST_REQUEST');
export const deviceListRequest = payload => DEVICE_LIST_REQUEST(payload);

export const DEVICE_LIST_SUCCESS = createAction('DEVICE_LIST_SUCCESS');
export const deviceListSuccess = payload => DEVICE_LIST_SUCCESS(payload);

export const DEVICE_LIST_FAILURE = createAction('DEVICE_LIST_FAILURE');
export const deviceListFailure = payload => DEVICE_LIST_FAILURE(payload);

export const DEVICE_REQUEST = createAction('DEVICE_REQUEST');
export const deviceInfoRequest = payload => DEVICE_REQUEST(payload);

export const DEVICE_SUCCESS = createAction('DEVICE_SUCCESS');
export const deviceInfoSuccess = payload => DEVICE_SUCCESS(payload);

export const DEVICE_FAILURE = createAction('DEVICE_FAILURE');
export const deviceInfoFailure = payload => DEVICE_FAILURE(payload);

export const SET_ACTIVE_GROUP = createAction('SET_ACTIVE_GROUP');
export const setActiveGroup = payload => SET_ACTIVE_GROUP(payload);

export const SET_CURRENT_PAGE = createAction('SET_CURRENT_PAGE');
export const setCurrentPage = payload => SET_CURRENT_PAGE(payload);

export const SET_DEVICEID_DIAMETER_REQUEST = createAction('SET_DEVICEID_DIAMETER_REQUEST');
export const setDeviceIdDiameterRequest = payload => SET_DEVICEID_DIAMETER_REQUEST(payload);

export const SET_DEVICEID_DIAMETER_SUCCESS = createAction('SET_DEVICEID_DIAMETER_SUCCESS');
export const setDeviceIdDiameterSuccess = payload => SET_DEVICEID_DIAMETER_SUCCESS(payload);

export const SET_DEVICEID_DIAMETER_FAILURE = createAction('SET_DEVICEID_DIAMETER_FAILURE');
export const setDeviceIdDiameterFailure = payload => SET_DEVICEID_DIAMETER_FAILURE(payload);

export const SET_MANAGE_SORT_CONFIG = createAction('SET_MANAGE_SORT_CONFIG');
export const setManageSortConfig = payload => SET_MANAGE_SORT_CONFIG(payload);

export const SET_SELECTED_METERS = createAction('SET_SELECTED_METERS');
export const setSelectedMeters = payload => SET_SELECTED_METERS(payload);
