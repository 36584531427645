import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';

import { flattenReportsRawData, sortReportsTableData } from '../../Helpers/HelperMethods';
import { CommonSorting, ReportingSorting } from '../../Helpers/Enums';
import * as ReportActions from './ReportActions';

const initialState = {
  reportsData: [],
  reportsSortedAndFilteredData: [],
  reportingSensors: [],
  reportsCSVData: '',
  reportsExportTableCSVData: '',
  reportsCSVDate: {
    startDate: moment().startOf('month').startOf('day').format(),
    endDate: moment().format(),
  },
  isReportsLoading: false,
  isReportsSensorsLoading: false,
  reportsCSVLoading: false,
  reportingDataErrorMessage: '',
  reportingSensorsErrorMessage: '',
  reportsCSVErrorMessage: '',
  reportsSortConfig: {
    key: ReportingSorting.readingTime,
    direction: CommonSorting.descending,
    searchStr: '',
  },

  meterHistoryDates: {
    startDate: moment().startOf('month').startOf('day').format(),
    endDate: moment().format(),
  },
  devicesForMeterHistory: [],
  devicesForMeterHistoryLoading: false,
  devicesForMeterHistoryError: '',

  meterHistoryLoading: false,
  meterHistory: '',
  meterHistoryError: '',
};

export const ReportReducer = createReducer(initialState, {
  [ReportActions.GET_REPORTS_REQUEST]: state => {
    return {
      ...state,
      isReportsLoading: true,
      reportingDataErrorMessage: initialState.reportingDataErrorMessage,
    };
  },
  [ReportActions.GET_REPORTS_SUCCESS]: (state, action) => {
    const { payload } = action;
    const parsedData = flattenReportsRawData(payload);
    const sortedByReadingTime = sortReportsTableData(parsedData, initialState.reportsSortConfig);
    return {
      ...state,
      reportsData: sortedByReadingTime,
      reportsSortedAndFilteredData: sortedByReadingTime,
      isReportsLoading: initialState.isReportsLoading,
      reportingDataErrorMessage: initialState.reportingDataErrorMessage,
    };
  },
  [ReportActions.GET_REPORTS_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isReportsLoading: initialState.isReportsLoading,
      reportingDataErrorMessage: payload,
    };
  },
  [ReportActions.SET_REPORTS_SORTED_DATA]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      reportsSortedAndFilteredData: payload,
    };
  },
  [ReportActions.SET_REPORT_SORT_CONFIG]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      reportsSortConfig: payload,
    };
  },
  [ReportActions.GET_REPORTING_SENSORS_REQUEST]: state => {
    return {
      ...state,
      isReportsSensorsLoading: true,
      reportingSensorsErrorMessage: initialState.reportingSensorsErrorMessage,
    };
  },
  [ReportActions.GET_REPORTING_SENSORS_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      reportingSensors: payload,
      isReportsSensorsLoading: initialState.isReportsSensorsLoading,
      reportingSensorsErrorMessage: initialState.reportingSensorsErrorMessage,
    };
  },
  [ReportActions.GET_REPORTING_SENSORS_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isReportsSensorsLoading: initialState.isReportsSensorsLoading,
      reportingSensorsErrorMessage: payload,
    };
  },
  [ReportActions.GET_REPORT_CSV_REQUEST]: state => {
    return {
      ...state,
      reportsCSVLoading: true,
      reportsCSVErrorMessage: initialState.reportsCSVErrorMessage,
    };
  },
  [ReportActions.GET_REPORT_CSV_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      reportsCSVData: payload.replace(/\./g, ','),
      reportsCSVLoading: initialState.reportsCSVLoading,
      reportsCSVErrorMessage: initialState.reportsCSVErrorMessage,
    };
  },
  [ReportActions.GET_REPORT_CSV_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      reportsCSVLoading: initialState.reportsCSVLoading,
      reportsCSVErrorMessage: payload,
    };
  },
  [ReportActions.CLEAR_REPORT_CSV_DATA]: (state, action) => {
    return {
      ...state,
      reportsCSVData: initialState.reportsCSVData,
      reportsCSVLoading: initialState.reportsCSVLoading,
      reportsCSVErrorMessage: initialState.reportsCSVErrorMessage,
    };
  },
  [ReportActions.SET_REPORT_CSV_DATE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      reportsCSVDate: payload,
    };
  },
  [ReportActions.SET_REPORTS_EXPORT_TABLE_CSV_DATA]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      reportsExportTableCSVData: payload,
    };
  },
  [ReportActions.CLEAR_REPORTS_EXPORT_TABLE_CSV_DATA]: (state, action) => {
    return {
      ...state,
      reportsExportTableCSVData: initialState.reportsExportTableCSVData,
    };
  },
  [ReportActions.SET_METER_HISTORY_DATES]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      meterHistoryDates: payload,
    };
  },
  [ReportActions.GET_DEVICE_FOR_METER_HISTORY_REQUEST]: state => {
    return {
      ...state,
      devicesForMeterHistoryLoading: true,
    };
  },
  [ReportActions.GET_DEVICE_FOR_METER_HISTORY_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      devicesForMeterHistoryLoading: false,
      devicesForMeterHistory: [...state.devicesForMeterHistory, payload],
    };
  },
  [ReportActions.GET_DEVICE_FOR_METER_HISTORY_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      devicesForMeterHistoryLoading: false,
      devicesForMeterHistoryError: payload,
    };
  },
  [ReportActions.REMOVE_DEVICE_FROM_METER_HISTORY]: (state, action) => {
    const { payload } = action;
    const data = removeDeviceAndReadings(JSON.stringify(state.devicesForMeterHistory), payload);
    return {
      ...state,
      devicesForMeterHistory: data.devicesForMeterHistory,
    };
  },
  [ReportActions.GET_METER_HISTORY_REQUEST]: state => {
    return {
      ...state,
      meterHistoryLoading: true,
    };
  },
  [ReportActions.GET_METER_HISTORY_SUCCESS]: state => {
    return {
      ...state,
      meterHistoryLoading: false,
      meterHistory: '',
    };
  },
  [ReportActions.GET_METER_HISTORY_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      meterHistoryLoading: false,
      meterHistoryError: payload,
    };
  },
});

export const getExistingDevicesMeterHistory = state => {
  return state.reports.devicesForMeterHistory;
};

const removeDeviceAndReadings = (devices, deviceIdentifier) => {
  const devicesParsed = JSON.parse(devices);
  for (const device in devicesParsed) {
    if (devicesParsed.hasOwnProperty(device)) {
      const dId = devicesParsed[device].identifier;
      if (deviceIdentifier === dId) {
        devicesParsed.splice(device, 1);
      }
    }
  }
  return {
    devicesForMeterHistory: devicesParsed,
  };
};
