import React from 'react';
import { useTranslation } from 'react-i18next';

import { DurationEnum } from '../../../Helpers/Enums';
import { GraphLengthSelectorStyles } from './GraphLengthSelector.styles';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

const GraphLengthSelector = props => {
  const { t } = useTranslation();

  const { handleDurationChange, handleEndTimeChange, duration, endTime, showLabel } = props;

  const handleDateChange = value => {
    if (moment(value).dayOfYear() === moment(moment.now()).dayOfYear()) {
      handleEndTimeChange(moment(moment.now()).format());
    } else {
      handleEndTimeChange(moment(moment(value).add(1, 'days').startOf('day').subtract(1, 'minute')).format());
    }
  };

  return (
    <GraphLengthSelectorStyles>
      {showLabel && <label className='select__label'>{t('navigation.graph.Graph_length')}</label>}
      <div className='flex'>
        <fieldset className='selector is-checked '>
          <div className='selector__list'>
            <div className='selector__item'>
              <input
                type='radio'
                name='selector'
                value={DurationEnum.ONE_DAY}
                id='ONE_DAY'
                data-testid='ONE_DAY'
                className='selector__input'
                onChange={() => handleDurationChange(DurationEnum.ONE_DAY)}
                checked={duration === DurationEnum.ONE_DAY}
              />
              <label htmlFor='ONE_DAY' className='selector__indicator'>
                {t('navigation.graph.button_group.ONE_DAY')}
              </label>
            </div>
            <div className='selector__item'>
              <input
                type='radio'
                name='selector'
                value={DurationEnum.TWO_DAYS}
                id='TWO_DAYS'
                data-testid='TWO_DAYS'
                className='selector__input'
                onChange={() => handleDurationChange(DurationEnum.TWO_DAYS)}
                checked={duration === DurationEnum.TWO_DAYS}
              />
              <label htmlFor='TWO_DAYS' className='selector__indicator'>
                {t('navigation.graph.button_group.TWO_DAYS')}
              </label>
            </div>
            <div className='selector__item'>
              <input
                type='radio'
                name='selector'
                value={DurationEnum.THREE_DAYS}
                id='THREE_DAYS'
                data-testid='THREE_DAYS'
                className='selector__input'
                onChange={() => handleDurationChange(DurationEnum.THREE_DAYS)}
                checked={duration === DurationEnum.THREE_DAYS}
              />
              <label htmlFor='THREE_DAYS' className='selector__indicator'>
                {t('navigation.graph.button_group.THREE_DAYS')}
              </label>
            </div>
            <div className='selector__item'>
              <input
                type='radio'
                name='selector'
                value={DurationEnum.SEVEN_DAYS}
                id='SEVEN_DAYS'
                data-testid='SEVEN_DAYS'
                className='selector__input'
                onChange={() => handleDurationChange(DurationEnum.SEVEN_DAYS)}
                checked={duration === DurationEnum.SEVEN_DAYS}
              />
              <label htmlFor='SEVEN_DAYS' className='selector__indicator'>
                {t('navigation.graph.button_group.SEVEN_DAYS')}
              </label>
            </div>
          </div>
        </fieldset>
        <MuiPickersUtilsProvider utils={DateFnsUtils} className={'date-picker'}>
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            format='dd.MM.yyyy HH:mm'
            margin='normal'
            id='date-picker-inline'
            label={t('navigation.graph.graphEndDate')}
            value={moment(endTime).valueOf()}
            onChange={handleDateChange}
            autoOk
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    </GraphLengthSelectorStyles>
  );
};

export default GraphLengthSelector;
