import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as AlertActions from '../../../Store/Alert/AlertActions';
import { AlertStatusEnumMap } from '../../../Helpers/Enums';
import Dialog from '@material-ui/core/Dialog';
import Header from '../Header/Header';

export default function CloseAlertModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { open, setOpen, alertsToClose } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const changeAlertStatus = () => {
    dispatch(AlertActions.changeAlertStatus({ alerts: alertsToClose, newStatus: AlertStatusEnumMap['3'] }));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <Header headerText={t('navigation.alerts.alert_list.close_confirmation')} handleClose={handleClose} />
      <div className='modal__footer'>
        <div className='btn-group btn-group--eq '>
          <button className='btn' onClick={handleClose}>
            <span className='btn__text'>{t('navigation.manage.resource_group.btn_cancel')}</span>
          </button>
          <button className='btn btn--primary' onClick={changeAlertStatus} data-testid='closeBtn'>
            <span className='btn__text'>{t('navigation.alerts.alert_list.close_action')}</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
}
