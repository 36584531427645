import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SearchBox from '../Inputs/SearchBox/SearchBox';
import { isRealResourceGroup } from '../../Helpers/HelperMethods';
import { AlertStatusEnumMap, AlertType } from '../../Helpers/Enums';
import * as SensorActions from '../../Store/Sensor/SensorActions';
import * as ResourceGroupActions from '../../Store/ResourceGroup/ResourceGroupActions';

export default function AlertFilter(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { hasSensors, sensors } = useSelector(state => state.sensor);

  const { resourceGroups, hasResourceGroups } = useSelector(state => state.resourceGroup);

  const { sortConfig, setSortConfig } = props;

  useEffect(() => {
    if (!hasResourceGroups) {
      dispatch(ResourceGroupActions.getAllResourceGroupsRequest());
    }
  }, [dispatch, hasResourceGroups]);

  useEffect(() => {
    if (!hasSensors) {
      dispatch(SensorActions.getSensorsRequest());
    }
  }, [dispatch, hasSensors]);

  const handleSearchClick = value => {
    setSortConfig({ ...sortConfig, currentPage: 1, searchStr: value });
  };

  const handleStatusFilterChange = value => {
    setSortConfig({ ...sortConfig, alertStatus: value, currentPage: 1 });
  };

  const handleMessageFilterChange = value => {
    setSortConfig({ ...sortConfig, alertMessage: value, currentPage: 1 });
  };

  const handleGroupFilterChange = value => {
    setSortConfig({ ...sortConfig, alertGroup: value, currentPage: 1 });
  };

  return (
    <div className='grid'>
      <div className='grid__col--sm-6 grid__col--md-3'>
        <SearchBox
          onClick={value => handleSearchClick(value)}
          placeholder={t('navigation.manage.meter_list.search.address')}
          component={'alert'}
          action={'alertList'}
          showClearBtn={true}
        />
      </div>
      <div className='grid__col--sm-6 grid__col--md-3'>
        <div className='select '>
          <svg className='icon select__icon'>
            <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-updown`} />
          </svg>
          <select
            data-testid='messageFilter'
            className='select__input'
            defaultValue={sortConfig.alertMessage}
            onChange={event => handleMessageFilterChange(event.target.value)}
          >
            <option value=''>{t('navigation.alerts.alert_list.filter.all_messages')}</option>
            <option value={AlertType['2']}>{t('navigation.alerts.alert_list.filter.no_reading')}</option>
            {sensors.map(sensor => {
              return (
                <option key={sensor.sensorTypeId} value={sensor.sensorTypeId}>
                  {sensor.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className='grid__col--sm-6 grid__col--md-3'>
        <div className='select '>
          <svg className='icon select__icon'>
            <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-updown`} />
          </svg>
          <select
            data-testid='statusFilter'
            className='select__input'
            defaultValue={sortConfig.alertStatus}
            onChange={event => handleStatusFilterChange(event.target.value)}
          >
            <option value=''>{t('navigation.alerts.alert_list.filter.all_statuses')}</option>
            <option value={AlertStatusEnumMap['0']}>{AlertStatusEnumMap['0']}</option>
            <option value={AlertStatusEnumMap['1']}>{AlertStatusEnumMap['1']}</option>
          </select>
        </div>
      </div>
      <div className='grid__col--sm-6 grid__col--md-3'>
        <div className='select '>
          <svg className='icon select__icon'>
            <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-updown`} />
          </svg>
          <select
            data-testid='groupFilter'
            className='select__input'
            defaultValue={sortConfig.alertGroup}
            onChange={event => handleGroupFilterChange(event.target.value)}
          >
            <option value=''>{t('navigation.alerts.alert_list.filter.all_groups')}</option>
            {resourceGroups.map(group => {
              return (
                isRealResourceGroup(group) && (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                )
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}
