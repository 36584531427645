import { createReducer } from '@reduxjs/toolkit';
import * as ResourceGroupActions from './ResourceGroupActions';

const initialState = {
  isGetAllLoading: false,
  hasResourceGroups: false,
  resourceGroups: [],
  errorMessage: '',
};

export const ResourceGroupReducer = createReducer(initialState, {
  [ResourceGroupActions.GET_ALL_RESOURCE_GROUPS_REQUEST]: state => {
    return {
      ...state,
      isGetAllLoading: true,
      resourceGroups: [],
      errorMessage: initialState.errorMessage,
    };
  },
  [ResourceGroupActions.GET_ALL_RESOURCE_GROUPS_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isGetAllLoading: false,
      hasResourceGroups: payload && payload.length > 0,
      resourceGroups: payload,
      errorMessage: initialState.errorMessage,
    };
  },
  [ResourceGroupActions.GET_ALL_RESOURCE_GROUPS_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isGetAllLoading: false,
      hasResourceGroups: initialState.hasResourceGroups,
      resourceGroups: initialState.resourceGroups,
      errorMessage: payload,
    };
  },
});
