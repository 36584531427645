import React from 'react';
import { Link } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

import { ROUTE_PATHS } from '../Routes/Routes';
import {
  GroupParams,
  MeterListTableColumn,
  ResourceGroupCrud,
  AlertStatusEnumMap,
  AlertType,
  CommonSorting,
  ReportingSorting,
  AlertTypeEnumMap,
  CustomAlertNames,
  deviceParams,
} from './Enums';
import * as Common from './Common';
import moment from 'moment';

export function getParamFromUrl(param) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get(param);
}

export function createPageableObject(data) {
  return {
    pageable: data.pageable,
    totalElements: data.totalElements,
    totalPages: data.totalPages,
    last: data.last,
    number: data.number,
    size: data.size,
    sort: data.sort,
    numberOfElements: data.numberOfElements,
    first: data.first,
    empty: data.empty,
  };
}

export function getLoadPath() {
  return `${process.env.PUBLIC_URL}/assets/i18n/{{ns}}/{{lng}}.json`;
}

export function getGraphSeries(readings) {
  return readings.map(sensor => {
    return createGraphSeriesFromSensorData(sensor);
  });
}

export function createGraphSeriesFromSensorData(sensor) {
  return {
    name: sensor.metaData && sensor.metaData.name ? sensor.metaData.name : sensor.sensorId,
    data: createDeltas(createDataList(sensor.readings), sensor.metaData.unit),
  };
}

export function createDeltas(dataList, unit) {
  if (Common.cumulativeSeries.indexOf(unit) === -1) {
    return dataList;
  }

  const deltas = dataList.map((v, i, a) => {
    return {
      x: v.x,
      y: parseFloat((parseFloat(v.y) - parseFloat(a[i - 1] ? a[i - 1].y : 0)).toFixed(2)),
    };
  });

  deltas[0].y = null;
  return deltas;
}

export function getGraphOptions(readings, id, defaultLegend) {
  const seriesShown = [];
  const unitAndSeriesMap = getUnitAndSeriesMap(readings);

  const opts = {
    chart: {
      id: id,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      x: {
        format: 'HH:mm',
      },
      y: {
        formatter: function (value) {
          return value ? value.toFixed(5) : value;
        },
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    markers: {
      size: 2,
      strokeWidth: 1,
      hover: {
        size: 3,
      },
    },
    legend: {
      show: defaultLegend,
      showForSingleSeries: defaultLegend,
      itemMargin: {
        horizontal: 10,
        vertical: 20,
      },
    },
    yaxis: readings.map(sensor => {
      const sensorUnitId = sensor.metaData.unit ? sensor.metaData.unit : sensor.sensorId;
      const yAxisOptions = {
        show: !seriesShown.includes(sensorUnitId),
        showAlways: unitAndSeriesMap[sensorUnitId].count > 1,
        forceNiceScale: true,
        decimalsInFloat: 0,
        opposite: unitAndSeriesMap[sensorUnitId].opposite,
        seriesName: unitAndSeriesMap[sensorUnitId].name,
        title: {
          text: sensorUnitId,
          style: {
            fontSize: '14px',
          },
        },
        axisBorder: {
          show: true,
        },
      };
      seriesShown.push(sensorUnitId);
      return yAxisOptions;
    }),
    colors: Common.chartColors,
  };

  if (!opts.yaxis[0]) {
    opts.yaxis[0] = {
      show: false,
    };
  }

  return opts;
}

export function getCompareData(readingsForCompare, sensorToCompare) {
  const data = {
    readings: [],
    id: 'compare-id',
    uniqueSensors: [],
  };

  readingsForCompare.forEach(sensor => {
    sensor.readings.forEach(reading => {
      if (data.uniqueSensors.indexOf(reading.metaData.name) === -1) {
        data.uniqueSensors.push(reading.metaData.name);
      }
      const deviceData = sensor.device && sensor.device.data ? JSON.parse(sensor.device.data) : { address: '' };
      if (reading.metaData.name === sensorToCompare) {
        const r = {
          toggleShow: sensor.toggleShow,
          meterId: deviceData.meterId ? deviceData.meterId : null,
          device: sensor.device.identifier,
          sensorId: reading.sensorId,
          address: deviceData.address ? deviceData.address : null,
          metaData: {
            name: createMetadataNameForGraph(deviceData),
            unit: reading.metaData.unit,
          },
          readings: reading.readings,
        };
        data.readings.push(r);
      }
    });
  });

  return data;
}

export const createMetadataNameForGraph = deviceData => {
  const meterIdText = deviceData.meterId ? ` (Meter ID: ${deviceData.meterId})` : '';
  return `${deviceData.address}${meterIdText}`;
};

export function createDataList(list) {
  return list.map(reading => {
    return { x: reading.time, y: reading.value };
  });
}

export const parseSelectedRows = rows => {
  const parsedList = [];
  for (const x in rows) {
    if (rows.hasOwnProperty(x)) {
      parsedList.push(rows[x].virtualLocationId);
    }
  }
  return parsedList;
};

export const customSort = (rows, field, direction) => {
  const handleField = row => {
    if (row[field] && field === MeterListTableColumn.device_id) {
      return row[field].props.children.toLowerCase();
    } else if (row[field]) {
      return row[field].toLowerCase();
    }
    return row[field];
  };
  return orderBy(rows, handleField, direction);
};

export const customSortForAlertTable = (a, b, sortConfig) => {
  const first = a[sortConfig.key] ? a[sortConfig.key] : '--';
  const second = b[sortConfig.key] ? b[sortConfig.key] : '--';
  if (first < second) {
    return sortConfig.direction === CommonSorting.ascending ? -1 : 1;
  }
  if (first > second) {
    return sortConfig.direction === CommonSorting.ascending ? 1 : -1;
  }
  return 0;
};

export const mapMeterListData = (devices, setOpen, setDevice) => {
  const setData = device => {
    setOpen(true);
    setDevice(device);
  };
  return devices.map(device => {
    const meterDataParsed = device.data ? JSON.parse(device.data) : {};
    return {
      id: device.virtualLocationId,
      address: meterDataParsed.address,
      device_id: (
        <Link className={'device-id'} to={`${ROUTE_PATHS.GRAPHICS}?vlId=${device.virtualLocationId}&deviceId=${device.id}`}>
          {device.identifier}
        </Link>
      ),
      type: meterDataParsed.type,
      diameter: meterDataParsed.diameter,
      data: device.data,
      deviceId: device.id,
      deviceIdentifier: device.identifier,
      changeMeterIdModal: (
        <svg
          className='icon  btn__icon cursor-pointer'
          onClick={() => {
            setData(device);
          }}
        >
          <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#pen`} />
        </svg>
      ),
    };
  });
};

export const isGroupForAllMeters = group => {
  return group.id === GroupParams.all_id && group.name === GroupParams.all_name && group.description === GroupParams.all_desc;
};

export const isRealResourceGroup = group => {
  return (
    !isGroupForAllMeters(group) &&
    !isGroupForUngrouped(group) &&
    !isGroupForUnarchived(group) &&
    !isGroupForArchived(group) &&
    !isGroupForUnarchivedAndUngrouped(group) &&
    !isGroupForArchivedAndUngrouped(group)
  );
};

export const isGroupForUngrouped = group => {
  return (
    group.id === GroupParams.ungrouped_id && group.name === GroupParams.ungrouped_name && group.description === GroupParams.ungrouped_desc
  );
};

export const isGroupForUnarchived = group => {
  return (
    group.id === GroupParams.unarchived_id &&
    group.name === GroupParams.unarchived_name &&
    group.description === GroupParams.unarchived_desc
  );
};

export const isGroupForArchived = group => {
  return (
    group.id === GroupParams.archived_id && group.name === GroupParams.archived_name && group.description === GroupParams.archived_desc
  );
};

export const isGroupForUnarchivedAndUngrouped = group => {
  return (
    group.id === GroupParams.unarchived_ungrouped_id &&
    group.name === GroupParams.unarchived_ungrouped_name &&
    group.description === GroupParams.unarchived_ungrouped_desc
  );
};

export const isGroupForArchivedAndUngrouped = group => {
  return (
    group.id === GroupParams.archived_ungrouped_id &&
    group.name === GroupParams.archived_ungrouped_name &&
    group.description === GroupParams.archived_ungrouped_desc
  );
};

export const isGroupUndefined = activeGroup => {
  return activeGroup.id === GroupParams.none;
};

export const hasActiveGroup = activeGroup => {
  return activeGroup && activeGroup.id && activeGroup.name && activeGroup.description;
};

export const disableClick = (input, length) => {
  return !(input.length === 0 || input.length >= length);
};

export const getUnitAndSeriesMap = readings => {
  const unitAndSeriesMap = {};

  readings.forEach(sensor => {
    const sensorUnitId = sensor.metaData.unit ? sensor.metaData.unit : sensor.sensorId;
    if (!unitAndSeriesMap[sensorUnitId]) {
      unitAndSeriesMap[sensorUnitId] = {
        name: sensor.metaData.name,
        count: 1,
        opposite: Object.keys(unitAndSeriesMap).length % 2 !== 0,
      };
    } else {
      unitAndSeriesMap[sensorUnitId].count++;
    }
  });

  return unitAndSeriesMap;
};

export const isModeDelete = mode => {
  return mode === ResourceGroupCrud.delete;
};

export const isModeAdd = mode => {
  return mode === ResourceGroupCrud.add;
};

export const isModeEdit = mode => {
  return mode === ResourceGroupCrud.edit;
};

export const hasValue = value => {
  const trimmedInput = value.trim();
  return trimmedInput.length > 0;
};

export const getSearchBoxInputFromState = (state, component, action) => {
  if (state.searchBox && state.searchBox[component] && state.searchBox[component][action]) {
    return state.searchBox[component][action];
  }
  return { searchBoxInput: '' };
};

export const createUniqueId = (deviceId, sensorTypeId, resourceGroupId, alertStatus) => {
  const rgId = resourceGroupId ? resourceGroupId : 'no-group';
  return `${deviceId}_${sensorTypeId}_${rgId}_${alertStatus}`;
};

export const createErrorMsgPrams = item => {
  const parseAlertData = JSON.parse(item.alertData);
  return {
    readingValue: parseFloat(parseAlertData.readingValue).toFixed(2),
    sensorTypeName: item.sensorTypeName,
    threshold: parseAlertData.threshold ? `> ${parseAlertData.threshold}%` : '',
  };
};

export const collapseAllRows = () => {
  const collapseElements = document.getElementsByClassName('show');
  const collapseElements2 = [...collapseElements];
  for (const x in collapseElements2) {
    if (collapseElements2.hasOwnProperty(x)) {
      collapseElements2[x].classList.remove('show');
    }
  }
  const arrowElements = document.getElementsByClassName('collapse-trigger');
  const arrowElements2 = [...arrowElements];
  for (const x in arrowElements2) {
    if (arrowElements2.hasOwnProperty(x)) {
      arrowElements2[x].classList.add('collapsed');
    }
  }
};

export const requestSort = (key, sortConfig, setSortConfig) => {
  let direction = CommonSorting.ascending;
  if (sortConfig && sortConfig.key === key && sortConfig.direction === CommonSorting.ascending) {
    direction = CommonSorting.descending;
  }
  setSortConfig({ ...sortConfig, key, direction });
  collapseAllRows();
};

export const getCaretIcon = (sortConfig, name) => {
  if (sortConfig.key === name) {
    return (
      <svg className='icon icon--middle '>
        <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#${getCaretDirection(sortConfig)}`} />
      </svg>
    );
  } else {
    return (
      <svg className='icon icon--middle '>
        <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-updown`} />
      </svg>
    );
  }
};

export const getCaretDirection = sortConfig => {
  return sortConfig.direction === CommonSorting.descending ? 'caret-down' : 'caret-up';
};

export const filterAlerts = (array, sortConfig) => {
  return array.filter(alert => {
    const parseAlertData = JSON.parse(alert.alertData);
    const addressLower = parseAlertData.deviceAddress ? parseAlertData.deviceAddress.toLowerCase() : '';
    return (
      addressContainsSearchStr(addressLower, sortConfig.searchStr) &&
      checkAlertStatus(alert.alertStatus, sortConfig.alertStatus) &&
      checkAlertMessage(alert, sortConfig.alertMessage) &&
      checkAlertGroup(alert.resourceGroupId, sortConfig.alertGroup)
    );
  });
};

export const addressContainsSearchStr = (searchFrom, searchStr) => {
  if (searchFrom === searchStr.toLowerCase()) {
    return true;
  }
  const searchStrSplitted = searchStr.split(' ');
  searchFrom = searchFrom.split(',');
  for (const x in searchStrSplitted) {
    if (searchStrSplitted.hasOwnProperty(x)) {
      const item = searchStrSplitted[x].toLowerCase();
      if (!searchFrom[0].includes(item)) {
        return false;
      }
    }
  }
  return true;
};

export const checkAlertStatus = (status, searchStatus) => {
  if (searchStatus) {
    return AlertStatusEnumMap[status] === searchStatus;
  } else {
    return true;
  }
};

export const checkAlertMessage = (alert, searchMessage) => {
  if (searchMessage === AlertType['2']) {
    return alert.alertType === 2;
  } else if (searchMessage) {
    return alert.sensorTypeId === searchMessage;
  } else {
    return true;
  }
};

export const checkAlertGroup = (group, searchGroup) => {
  if (searchGroup) {
    return group === searchGroup;
  } else {
    return true;
  }
};

export const getAlertStatus = value => {
  return AlertStatusEnumMap[value];
};

export const getAlertType = value => {
  return AlertTypeEnumMap[value];
};

export const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const convertReportsTableData = (data, sensors) => {
  const sensorIdNameMap = getSensorNameIdMap(sensors);

  return data.map(device => {
    const obj = {};

    Object.keys(device).forEach(item => {
      if (sensorIdNameMap[item]) {
        obj[sensorIdNameMap[item]] = device[item];
      } else {
        obj[item] = device[item];
      }
    });

    return obj;
  });
};

export const getSensorNameIdMap = sensors => {
  const res = {};

  Object.keys(sensors).forEach(sensor => {
    res[sensors[sensor].sensorId] = sensors[sensor].name;
  });

  return res;
};

export const flattenReportsRawData = rawData => {
  return Object.keys(rawData).map(device => {
    return {
      deviceIdentifier: device,
      address: rawData[device].address,
      meterIdentifier: rawData[device].meterIdentifier,
      virtualLocationId: rawData[device].virtualLocationId,
      deviceId: rawData[device].deviceId,
      readingTime: moment(rawData[device].readingTime).format(Common.dateTimeFormat),
      ...parseReadingsDecimal(rawData[device].readings),
    };
  });
};

export const parseReadingsDecimal = readings => {
  for (const x in readings) {
    if (readings.hasOwnProperty(x)) {
      const reading = readings[x];
      if (Math.round(reading).toString() !== reading) {
        readings[x] = parseFloat(reading).toFixed(1).replace('.', ',');
      }
    }
  }
  return readings;
};

export const sortReportsTableData = (data, sortConfig) => {
  return data.sort((a, b) => {
    if (sortConfig.key === ReportingSorting.address || sortConfig.key === ReportingSorting.readingTime) {
      return customSortForAlertTable(a, b, sortConfig);
    } else {
      return customSortForReportsTable(a, b, sortConfig);
    }
  });
};

export const customSortForReportsTable = (a, b, sortConfig) => {
  const first = a[sortConfig.key] ? a[sortConfig.key].replace(',', '.') : -9999;
  const second = b[sortConfig.key] ? b[sortConfig.key].replace(',', '.') : -9999;
  if (sortConfig.direction === CommonSorting.ascending) {
    return first - second;
  } else {
    return second - first;
  }
};

export const filterData = (searchStr, dataToFilter) => {
  return dataToFilter.filter(device => {
    return Object.keys(device).some(key => {
      return device[key] ? device[key].toLowerCase().includes(searchStr.toLowerCase()) : false;
    });
  });
};

export const getLatestAlert = alertList => {
  const sortedByCreatedDate = alertList.sort((a, b) => {
    return a.createdDate > b.createdDate ? -1 : 1;
  });

  return sortedByCreatedDate[0];
};

export const getAddEventLogRequestData = device => {
  return {
    deviceId: device.id,
    virtualLocationId: device.virtualLocationId,
    deviceAddress: device.address,
  };
};

export const hideError = showError => {
  showError(false);
};

export const camelCaseToSnakeCase = input => {
  return input
    .replace(/[\w]([A-Z])/g, function (m) {
      return `${m[0]}_${m[1]}`;
    })
    .toLowerCase();
};

export const getParamFromDeviceData = (device, param) => {
  if (device && device.data) {
    const parseData = JSON.parse(device.data);
    return parseData[param];
  } else {
    return '';
  }
};

export const getGroupId = group => {
  if (isGroupForAllMeters(group)) {
    return '';
  } else if (!isRealResourceGroup(group)) {
    return group.name;
  } else {
    return group.id;
  }
};

export const getNameForResourceGroup = group => {
  if (isGroupForArchived(group)) {
    return 'navigation.manage.resource_group.group_inactive_name';
  } else if (isGroupForUnarchived(group)) {
    return 'navigation.manage.resource_group.group_active_name';
  } else if (isGroupForUngrouped(group)) {
    return 'navigation.manage.resource_group.group_ungrouped_name';
  } else if (isGroupForArchivedAndUngrouped(group)) {
    return 'navigation.manage.resource_group.group_archived_ungrouped_name';
  } else if (isGroupForUnarchivedAndUngrouped(group)) {
    return 'navigation.manage.resource_group.group_unarchived_ungrouped_name';
  } else {
    return group.name;
  }
};

export const isMoveDisabled = activeGroup => {
  return !isRealResourceGroup(activeGroup) || !(activeGroup && activeGroup.id);
};

export const isMoveDisabledNoPointer = activeGroup => {
  if (isMoveDisabled(activeGroup)) {
    return 'no__pointer';
  } else {
    return '';
  }
};

export const getText = mode => {
  switch (mode) {
    case ResourceGroupCrud.edit:
      return 'navigation.manage.resource_group.edit_group';
    case ResourceGroupCrud.add:
      return 'navigation.manage.resource_group.add_new_group';
    default:
      return '';
  }
};

export const handleContractCheckbox = (event, alertCriteries, setAlertCriteries) => {
  const tmpList = alertCriteries.customAlertNames ? [...alertCriteries.customAlertNames] : [];
  if (event.target.checked) {
    tmpList.push(CustomAlertNames.CONTRACT_KEY);
  } else {
    tmpList.splice(tmpList.indexOf(CustomAlertNames.CONTRACT_KEY), 1);
  }
  setAlertCriteries({ ...alertCriteries, customAlertNames: tmpList });
};

export const parseNumberFixed = (value, digits) => {
  return value ? parseFloat(value).toFixed(digits) : '-';
};

export const getColumnCount = (item, containerWidth, id) => {
  // containerWidth is passed to this method only for testing purposes
  if (!containerWidth) {
    try {
      containerWidth = document.getElementById(id).clientWidth;
    } catch (e) {
      containerWidth = 800; // fallback width
    }
  }

  if (!item || !item.alerts) {
    return 1;
  }

  if (item.alerts.length > 40 && containerWidth >= 1600) {
    return 4;
  }

  if (item.alerts.length > 20) {
    if (containerWidth >= 1200) {
      return 3;
    }
    if (containerWidth >= 800) {
      return 2;
    }
  }

  if (item.alerts.length > 10 && containerWidth >= 800) {
    return 2;
  }

  return 1;
};

export const getMeterId = meterId => {
  return meterId ? meterId : '--';
};

export const getAddress = address => {
  return address ? address : '--';
};

export const mapIdentifiers = devices => {
  if (devices && devices.length > 0) {
    return devices.map(device => {
      return device[deviceParams.identifier];
    });
  } else {
    return [];
  }
};

export const findByMetaDataIdentifier = (data, identifier) => {
  return data.find(element => element.metaData && element.metaData.identifier === identifier);
};

export const checkMeterInfoParams = (deviceId, diameter, deviceInfo, deviceAddress) => {
  if (!deviceId) {
    return { hasError: true, errorMsg: 'notification.warn.meterIdFieldRequired' };
  }
  if (!diameter) {
    return { hasError: true, errorMsg: 'notification.warn.meterDiameterFieldRequired' };
  }
  if (deviceInfo && deviceInfo.trim().length > 20) {
    return { hasError: true, errorMsg: 'notification.warn.deviceInfoOver20Symbols' };
  }
  if (deviceAddress && deviceAddress.trim().length > 70) {
    return { hasError: true, errorMsg: 'notification.warn.deviceAddressOver70Symbols' };
  }
  return { hasError: false, errorMsg: '' };
};

export const getShortTime = timeString => {
  return moment(timeString).format(Common.shortTimeFormat);
};
