import { put } from 'redux-saga/effects';

import * as ResourceGroupActions from './ResourceGroupActions';
import * as ManagementActions from '../Management/ManagementActions';
import { handleError, handleSuccess } from '../../Services/Fetch/HttpResponseHandler';
import { API_ENDPOINTS, getData, postData } from '../../Services/Fetch/FetchService';
import { ResourceGroupCrud } from '../../Helpers/Enums';

const getEndpoint = type => {
  switch (type) {
    case ResourceGroupCrud.add:
      return `${API_ENDPOINTS.RESOURCE_GROUP}/create`;
    case ResourceGroupCrud.edit:
      return `${API_ENDPOINTS.RESOURCE_GROUP}/edit`;
    case ResourceGroupCrud.delete:
      return `${API_ENDPOINTS.RESOURCE_GROUP}/delete`;
    default:
      return null;
  }
};

const getEndpointForMetersAction = type => {
  switch (type) {
    case ResourceGroupCrud.move:
      return `${API_ENDPOINTS.RESOURCE_GROUP}/moveToResourceGroup`;
    case ResourceGroupCrud.copy:
      return `${API_ENDPOINTS.RESOURCE_GROUP}/addToResourceGroup`;
    case ResourceGroupCrud.remove:
      return `${API_ENDPOINTS.RESOURCE_GROUP}/removeFromResourceGroup`;
    case ResourceGroupCrud.removeAll:
      return `${API_ENDPOINTS.RESOURCE_GROUP}/removeFromAllResourceGroups`;
    default:
      return null;
  }
};

const getPayloadForMetersAction = payload => {
  switch (payload.action) {
    case ResourceGroupCrud.move:
      return {
        moveFromResourceGroupId: payload.resourceGroupId.id,
        moveToResourceGroupId: payload.selectedResourceGroupId,
        virtualLocationIds: payload.virtualLocationIds,
      };
    case ResourceGroupCrud.copy:
      return {
        resourceGroupId: payload.selectedResourceGroupId,
        virtualLocationIds: payload.virtualLocationIds,
      };
    case ResourceGroupCrud.remove:
      return {
        resourceGroupId: payload.resourceGroupId.id,
        virtualLocationIds: payload.virtualLocationIds,
      };
    case ResourceGroupCrud.removeAll:
      return { virtualLocationIds: payload.virtualLocationIds };
    default:
      return null;
  }
};

export function* resourceGroup(payload) {
  try {
    const response = yield postData(getEndpoint(payload.payload.mode), payload.payload);
    if (response.status === 200) {
      handleSuccess(`notification.success.resourceGroup.${payload.payload.mode}`);
      yield put(ResourceGroupActions.getAllResourceGroupsRequest());
    } else {
      yield put(ResourceGroupActions.resourceGroupFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(
      ResourceGroupActions.resourceGroupFailure({
        responseMessage: handleError(error.response),
      })
    );
  }
}

export function* getAllResourceGroups() {
  try {
    const response = yield getData(`${API_ENDPOINTS.RESOURCE_GROUP}/allWithCount`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(ResourceGroupActions.getAllResourceGroupsSuccess(data));
    } else {
      yield put(
        ResourceGroupActions.getAllResourceGroupsFailure({
          responseMessage: handleError(response),
        })
      );
    }
  } catch (error) {
    yield put(
      ResourceGroupActions.getAllResourceGroupsFailure({
        responseMessage: handleError(error.response),
      })
    );
  }
}

export function* resourceGroupMetersAction(payload) {
  try {
    const response = yield postData(getEndpointForMetersAction(payload.payload.action), getPayloadForMetersAction(payload.payload));
    if (response.status === 200) {
      handleSuccess(`notification.success.resourceGroup.${payload.payload.action}`);
      const data = yield response.data;
      yield put(ResourceGroupActions.resouceGroupMetersActionSuccess(data));
      yield put(ResourceGroupActions.getAllResourceGroupsRequest());
      yield put(ManagementActions.deviceListRequest(payload.payload));
    } else {
      yield put(
        ResourceGroupActions.resouceGroupMetersActionFailure({
          responseMessage: handleError(response),
        })
      );
    }
  } catch (error) {
    yield put(
      ResourceGroupActions.resouceGroupMetersActionFailure({
        responseMessage: handleError(error.response),
      })
    );
  }
}
