import LoginView from '../Views/Login/LoginView';
import DeviceEventLogView from '../Views/DeviceEventLog/DeviceEventLogView';
import AlertsView from '../Views/Alerts/AlertsView';
import ManageView from '../Views/Manage/ManageView';
import ReportsView from '../Views/Reports/ReportsView';
import GraphicsView from '../Views/Graphics/GraphicsView';
import DashboardView from '../Views/Dashboard/DashboardView';
import CompareView from '../Views/Compare/CompareView';

export const ROUTE_PATHS = {
  INDEX: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  ALERTS: '/alerts',
  DEVICE_EVENT_LOG: '/deviceEventLog',
  MANAGE: '/manage',
  REPORT: '/report',
  GRAPHICS: '/graph',
  COMPARE: '/compare',
};

export const routes = [
  { path: ROUTE_PATHS.LOGIN, component: LoginView, exact: true },
  {
    path: ROUTE_PATHS.INDEX,
    component: DashboardView,
    exact: true,
    isProtected: true,
  },
  { path: ROUTE_PATHS.DASHBOARD, component: DashboardView, isProtected: true },
  { path: ROUTE_PATHS.ALERTS, component: AlertsView, isProtected: true },
  { path: ROUTE_PATHS.DEVICE_EVENT_LOG, component: DeviceEventLogView, isProtected: true },
  {
    path: ROUTE_PATHS.MANAGE,
    component: ManageView,
    isProtected: true,
  },
  { path: ROUTE_PATHS.REPORT, component: ReportsView, isProtected: true },
  {
    path: ROUTE_PATHS.GRAPHICS,
    component: GraphicsView,
    isProtected: true,
  },
  { path: ROUTE_PATHS.COMPARE, component: CompareView, isProtected: true },
];
