import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CompareLegendItem from './CompareLegendItem/CompareLegendItem';
import GraphLengthSelector from '../Graphs/GraphLengthSelector/GraphLengthSelector';
import DropDown from '../DropDown/DropDown';
import SearchBox from '../Inputs/SearchBox/SearchBox';
import { getGraphSeries, getGraphOptions, getCompareData } from '../../Helpers/HelperMethods';
import * as CompareActions from '../../Store/Compare/CompareActions';
import { CompareStyles } from './Compare.styles';
import Loader from '../Loader/Loader';

export default function Compare(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { readingsForCompare, devicesForCompare, sensorToCompare, duration, endTime, isLoading } = props;

  const data = getCompareData(readingsForCompare, sensorToCompare);

  const handleSensorChange = sensor => {
    dispatch(CompareActions.setSensorToCompare(sensor));
  };

  const handleDurationChange = value => {
    dispatch(CompareActions.changeCompareDuration(value));
    dispatch(CompareActions.refreshCompareReadings(devicesForCompare));
  };

  const handleEndTimeChange = value => {
    dispatch(CompareActions.changeCompareEndTime(value));
    dispatch(CompareActions.refreshCompareReadings(devicesForCompare));
  };

  const removeSeries = deviceIdentifier => {
    dispatch(CompareActions.removeDeviceToCompare(deviceIdentifier));
  };

  const removeAllSeries = () => {
    for (const x in data.readings) {
      if (data.readings.hasOwnProperty(x)) {
        const reading = data.readings[x];
        dispatch(CompareActions.removeDeviceToCompare(reading.device));
      }
    }
  };

  const toggleSeries = deviceIdentifier => {
    dispatch(CompareActions.toggleDeviceToCompare(deviceIdentifier));
  };

  useEffect(() => {
    data.readings.forEach(d => {
      if (d.toggleShow) {
        ApexCharts.exec(data.id, 'showSeries', d.metaData.name);
      } else {
        ApexCharts.exec(data.id, 'hideSeries', d.metaData.name);
      }
    });
  }, [data]);

  const returnFunction = (sensor, index) => {
    return (
      <option key={index} value={sensor}>
        {sensor}
      </option>
    );
  };

  const handleClick = value => {
    if (value) {
      dispatch(
        CompareActions.getDeviceForCompareRequest({
          page: 0,
          size: 50,
          searchStr: value,
          endTime: endTime,
          duration: duration,
        })
      );
    }
  };

  return (
    <CompareStyles>
      <div className='grid flex'>
        <div className='grid__col--md-3'>
          <SearchBox
            showClearBtn
            showLabel
            onClick={value => handleClick(value)}
            placeholder={t('navigation.manage.meter_list.search.meter_id')}
            emptyAfterSearch
          />
        </div>
        <div className='grid__col--md-6'>
          <GraphLengthSelector
            showLabel
            handleDurationChange={handleDurationChange}
            handleEndTimeChange={handleEndTimeChange}
            duration={duration}
            endTime={endTime}
          />
        </div>
        {readingsForCompare && readingsForCompare.length > 0 && (
          <div className='grid__col--md-2'>
            <DropDown
              value={sensorToCompare}
              handleChange={handleSensorChange}
              data={data.uniqueSensors}
              label={t('navigation.compare.sensor')}
              returnFunction={returnFunction}
            />
          </div>
        )}
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Chart options={getGraphOptions(data.readings, data.id, false)} series={getGraphSeries(data.readings)} height='600' type={'line'} />
      )}

      <hr className='separator separator--small ' />
      {data.readings.map(reading => {
        return (
          <CompareLegendItem
            key={reading.device}
            seriesVisible={reading.toggleShow}
            address={reading.address}
            meterId={reading.meterId}
            deviceIdentifier={reading.device}
            toggle={toggleSeries}
            remove={removeSeries}
          />
        );
      })}
      {data && data.readings && data.readings.length > 0 && (
        <button className='btn' onClick={removeAllSeries}>
          <span>{t('navigation.compare.remove_all_meters_btn')}</span>
        </button>
      )}
    </CompareStyles>
  );
}
