import styled from 'styled-components';

export const BoxesStyles = styled.div`
  .box {
    border: 2px solid var(--light-grey-border);
    border-radius: 0.3em;
    padding: 1em;
  }

  .box--icon {
    margin-top: 1em;
  }

  .box--nr {
    font-size: 2em;
  }

  .box--nr-sm {
    font-size: 1.35em;
  }

  .icon--medium {
    font-size: 2.5em;
  }

  .box--border {
    border: 2px solid var(--light-grey-border);
    border-radius: 0.3em;
  }

  .chart--title {
    position: absolute;
    margin-left: 5px;
    margin-top: 5px;
  }

  .margin-small {
    margin-top: 0.3em;
  }

  .padding-bottom {
    padding-bottom: 3.4em;
  }

  .alert-box-padding {
    padding-top: 2em;
    padding-bottom: 1.1em;
  }

  .margin-medium {
    margin-top: 1.1em;
  }
`;
