import React from 'react';

import ColumnRow from './ColumnRow';
import DataRow from './DataRow';

export default function GroupParameterTable(props) {
  const { alertCriteries, setAlertCriteries } = props;

  return (
    <div className='grid grid--center-lg'>
      <div className='grid__col--lg-12 margin'>
        <table className='tbl tbl--borders tbl--row-highlight tbl--small'>
          <ColumnRow />
          <DataRow alertCriteries={alertCriteries} setAlertCriteries={setAlertCriteries} />
        </table>
      </div>
    </div>
  );
}
