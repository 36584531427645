import React from 'react';
import PropTypes from 'prop-types';

export default function Header(props) {
  const { headerText, handleClose } = props;
  return (
    <div className='modal__header'>
      <h4 className='modal__title'>{headerText}</h4>
      <button className='btn btn--sm btn--icon btn--ghost' onClick={handleClose} data-testid='modalCloseBtn'>
        <svg className='icon  btn__icon'>
          <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#x`} />
        </svg>
      </button>
    </div>
  );
}

Header.propTypes = {
  headerText: PropTypes.string,
  handleClose: PropTypes.func,
};
