import React from 'react';
import PropTypes from 'prop-types';

import NumberWithLabel from '../../Items/NumberWithLabel';

export default function MetersBoxRow(props) {
  const { signalCount, signalLabel, powerCount, powerLabel, lengthLabel, timeLabel } = props;

  return (
    <div className='grid grid--middle-sm margin-medium'>
      <div className='grid__col--sm-4 grid--middle-sm'>
        <div>
          <span className='text-bold'>{lengthLabel}</span>
        </div>
        <div>
          <span className='text-grey h6'>{timeLabel}</span>
        </div>
      </div>
      <div className='grid__col--sm-4'>
        <NumberWithLabel count={signalCount} label={signalLabel} />
      </div>
      <div className='grid__col--sm-4'>
        <NumberWithLabel count={powerCount} label={powerLabel} />
      </div>
    </div>
  );
}

MetersBoxRow.propTypes = {
  signalCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  signalLabel: PropTypes.string,
  powerCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  powerLabel: PropTypes.string,
  lengthLabel: PropTypes.string,
  timeLabel: PropTypes.string,
};
