import React from 'react';

import DeviceEventLogTable from '../../Components/Table/DeviceEventLogTable/DeviceEventLogTable';

export default function DeviceEventLogView() {
  return (
    <div className='grid grid--center-lg'>
      <div className='grid__col--lg-11 margin'>
        <DeviceEventLogTable />
      </div>
    </div>
  );
}
