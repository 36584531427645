import { put } from 'redux-saga/effects';

import * as ManagementActions from './ManagementActions';
import { handleError, handleSuccess } from '../../Services/Fetch/HttpResponseHandler';
import { API_ENDPOINTS, getData, postData } from '../../Services/Fetch/FetchService';
import { camelCaseToSnakeCase } from '../../Helpers/HelperMethods';

export function* readDeviceList(payload) {
  const page = payload.payload.page;
  const size = payload.payload.size;
  const sortableRow = payload.payload.sortableRow;
  const sortDirection = payload.payload.sortDirection;
  const searchStr = payload.payload.searchStr ? `&searchStr=${payload.payload.searchStr}` : '';
  const groupId = payload.payload.groupId ? `&groupId=${payload.payload.groupId}` : '';
  try {
    const response = yield getData(
      `${API_ENDPOINTS.DEVICE_LIST}/?page=${page - 1}&size=${size}&sort=${sortableRow},${sortDirection}${searchStr}${groupId}`
    );
    if (response.status === 200) {
      const data = yield response.data;
      yield put(ManagementActions.deviceListSuccess(data));
    } else {
      yield put(ManagementActions.deviceListFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(ManagementActions.deviceListFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* readDeviceInfo(payload) {
  const deviceId = payload.payload.deviceId;
  try {
    const response = yield getData(`${API_ENDPOINTS.DEVICE_INFO}/${deviceId}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(ManagementActions.deviceInfoSuccess(data));
    } else {
      yield put(ManagementActions.deviceInfoFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(ManagementActions.deviceInfoFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* setDeviceIdDiameter(payload) {
  try {
    const response = yield postData(`${API_ENDPOINTS.DEVICE_INFO}/edit`, payload.payload);
    if (response.status === 200) {
      handleSuccess('notification.success.deviceEdit');
      yield put(ManagementActions.setDeviceIdDiameterSuccess());
      yield put(
        ManagementActions.deviceListRequest({
          page: payload.payload.managementSortConfig.currentPage,
          size: payload.payload.managementSortConfig.perPage,
          sortableRow: camelCaseToSnakeCase(payload.payload.managementSortConfig.key),
          sortDirection: payload.payload.managementSortConfig.direction,
          searchStr: payload.payload.managementSortConfig.searchStr,
        })
      );
    } else {
      yield put(
        ManagementActions.setDeviceIdDiameterFailure({
          responseMessage: handleError(response),
        })
      );
    }
  } catch (error) {
    yield put(
      ManagementActions.setDeviceIdDiameterFailure({
        responseMessage: handleError(error.response),
      })
    );
  }
}
