import React from 'react';
import { BoxesStyles } from './Boxes.styles';
import { useTranslation } from 'react-i18next';
import MetersBoxRow from './MetersBoxRow';
import { useSelector } from 'react-redux';
import { Hours } from '../../../../Helpers/Enums';
import { getShortTime } from '../../../../Helpers/HelperMethods';

export default function MetersBox() {
  const { t } = useTranslation();
  const { noReadingsCount } = useSelector(state => state.dashboard);

  const getTotalDevices = () => {
    if (noReadingsCount[Hours['1']].result && noReadingsCount[Hours['1']].result.totalDevicesCount) {
      return noReadingsCount[Hours['1']].result.totalDevicesCount;
    } else if (noReadingsCount[Hours['24']].result && noReadingsCount[Hours['24']].result.totalDevicesCount) {
      return noReadingsCount[Hours['24']].result.totalDevicesCount;
    } else {
      return '-';
    }
  };

  const get1HTimeLabel = () => {
    if (noReadingsCount[Hours['1']].time && noReadingsCount[Hours['1']].time.start && noReadingsCount[Hours['1']].time.end) {
      return `(${getShortTime(noReadingsCount[Hours['1']].time.start)} - ${getShortTime(noReadingsCount[Hours['1']].time.end)})`;
    }
    return '-';
  };

  const get24HTimeLabel = () => {
    if (noReadingsCount[Hours['24']].time && noReadingsCount[Hours['24']].time.end) {
      return `(${t('navigation.dashboard.alerts.until')} ${getShortTime(noReadingsCount[Hours['24']].time.end)})`;
    }
    return '-';
  };

  return (
    <BoxesStyles>
      <div className='grid box grid--no-gutter grid--middle-sm'>
        <div className='grid__col--sm-11'>
          <MetersBoxRow
            lengthLabel={t('navigation.dashboard.alerts.last_hour')}
            powerCount={noReadingsCount[Hours['1']].result && noReadingsCount[Hours['1']].result.powerZeroCount}
            powerLabel={t('navigation.dashboard.alerts.no_power')}
            signalCount={noReadingsCount[Hours['1']].result && noReadingsCount[Hours['1']].result.noReadingsMetersCount}
            signalLabel={t('navigation.dashboard.alerts.no_signal')}
            timeLabel={get1HTimeLabel()}
          />
          <MetersBoxRow
            lengthLabel={t('navigation.dashboard.alerts.24h')}
            powerCount={noReadingsCount[Hours['24']].result && noReadingsCount[Hours['24']].result.powerZeroCount}
            powerLabel={t('navigation.dashboard.alerts.no_power')}
            signalCount={noReadingsCount[Hours['24']].result && noReadingsCount[Hours['24']].result.noReadingsMetersCount}
            signalLabel={t('navigation.dashboard.alerts.no_signal')}
            timeLabel={get24HTimeLabel()}
          />
        </div>
        <div className='grid__col--sm-1 grid--middle-sm margin-medium'>
          <svg className='icon icon--large'>
            <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/iconsELV.svg#meters-lg`} />
          </svg>
        </div>
        <div className='grid__col--sm-12 text-bold text-lead margin-medium '>{`${t(
          'navigation.dashboard.alerts.total'
        )}: ${getTotalDevices()}`}</div>
      </div>
    </BoxesStyles>
  );
}
