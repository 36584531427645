import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GraphInfoStyles } from './GraphInfo.styles';
import { useSelector } from 'react-redux';

const GraphInfo = () => {
  const { t } = useTranslation();
  const { deviceInfo, hasDeviceInfo } = useSelector(state => state.management);
  const [parseData, setParseData] = useState({});

  const graphClasses = {
    infoContainerRow: 'info-container-row',
  };

  useEffect(() => {
    if (hasDeviceInfo) {
      setParseData(deviceInfo.data ? JSON.parse(deviceInfo.data) : {});
    }
  }, [hasDeviceInfo, deviceInfo.data]);

  return (
    <GraphInfoStyles>
      <div className={'info-container'}>
        {parseData.deviceInfo && (
          <span className={graphClasses.infoContainerRow}>{`${t('navigation.graph.device_info')}: ${parseData.deviceInfo}`}</span>
        )}

        {parseData.type && <span className={graphClasses.infoContainerRow}>{`${t('navigation.graph.type')}: ${parseData.type}`}</span>}

        {parseData.diameter && (
          <span className={graphClasses.infoContainerRow}>{`${t('navigation.graph.dn')}: ${parseData.diameter}`}</span>
        )}

        {parseData.address && (
          <span className={graphClasses.infoContainerRow}>{`${t('navigation.graph.address')}: ${parseData.address}`}</span>
        )}

        {parseData.meterId && (
          <span className={graphClasses.infoContainerRow}>{`${t('navigation.graph.meter_id')}: ${parseData.meterId}`}</span>
        )}

        {deviceInfo.identifier && (
          <span className={graphClasses.infoContainerRow}>{`${t('navigation.graph.device_id')}: ${deviceInfo.identifier}`}</span>
        )}
      </div>
    </GraphInfoStyles>
  );
};

export default GraphInfo;
