import styled from 'styled-components';
import 'animate.css/animate.min.css';

export const NotFoundViewStyles = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 100%;

  .notfound {
    height: 100%;
    background-color: var(--foreground-color);
  }
`;
