import { put } from 'redux-saga/effects';

import { handleError } from '../../Services/Fetch/HttpResponseHandler';
import { API_ENDPOINTS, getData } from '../../Services/Fetch/FetchService';
import * as DashboardActions from './DashboardActions';

export function* getOutsideTemperatureReading(payload) {
  const identifier = payload.payload.identifier;
  const duration = payload.payload.duration;
  const endTime = encodeURIComponent(payload.payload.endTime);
  try {
    const response = yield getData(`${API_ENDPOINTS.READING}?identifier=${identifier}&duration=${duration}&endTime=${endTime}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(DashboardActions.getOutsideTempSuccess(data));
    } else {
      yield put(DashboardActions.getOutsideTempFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(DashboardActions.getOutsideTempFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* getStationTemperatureReading(payload) {
  const source = `?meterSource=${payload.payload.meterSource}`;
  const t1 = `&t1SensorIdentifier=${payload.payload.t1SensorIdentifier}`;
  const t2 = `&t2SensorIdentifier=${payload.payload.t2SensorIdentifier}`;
  const flow = `&flowSensorIdentifier=${payload.payload.flowSensorIdentifier}`;
  const identifierList = `&plantDeviceIdentifierList=${payload.payload.plantDeviceIdentifierList}`;
  try {
    const response = yield getData(`${API_ENDPOINTS.DASHBOARD}/circulationTemperatures${source}${t1}${t2}${flow}${identifierList}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(DashboardActions.getStationTempSuccess(data));
    } else {
      yield put(DashboardActions.getStationTempFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(DashboardActions.getStationTempFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* getNoReadingsCount(payload) {
  const source = `?meterSource=${payload.payload.meterSource}`;
  const power = `&powerSensorIdentifier=${payload.payload.powerSensorIdentifier}`;
  const timeSpanInHours = `&timeSpanInHours=${payload.payload.timeSpanInHours}`;
  try {
    const response = yield getData(`${API_ENDPOINTS.DASHBOARD}/metersInfo${source}${power}${timeSpanInHours}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(
        DashboardActions.getNoReadingsCountSuccess({
          data,
          duration: payload.payload.timeSpanInHours,
        })
      );
    } else {
      yield put(DashboardActions.getNoReadingsCountFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(DashboardActions.getNoReadingsCountFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* getAlertsCount() {
  try {
    const response = yield getData(`${API_ENDPOINTS.DASHBOARD}/alerts`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(DashboardActions.getAlertsCountSuccess(data));
    } else {
      yield put(DashboardActions.getAlertsCountFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(DashboardActions.getAlertsCountFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* getPowerReadings(payload) {
  const source = `?meterSource=${payload.payload.meterSource}`;
  const power = `&powerSensorIdentifier=${payload.payload.powerSensorIdentifier}`;
  const device = `&powerDeviceIdentifier=${payload.payload.powerDeviceIdentifier}`;
  const powerHeat = `&heatPowerSensorIdentifier=${payload.payload.heatPowerSensorIdentifier}`;
  const endUserMeter = `&endUserMeter=true`;
  try {
    const response = yield getData(`${API_ENDPOINTS.DASHBOARD}/power${source}${power}${device}${powerHeat}${endUserMeter}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(DashboardActions.getPowerReadingsSuccess(data));
    } else {
      yield put(DashboardActions.getPowerReadingsFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(DashboardActions.getPowerReadingsFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* getFlowReadings(payload) {
  const devices = `?plantDeviceIdentifierList=${payload.payload.plantDeviceIdentifierList}`;
  const flowInSensor = `&flowInSensorIdentifier=${payload.payload.flowInSensorIdentifier}`;
  const flowOutSensor = `&flowOutSensorIdentifier=${payload.payload.flowOutSensorIdentifier}`;
  try {
    const response = yield getData(`${API_ENDPOINTS.DASHBOARD}/flow${devices}${flowInSensor}${flowOutSensor}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(DashboardActions.getFlowReadingsSuccess(data));
    } else {
      yield put(DashboardActions.getFlowReadingsFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(DashboardActions.getFlowReadingsFailure({ responseMessage: handleError(error.response) }));
  }
}

export function* getPlantPowerReadings(payload) {
  const identifier = payload.payload.identifier;
  const duration = payload.payload.duration;
  const endTime = encodeURIComponent(payload.payload.endTime);
  try {
    const response = yield getData(`${API_ENDPOINTS.READING}?identifier=${identifier}&duration=${duration}&endTime=${endTime}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(DashboardActions.getPlantPowerReadingsSuccess(data));
    } else {
      yield put(DashboardActions.getPlantPowerReadingsFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(
      DashboardActions.getPlantPowerReadingsFailure({
        responseMessage: handleError(error.response),
      })
    );
  }
}

export function* getPlantFlowOutReadings(payload) {
  const identifier = payload.payload.identifier;
  const duration = payload.payload.duration;
  const endTime = encodeURIComponent(payload.payload.endTime);
  try {
    const response = yield getData(`${API_ENDPOINTS.READING}?identifier=${identifier}&duration=${duration}&endTime=${endTime}`);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(DashboardActions.getPlantFlowOutReadingsSuccess(data));
    } else {
      yield put(DashboardActions.getPlantFlowOutReadingsFailure({ responseMessage: handleError(response) }));
    }
  } catch (error) {
    yield put(
      DashboardActions.getPlantFlowOutReadingsFailure({
        responseMessage: handleError(error.response),
      })
    );
  }
}
