import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AlertCountBox from './Boxes/AlertCountBox';
import MetersBox from './Boxes/MetersBox';
import TemperatureBox from './Boxes/TemperatureBox';
import { AlertsRowStyles } from './AlertsRow.styles';
import { AlertStatusEnumMap } from '../../../Helpers/Enums';

export default function AlertsRow() {
  const { t } = useTranslation();

  const { alertsCount, hasAlertsCount } = useSelector(state => state.dashboard);

  const getAlertsCountParam = param => {
    if (!hasAlertsCount) {
      return '-';
    }
    const tmpAlert = alertsCount.find(({ status }) => status === param);
    if (tmpAlert) {
      return tmpAlert.count;
    } else {
      return '-';
    }
  };

  return (
    <AlertsRowStyles>
      <div className='grid grid--start-lg '>
        <div className='grid__col--sm-12 grid__col--lg-6'>
          <div className='grid'>
            <div className='grid__col--sm-7'>
              <h3>{t('navigation.dashboard.alerts.meters_title')}</h3>
              <MetersBox />
            </div>
            <div className='grid__col--sm-5'>
              <h3>{t('navigation.dashboard.alerts.alerts_title')}</h3>
              <AlertCountBox
                firstCount={getAlertsCountParam(AlertStatusEnumMap['0'])}
                firstLabel={t('navigation.dashboard.alerts.alerts_new')}
                secondCount={getAlertsCountParam(AlertStatusEnumMap['1'])}
                secondLabel={t('navigation.dashboard.alerts.alerts_postponed')}
              />
            </div>
          </div>
        </div>
        <div className='grid__col--sm-12 grid__col--lg-6'>
          <div className='grid grid--end-lg'>
            <div className='grid__col--lg-12'>
              <TemperatureBox />
            </div>
          </div>
        </div>
      </div>
    </AlertsRowStyles>
  );
}
