import { createAction } from '@reduxjs/toolkit';

export const GET_OUTSIDE_TEMP_REQUEST = createAction('GET_OUTSIDE_TEMP_REQUEST');
export const getOutsideTempRequest = payload => GET_OUTSIDE_TEMP_REQUEST(payload);

export const GET_OUTSIDE_TEMP_SUCCESS = createAction('GET_OUTSIDE_TEMP_SUCCESS');
export const getOutsideTempSuccess = payload => GET_OUTSIDE_TEMP_SUCCESS(payload);

export const GET_OUTSIDE_TEMP_FAILURE = createAction('GET_OUTSIDE_TEMP_FAILURE');
export const getOutsideTempFailure = payload => GET_OUTSIDE_TEMP_FAILURE(payload);

export const GET_STATION_TEMP_REQUEST = createAction('GET_STATION_TEMP_REQUEST');
export const getStationTempRequest = payload => GET_STATION_TEMP_REQUEST(payload);

export const GET_STATION_TEMP_SUCCESS = createAction('GET_STATION_TEMP_SUCCESS');
export const getStationTempSuccess = payload => GET_STATION_TEMP_SUCCESS(payload);

export const GET_STATION_TEMP_FAILURE = createAction('GET_STATION_TEMP_FAILURE');
export const getStationTempFailure = payload => GET_STATION_TEMP_FAILURE(payload);

export const GET_NO_READINGS_COUNT_REQUEST = createAction('GET_NO_READINGS_COUNT_REQUEST');
export const getNoReadingsCountRequest = payload => GET_NO_READINGS_COUNT_REQUEST(payload);

export const GET_NO_READINGS_COUNT_SUCCESS = createAction('GET_NO_READINGS_COUNT_SUCCESS');
export const getNoReadingsCountSuccess = payload => GET_NO_READINGS_COUNT_SUCCESS(payload);

export const GET_NO_READINGS_COUNT_FAILURE = createAction('GET_NO_READINGS_COUNT_FAILURE');
export const getNoReadingsCountFailure = payload => GET_NO_READINGS_COUNT_FAILURE(payload);

export const GET_ALERTS_COUNT_REQUEST = createAction('GET_ALERTS_COUNT_REQUEST');
export const getAlertsCountRequest = payload => GET_ALERTS_COUNT_REQUEST(payload);

export const GET_ALERTS_COUNT_SUCCESS = createAction('GET_ALERTS_COUNT_SUCCESS');
export const getAlertsCountSuccess = payload => GET_ALERTS_COUNT_SUCCESS(payload);

export const GET_ALERTS_COUNT_FAILURE = createAction('GET_ALERTS_COUNT_FAILURE');
export const getAlertsCountFailure = payload => GET_ALERTS_COUNT_FAILURE(payload);

export const GET_POWER_READINGS_REQUEST = createAction('GET_POWER_READINGS_REQUEST');
export const getPowerReadingsRequest = payload => GET_POWER_READINGS_REQUEST(payload);

export const GET_POWER_READINGS_SUCCESS = createAction('GET_POWER_READINGS_SUCCESS');
export const getPowerReadingsSuccess = payload => GET_POWER_READINGS_SUCCESS(payload);

export const GET_POWER_READINGS_FAILURE = createAction('GET_POWER_READINGS_FAILURE');
export const getPowerReadingsFailure = payload => GET_POWER_READINGS_FAILURE(payload);

export const GET_FLOW_READINGS_REQUEST = createAction('GET_FLOW_READINGS_REQUEST');
export const getFlowReadingsRequest = payload => GET_FLOW_READINGS_REQUEST(payload);

export const GET_FLOW_READINGS_SUCCESS = createAction('GET_FLOW_READINGS_SUCCESS');
export const getFlowReadingsSuccess = payload => GET_FLOW_READINGS_SUCCESS(payload);

export const GET_FLOW_READINGS_FAILURE = createAction('GET_FLOW_READINGS_FAILURE');
export const getFlowReadingsFailure = payload => GET_FLOW_READINGS_FAILURE(payload);

export const GET_PLANT_POWER_READINGS_REQUEST = createAction('GET_PLANT_POWER_READINGS_REQUEST');
export const getPlantPowerReadingsRequest = payload => GET_PLANT_POWER_READINGS_REQUEST(payload);

export const GET_PLANT_POWER_READINGS_SUCCESS = createAction('GET_PLANT_POWER_READINGS_SUCCESS');
export const getPlantPowerReadingsSuccess = payload => GET_PLANT_POWER_READINGS_SUCCESS(payload);

export const GET_PLANT_POWER_READINGS_FAILURE = createAction('GET_PLANT_POWER_READINGS_FAILURE');
export const getPlantPowerReadingsFailure = payload => GET_PLANT_POWER_READINGS_FAILURE(payload);

export const GET_PLANT_FLOW_OUT_READINGS_REQUEST = createAction('GET_PLANT_FLOW_OUT_READINGS_REQUEST');
export const getPlantFlowOutReadingsRequest = payload => GET_PLANT_FLOW_OUT_READINGS_REQUEST(payload);

export const GET_PLANT_FLOW_OUT_READINGS_SUCCESS = createAction('GET_PLANT_FLOW_OUT_READINGS_SUCCESS');
export const getPlantFlowOutReadingsSuccess = payload => GET_PLANT_FLOW_OUT_READINGS_SUCCESS(payload);

export const GET_PLANT_FLOW_OUT_READINGS_FAILURE = createAction('GET_PLANT_FLOW_OUT_READINGS_FAILURE');
export const getPlantFlowOutReadingsFailure = payload => GET_PLANT_FLOW_OUT_READINGS_FAILURE(payload);
