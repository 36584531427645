import styled from 'styled-components';

export const GraphInfoStyles = styled.div`
  .info-container {
    padding: 1em 8em 1em 1em;
  }

  .info-container-row {
    display: block;
  }
`;
