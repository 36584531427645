import styled from 'styled-components';
import 'animate.css/animate.min.css';

export const DataRowStyles = styled.div`
  .inactive {
    border: 1px solid var(--inactive-border);
    background-color: var(--inactive-bg);
    padding: 0.2em;
  }
`;
