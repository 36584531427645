import { createAction } from '@reduxjs/toolkit';

export const READING_REQUEST = createAction('READING_REQUEST');
export const readingRequest = payload => READING_REQUEST(payload);

export const READING_SUCCESS = createAction('READING_SUCCESS');
export const readingSuccess = payload => READING_SUCCESS(payload);

export const READING_FAILURE = createAction('READING_FAILURE');
export const readingFailure = payload => READING_FAILURE(payload);
