import { createReducer } from '@reduxjs/toolkit';

import * as CompareActions from './CompareActions';
import moment from 'moment';
import { DurationEnum } from '../../Helpers/Enums';

const initialState = {
  isLoading: false,
  devicesForCompare: [],
  readingsForCompare: [],
  sensorToCompare: 'EFFEKT1 (POWER) (kW)',
  errorMessage: '',
  endTime: moment(moment.now()).format(),
  duration: DurationEnum.ONE_DAY,
};

const defaultResponse = state => {
  return {
    ...state,
    isLoading: true,
    errorMessage: initialState.errorMessage,
  };
};

const defaultFailureResponse = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: false,
    errorMessage: payload,
  };
};

const removeDeviceAndReadings = (readings, devices, deviceIdentifier) => {
  const devicesParsed = JSON.parse(devices);
  const readingsParsed = JSON.parse(readings);

  // remove device
  for (const device in devicesParsed) {
    if (devicesParsed.hasOwnProperty(device)) {
      const dId = devicesParsed[device].identifier;
      if (deviceIdentifier === dId) {
        devicesParsed.splice(device, 1);
      }
    }
  }

  // remove readings
  for (const reading in readingsParsed) {
    if (readingsParsed.hasOwnProperty(reading)) {
      const dId = readingsParsed[reading].device.identifier;
      if (deviceIdentifier === dId) {
        readingsParsed.splice(reading, 1);
      }
    }
  }

  return {
    devicesForCompare: devicesParsed,
    readingsForCompare: readingsParsed,
  };
};

const toggleDevice = (readings, deviceIdentifier) => {
  const readingsParsed = JSON.parse(readings);
  for (const reading in readingsParsed) {
    if (readingsParsed.hasOwnProperty(reading)) {
      const dId = readingsParsed[reading].device.identifier;
      if (deviceIdentifier === dId) {
        readingsParsed[reading].toggleShow = !readingsParsed[reading].toggleShow;
      }
    }
  }
  return readingsParsed;
};

export const CompareReducer = createReducer(initialState, {
  [CompareActions.GET_DEVICE_FOR_COMPARE_REQUEST]: defaultResponse,
  [CompareActions.GET_DEVICE_FOR_COMPARE_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      devicesForCompare: [...state.devicesForCompare, payload],
      errorMessage: initialState.errorMessage,
    };
  },
  [CompareActions.GET_DEVICE_FOR_COMPARE_FAILURE]: defaultFailureResponse,

  [CompareActions.GET_READINGS_FOR_COMPARE_REQUEST]: defaultResponse,
  [CompareActions.GET_READINGS_FOR_COMPARE_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isLoading: false,
      readingsForCompare: [...state.readingsForCompare, { readings: payload.readings, device: payload.device, toggleShow: true }],
      errorMessage: initialState.errorMessage,
    };
  },
  [CompareActions.GET_READINGS_FOR_COMPARE_FAILURE]: defaultFailureResponse,
  [CompareActions.SET_SENSOR_TO_COMPARE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      sensorToCompare: payload,
    };
  },
  [CompareActions.REMOVE_DEVICE_TO_COMPARE]: (state, action) => {
    const { payload } = action;
    const data = removeDeviceAndReadings(JSON.stringify(state.readingsForCompare), JSON.stringify(state.devicesForCompare), payload);
    return {
      ...state,
      readingsForCompare: data.readingsForCompare,
      devicesForCompare: data.devicesForCompare,
    };
  },
  [CompareActions.TOGGLE_DEVICE_TO_COMPARE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      readingsForCompare: toggleDevice(JSON.stringify(state.readingsForCompare), payload),
    };
  },
  [CompareActions.CHANGE_COMPARE_DURATION]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      duration: payload,
    };
  },
  [CompareActions.CHANGE_COMPARE_END_TIME]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      endTime: payload,
    };
  },
  [CompareActions.CLEAR_READINGS_TO_COMPARE]: state => {
    return {
      ...state,
      readingsForCompare: [],
    };
  },
  [CompareActions.CLEAR_DEVICES_TO_COMPARE]: state => {
    return {
      ...state,
      devicesForCompare: [],
    };
  },
});

export const getEndTime = state => {
  return state.compare.endTime;
};

export const getDuration = state => {
  return state.compare.duration;
};

export const getExistingDevices = state => {
  return state.compare.devicesForCompare;
};
