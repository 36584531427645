import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

import { ROUTE_PATHS } from '../../../../Routes/Routes';

export default function ReportingDataRow(props) {
  const { t } = useTranslation();
  const { device, sensors } = props;

  const history = useHistory();

  const goToGraphPage = (virtualLocationId, deviceId) => {
    history.push(`${ROUTE_PATHS.GRAPHICS}?vlId=${virtualLocationId}&deviceId=${deviceId}`);
  };

  return (
    <React.Fragment>
      <tr>
        <td>{device.readingTime ? device.readingTime : '--'}</td>
        <td>{device.meterIdentifier ? device.meterIdentifier : '--'}</td>
        <td>{device.address ? device.address : '--'}</td>
        {sensors.map(sensor => {
          return <td key={`${device.deviceIdentifier}_${sensor.sensorId}`}>{device[sensor.sensorId] ? device[sensor.sensorId] : '--'}</td>;
        })}
        <td className='cell--icon'>
          <Tooltip title={<span className='h6'>{t('navigation.manage.tooltip.graphic')}</span>}>
            <button
              className='btn btn--icon'
              onClick={() => goToGraphPage(device.virtualLocationId, device.deviceId)}
              data-testid={'btnGoToGraph'}
            >
              <svg className='icon  btn__icon'>
                <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/iconsELV.svg#data-lg`} />
              </svg>
            </button>
          </Tooltip>
        </td>
      </tr>
    </React.Fragment>
  );
}
