import React from 'react';
import { useTranslation } from 'react-i18next';

import useAuthentication from '../../Hooks/useAuthentication';
import { LoginViewStyles } from './LoginView.styles';

export default function LoginView() {
  const { forceLogin } = useAuthentication();
  const { t } = useTranslation();

  return (
    <LoginViewStyles>
      <div className='login-container'>
        <h1 className={'login-title'}>{t('app.name')}</h1>
        <button className='btn' onClick={forceLogin}>
          <span className='btn__text'>{t('action.login')}</span>
        </button>
      </div>
    </LoginViewStyles>
  );
}
