import { createReducer } from '@reduxjs/toolkit';

import * as DeviceEventLogActions from './DeviceEventLogActions';
import { DeviceEventLog, CommonSorting } from '../../Helpers/Enums';
import { createPageableObject } from '../../Helpers/HelperMethods';

const initialState = {
  deviceEventLogLoading: false,
  deviceEventLog: [],
  pageableInfo: {},
  errorMessage: '',
  deviceEventLogSortConfig: {
    key: DeviceEventLog.createdDate,
    direction: CommonSorting.descending,
    currentPage: 1,
    perPage: 25,
    searchStr: '',
  },
};

export const DeviceEventLogReducer = createReducer(initialState, {
  [DeviceEventLogActions.GET_DEVICE_EVENT_LOG_REQUEST]: state => {
    return {
      ...state,
      deviceEventLogLoading: true,
      deviceEventLog: initialState.deviceEventLog,
      pageableInfo: initialState.pageableInfo,
      errorMessage: initialState.errorMessage,
    };
  },
  [DeviceEventLogActions.GET_DEVICE_EVENT_LOG_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      deviceEventLogLoading: false,
      deviceEventLog: payload.content,
      pageableInfo: createPageableObject(payload),
      errorMessage: initialState.errorMessage,
    };
  },
  [DeviceEventLogActions.GET_DEVICE_EVENT_LOG_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      deviceEventLogLoading: false,
      deviceEventLog: initialState.deviceEventLog,
      pageableInfo: initialState.pageableInfo,
      errorMessage: payload,
    };
  },
  [DeviceEventLogActions.ADD_DEVICE_EVENT_LOG_REQUEST]: state => {
    return {
      ...state,
      deviceEventLogLoading: true,
      errorMessage: initialState.errorMessage,
    };
  },
  [DeviceEventLogActions.ADD_DEVICE_EVENT_LOG_SUCCESS]: state => {
    return {
      ...state,
      deviceEventLogLoading: false,
      errorMessage: initialState.errorMessage,
    };
  },
  [DeviceEventLogActions.ADD_DEVICE_EVENT_LOG_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      deviceEventLogLoading: false,
      errorMessage: payload,
    };
  },
  [DeviceEventLogActions.SET_EVENT_LOG_SORT_CONFIG]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      deviceEventLogSortConfig: payload,
    };
  },
});
