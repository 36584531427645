import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import AlertsRow from '../../Components/Dashboard/AlertsRow/AlertsRow';
import FlowRow from '../../Components/Dashboard/FlowRow/FlowRow';
import StationDataRow from '../../Components/Dashboard/StationDataRow/StationDataRow';
import * as DashboardActions from '../../Store/Dashboard/DashboardActions';
import { DeviceSource, DurationEnum, Identifiers, Sensors, StationTempParams } from '../../Helpers/Enums';
import { DashboardViewStyles } from './DashboardView.styles';

export default function DashboardView() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      DashboardActions.getStationTempRequest({
        meterSource: DeviceSource.nas,
        t1SensorIdentifier: Sensors.t1Sensor,
        t2SensorIdentifier: Sensors.t2Sensor,
        flowSensorIdentifier: Sensors.flowSensor,
        plantDeviceIdentifierList: [StationTempParams.M1, StationTempParams.M2],
      })
    );
    dispatch(DashboardActions.getAlertsCountRequest());
    dispatch(
      DashboardActions.getOutsideTempRequest({
        identifier: Identifiers.OUTSIDE_TEMPERATURE,
        duration: DurationEnum.ONE_DAY,
        endTime: moment(moment.now()).format(),
      })
    );
    dispatch(
      DashboardActions.getNoReadingsCountRequest({
        meterSource: DeviceSource.nas,
        powerSensorIdentifier: Sensors.powerSensor,
        timeSpanInHours: 1,
      })
    );
    dispatch(
      DashboardActions.getNoReadingsCountRequest({
        meterSource: DeviceSource.nas,
        powerSensorIdentifier: Sensors.powerSensor,
        timeSpanInHours: 24,
      })
    );
    dispatch(
      DashboardActions.getPowerReadingsRequest({
        meterSource: DeviceSource.nas,
        powerSensorIdentifier: Sensors.powerSensor,
        powerDeviceIdentifier: Identifiers.PLANT_OUTPUT_POWER,
        heatPowerSensorIdentifier: Sensors.powerHeat,
      })
    );
    dispatch(
      DashboardActions.getFlowReadingsRequest({
        plantDeviceIdentifierList: [StationTempParams.M1, StationTempParams.M2],
        flowInSensorIdentifier: Sensors.flowIn,
        flowOutSensorIdentifier: Sensors.flowOut,
      })
    );
    dispatch(
      DashboardActions.getPlantPowerReadingsRequest({
        identifier: Identifiers.PLANT_OUTPUT_POWER,
        duration: DurationEnum.ONE_DAY,
        endTime: moment(moment.now()).format(),
      })
    );
    dispatch(
      DashboardActions.getPlantFlowOutReadingsRequest({
        identifier: Identifiers.PLANT_TOTAL_FLOW,
        duration: DurationEnum.ONE_DAY,
        endTime: moment(moment.now()).format(),
      })
    );
  }, [dispatch]);

  return (
    <DashboardViewStyles>
      <div className='grid grid--center-lg'>
        <div className='grid__col--lg-11'>
          <AlertsRow />
          <hr className='separator separator--dark separator--small' />
          <FlowRow />
          <hr className='separator separator--dark separator--small' />
          <StationDataRow />
        </div>
      </div>
    </DashboardViewStyles>
  );
}
