import { all, takeEvery } from 'redux-saga/effects';

import * as ManagementActions from './Management/ManagementActions';
import * as ReadingActions from './Reading/ReadingActions';
import * as ResourceGroupActions from './ResourceGroup/ResourceGroupActions';
import * as AlertActions from './Alert/AlertActions';
import * as CompareActions from './Compare/CompareActions';
import * as CommonActions from './Common/CommonActions';
import * as SensorActions from './Sensor/SensorActions';
import * as ReportActions from './Report/ReportActions';
import * as DeviceEventLogActions from './DeviceEventLog/DeviceEventLogActions';
import * as DashboardActions from './Dashboard/DashboardActions';
import * as ArchiveDeviceActions from './ArchiveDevice/ArchiveDeviceActions';

import * as ManagementSagas from './Management/ManagementSagas';
import * as ReadingSagas from './Reading/ReadingSagas';
import * as ResourceGroupSagas from './ResourceGroup/ResourceGroupSagas';
import * as AlertSagas from './Alert/AlertSagas';
import * as CompareSagas from './Compare/CompareSagas';
import * as CommonSagas from './Common/CommonSagas';
import * as SensorSagas from './Sensor/SensorSagas';
import * as ReportSagas from './Report/ReportSagas';
import * as DeviceEventLogSagas from './DeviceEventLog/DeviceEventLogSagas';
import * as DashboardSagas from './Dashboard/DashboardSagas';
import * as ArchiveDeviceSagas from './ArchiveDevice/ArchiveDeviceSagas';

export const DeviceList = [
  takeEvery(ManagementActions.DEVICE_LIST_REQUEST, ManagementSagas.readDeviceList),
  takeEvery(ManagementActions.DEVICE_REQUEST, ManagementSagas.readDeviceInfo),
  takeEvery(ManagementActions.SET_DEVICEID_DIAMETER_REQUEST, ManagementSagas.setDeviceIdDiameter),
];

export const ResourceGroup = [
  takeEvery(ResourceGroupActions.RESOURCE_GROUP_REQUEST, ResourceGroupSagas.resourceGroup),
  takeEvery(ResourceGroupActions.GET_ALL_RESOURCE_GROUPS_REQUEST, ResourceGroupSagas.getAllResourceGroups),
  takeEvery(ResourceGroupActions.RESOURCE_GROUP_METERS_ACTION_REQUEST, ResourceGroupSagas.resourceGroupMetersAction),
];

export const Reading = [takeEvery(ReadingActions.READING_REQUEST, ReadingSagas.readDeviceReadings)];

export const Alert = [
  takeEvery(AlertActions.GET_ALERTS_REQUEST, AlertSagas.readAlertList),
  takeEvery(AlertActions.GET_ALERTS_DETAIL_REQUEST, AlertSagas.readAlertListByIds),
  takeEvery(AlertActions.CHANGE_ALERT_STATUS_REQUEST, AlertSagas.changeAlertStatus),
];

export const Compare = [
  takeEvery(CompareActions.GET_DEVICE_FOR_COMPARE_REQUEST, CompareSagas.getDeviceForCompare),
  takeEvery(CompareActions.GET_READINGS_FOR_COMPARE_REQUEST, CompareSagas.readDeviceReadings),
  takeEvery(CompareActions.REFRESH_COMPARE_READINGS, CompareSagas.refreshCompareReadings),
  takeEvery(CompareActions.DEVICES_FROM_MANAGE_VIEW, CompareSagas.setDevicesFromManageView),
];

export const Reports = [
  takeEvery(ReportActions.GET_REPORTS_REQUEST, ReportSagas.getReportsData),
  takeEvery(ReportActions.GET_REPORTING_SENSORS_REQUEST, ReportSagas.getReportingSensors),
  takeEvery(ReportActions.GET_REPORT_CSV_REQUEST, ReportSagas.getReportsCSVData),
  takeEvery(ReportActions.GET_METER_HISTORY_REQUEST, ReportSagas.getMeterHistoryCSVData),
  takeEvery(ReportActions.GET_DEVICE_FOR_METER_HISTORY_REQUEST, ReportSagas.getDeviceForMeterHistory),
];

export const Common = [takeEvery(CommonActions.GET_API_VERSION, CommonSagas.getApiVersion)];

export const Sensor = [takeEvery(SensorActions.GET_SENSORS_REQUEST, SensorSagas.readSensorList)];

export const DeviceEventLog = [
  takeEvery(DeviceEventLogActions.GET_DEVICE_EVENT_LOG_REQUEST, DeviceEventLogSagas.getDeviceEventLogList),
  takeEvery(DeviceEventLogActions.ADD_DEVICE_EVENT_LOG_REQUEST, DeviceEventLogSagas.addDeviceEventLog),
];

export const Dashboard = [
  takeEvery(DashboardActions.GET_OUTSIDE_TEMP_REQUEST, DashboardSagas.getOutsideTemperatureReading),
  takeEvery(DashboardActions.GET_STATION_TEMP_REQUEST, DashboardSagas.getStationTemperatureReading),
  takeEvery(DashboardActions.GET_NO_READINGS_COUNT_REQUEST, DashboardSagas.getNoReadingsCount),
  takeEvery(DashboardActions.GET_ALERTS_COUNT_REQUEST, DashboardSagas.getAlertsCount),
  takeEvery(DashboardActions.GET_POWER_READINGS_REQUEST, DashboardSagas.getPowerReadings),
  takeEvery(DashboardActions.GET_FLOW_READINGS_REQUEST, DashboardSagas.getFlowReadings),
  takeEvery(DashboardActions.GET_PLANT_POWER_READINGS_REQUEST, DashboardSagas.getPlantPowerReadings),
  takeEvery(DashboardActions.GET_PLANT_FLOW_OUT_READINGS_REQUEST, DashboardSagas.getPlantFlowOutReadings),
];

export const ArchiveDevice = [takeEvery(ArchiveDeviceActions.UPDATE_DEVICE_ARCHIVE_REQUEST, ArchiveDeviceSagas.updateDeviceArchive)];

export default function* RootSaga() {
  yield all([...DeviceList]);
  yield all([...Reading]);
  yield all([...ResourceGroup]);
  yield all([...Alert]);
  yield all([...Compare]);
  yield all([...Common]);
  yield all([...Sensor]);
  yield all([...Reports]);
  yield all([...DeviceEventLog]);
  yield all([...Dashboard]);
  yield all([...ArchiveDevice]);
}
