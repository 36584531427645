import React from 'react';

import AlertTable from '../../Components/Table/AlertTable/AlertTable';

export default function AlertsView() {
  return (
    <div className='grid grid--center-lg'>
      <div className='grid__col--lg-11 margin'>
        <AlertTable />
      </div>
    </div>
  );
}
