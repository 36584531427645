import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchBox from '../Inputs/SearchBox/SearchBox';

export default function DeviceEventLogFilter(props) {
  const { t } = useTranslation();

  const { sortConfig, setSortConfig } = props;

  const handleSearchClick = value => {
    setSortConfig({ ...sortConfig, currentPage: 1, searchStr: value });
  };

  return (
    <div className='grid'>
      <div className='grid__col--sm-6 grid__col--md-3'>
        <SearchBox
          onClick={value => handleSearchClick(value)}
          placeholder={t('navigation.manage.meter_list.search.overall')}
          component={'deviceEventLog'}
          action={'deviceEventLogList'}
          showClearBtn={true}
        />
      </div>
    </div>
  );
}
