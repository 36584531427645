import { createReducer } from '@reduxjs/toolkit';

import * as AlertActions from './AlertActions';
import { createUniqueId } from '../../Helpers/HelperMethods';
import { AlertSorting, CommonSorting } from '../../Helpers/Enums';

const initialState = {
  isAlertGroupsLoading: false,
  alertGroups: [],
  hasAlertGroups: false,
  errorMessage: '',
  alertSortConfig: {
    key: AlertSorting.maxCreatedDate,
    direction: CommonSorting.descending,
    currentPage: 1,
    perPage: 25,
    alertStatus: '',
    alertMessage: '',
    alertGroup: '',
    searchStr: '',
  },
};

const initialStateForIds = {
  isAlertsByIdLoading: false,
  alertsById: [],
  errorMessage: '',
};

export const AlertReducer = createReducer(initialState, {
  [AlertActions.GET_ALERTS_REQUEST]: state => {
    return {
      ...state,
      isAlertGroupsLoading: true,
      errorMessage: initialState.errorMessage,
    };
  },
  [AlertActions.GET_ALERTS_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isAlertGroupsLoading: false,
      alertGroups: payload,
      hasAlertGroups: payload && payload.length > 0,
      errorMessage: initialState.errorMessage,
    };
  },
  [AlertActions.GET_ALERTS_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isAlertGroupsLoading: false,
      alertGroups: initialState.alertGroups,
      hasAlertGroups: initialState.hasAlertGroups,
      errorMessage: payload,
    };
  },
  [AlertActions.SET_ALERT_SORT_CONFIG]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      alertSortConfig: payload,
    };
  },
  [AlertActions.CHANGE_ALERT_STATUS_REQUEST]: state => {
    return {
      ...state,
      errorMessage: initialState.errorMessage,
    };
  },
  [AlertActions.CHANGE_ALERT_STATUS_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      errorMessage: payload,
    };
  },
});

export const AlertReducerByIds = createReducer(initialStateForIds, {
  [AlertActions.GET_ALERTS_DETAIL_REQUEST]: state => {
    return {
      ...state,
      isAlertsByIdLoading: true,
      errorMessage: initialState.errorMessage,
    };
  },
  [AlertActions.GET_ALERTS_DETAIL_SUCCESS]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isAlertsByIdLoading: false,
      alertsById: createItem(JSON.stringify(state.alertsById), payload),
      errorMessage: initialState.errorMessage,
    };
  },
  [AlertActions.GET_ALERTS_DETAIL_FAILURE]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      isAlertsByIdLoading: false,
      alertsById: [],
      errorMessage: payload,
    };
  },
});

const replaceOrAddItemInList = (list, data) => {
  const newDataset = {};
  const uniqueId = createUniqueId(
    data.alerts[0].deviceId,
    data.alerts[0].sensorTypeId,
    data.alerts[0].resourceGroupId,
    data.alerts[0].alertStatus
  );
  if (list.length > 0) {
    for (const x in list) {
      if (list.hasOwnProperty(x)) {
        const item = list[x];
        item[uniqueId] = data;
      }
    }
  } else {
    newDataset[uniqueId] = data;
    list.push(newDataset);
  }
  return list;
};

export const createItem = (existingList, data) => {
  existingList = JSON.parse(existingList);
  existingList = replaceOrAddItemInList(existingList, data);
  return existingList;
};
