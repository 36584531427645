import { createAction } from '@reduxjs/toolkit';

export const RESOURCE_GROUP_REQUEST = createAction('RESOURCE_GROUP_REQUEST');
export const resourceGroupRequest = payload => RESOURCE_GROUP_REQUEST(payload);

export const RESOURCE_GROUP_FAILURE = createAction('RESOURCE_GROUP_FAILURE');
export const resourceGroupFailure = payload => RESOURCE_GROUP_FAILURE(payload);

export const GET_ALL_RESOURCE_GROUPS_REQUEST = createAction('GET_ALL_RESOURCE_GROUPS_REQUEST');
export const getAllResourceGroupsRequest = payload => GET_ALL_RESOURCE_GROUPS_REQUEST(payload);

export const GET_ALL_RESOURCE_GROUPS_SUCCESS = createAction('GET_ALL_RESOURCE_GROUPS_SUCCESS');
export const getAllResourceGroupsSuccess = payload => GET_ALL_RESOURCE_GROUPS_SUCCESS(payload);

export const GET_ALL_RESOURCE_GROUPS_FAILURE = createAction('GET_ALL_RESOURCE_GROUPS_FAILURE');
export const getAllResourceGroupsFailure = payload => GET_ALL_RESOURCE_GROUPS_FAILURE(payload);

export const RESOURCE_GROUP_METERS_ACTION_REQUEST = createAction('RESOURCE_GROUP_METERS_ACTION_REQUEST');
export const resouceGroupMetersActionRequest = payload => RESOURCE_GROUP_METERS_ACTION_REQUEST(payload);

export const RESOURCE_GROUP_METERS_ACTION_SUCCESS = createAction('RESOURCE_GROUP_METERS_ACTION_SUCCESS');
export const resouceGroupMetersActionSuccess = payload => RESOURCE_GROUP_METERS_ACTION_SUCCESS(payload);

export const RESOURCE_GROUP_METERS_ACTION_FAILURE = createAction('RESOURCE_GROUP_METERS_ACTION_FAILURE');
export const resouceGroupMetersActionFailure = payload => RESOURCE_GROUP_METERS_ACTION_FAILURE(payload);
