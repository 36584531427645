import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';

import Header from '../Header/Header';
import { showWarnNotification } from '../../../Services/Notification/NotificationHandler';
import {
  camelCaseToSnakeCase,
  getGroupId,
  isMoveDisabled,
  isMoveDisabledNoPointer,
  parseSelectedRows,
  isRealResourceGroup,
} from '../../../Helpers/HelperMethods';
import { ResourceGroupCrud } from '../../../Helpers/Enums';
import * as ResourceGroupActions from '../../../Store/ResourceGroup/ResourceGroupActions';
import * as ManagementActions from '../../../Store/Management/ManagementActions';

export default function MetersToGroupModal(props) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { open, setOpen } = props;

  const { activeGroup, selectedMeters, managementSortConfig } = useSelector(state => state.management);

  const { hasResourceGroups, resourceGroups } = useSelector(state => state.resourceGroup);

  const [removeFromGroup, setRemoveFromGroup] = useState(false);
  const [removeAction, setRemoveAction] = useState(isMoveDisabled(activeGroup) ? ResourceGroupCrud.removeAll : ResourceGroupCrud.remove);
  const [groupAction, setGroupAction] = useState(ResourceGroupCrud.copy);
  const [selectedGroup, setSelectedGroup] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const saveMeters = () => {
    if (!selectedGroup && !removeFromGroup) {
      showWarnNotification(t('notification.warn.targetGroup'));
      return;
    }
    dispatch(
      ResourceGroupActions.resouceGroupMetersActionRequest({
        virtualLocationIds: parseSelectedRows(selectedMeters),
        resourceGroupId: activeGroup,
        selectedResourceGroupId: selectedGroup,
        action: getAction(),
        page: managementSortConfig.currentPage,
        size: managementSortConfig.perPage,
        sortableRow: camelCaseToSnakeCase(managementSortConfig.key),
        sortDirection: managementSortConfig.direction,
        searchStr: managementSortConfig.searchStr,
        groupId: getGroupId(activeGroup),
      })
    );
    handleClose();
    emptySelectedMeters();
  };

  const getAction = () => {
    if (!removeFromGroup) {
      return groupAction;
    } else {
      return removeAction;
    }
  };

  const emptySelectedMeters = () => {
    dispatch(ManagementActions.setSelectedMeters([]));
  };

  const handleSelector = value => {
    setGroupAction(value);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <Header headerText={t('navigation.manage.resource_group.manage_meters')} handleClose={handleClose} />
      <div className='modal__body'>
        <div className='grid__col--sm-7'>
          <fieldset className={`selector is-checked ${removeFromGroup ? 'is-disabled' : null}`}>
            <div className='selector__list'>
              <div className='selector__item'>
                <input
                  type='radio'
                  name='selector'
                  value={ResourceGroupCrud.copy}
                  id='copyMeters'
                  data-testid='copyMeters'
                  className={`selector__input ${isMoveDisabledNoPointer(activeGroup)}`}
                  onChange={() => handleSelector(ResourceGroupCrud.copy)}
                  checked={groupAction === ResourceGroupCrud.copy}
                />
                <label htmlFor='copyMeters' className='selector__indicator'>
                  {t('navigation.manage.resource_group.copy_meters')}
                </label>
              </div>
              <div className='selector__item'>
                <input
                  disabled={isMoveDisabled(activeGroup)}
                  type='radio'
                  name='selector'
                  value={ResourceGroupCrud.move}
                  id='moveMeters'
                  data-testid='moveMeters'
                  className='selector__input'
                  onChange={() => handleSelector(ResourceGroupCrud.move)}
                  checked={groupAction === ResourceGroupCrud.move}
                />
                <label htmlFor='moveMeters' className={`selector__indicator ${isMoveDisabledNoPointer(activeGroup)}`}>
                  {t('navigation.manage.resource_group.move_meters')}
                </label>
              </div>
            </div>
          </fieldset>
        </div>

        {hasResourceGroups && (
          <div className='grid__col--sm-7'>
            <div className='select '>
              <svg className='icon select__icon'>
                <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-updown`} />
              </svg>

              <label className='select__label'>{`${t('navigation.manage.resource_group.to_group')}:`}</label>
              <select
                className='select__input'
                disabled={removeFromGroup}
                onChange={event => setSelectedGroup(event.target.value)}
                defaultValue=''
              >
                <option value='' disabled />
                {resourceGroups.map(group => {
                  return (
                    activeGroup.id !== group.id &&
                    isRealResourceGroup(group) && (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    )
                  );
                })}
              </select>
            </div>
          </div>
        )}

        <div className='grid__col--sm-7'>
          <label className='checkbox checkbox--styled '>
            <input
              type='checkbox'
              value={removeFromGroup}
              checked={removeFromGroup}
              onChange={() => setRemoveFromGroup(!removeFromGroup)}
              className='checkbox__input'
            />
            <span className='checkbox__indicator'>
              <svg className='icon checkbox__icon'>
                <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#tick`} />
              </svg>
            </span>
            <span className='checkbox__label'>{t('navigation.manage.resource_group.btn_remove_selected_meters')}</span>
          </label>
        </div>

        <div className='grid__col--sm-7'>
          <div className='select '>
            <svg className='icon select__icon'>
              <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-updown`} />
            </svg>

            <select className='select__input' disabled={!removeFromGroup} onChange={event => setRemoveAction(event.target.value)}>
              {!isMoveDisabled(activeGroup) && (
                <option value={ResourceGroupCrud.remove}>
                  {t('navigation.manage.resource_group.btn_remove_from_group', {
                    name: activeGroup.name,
                  })}
                </option>
              )}
              <option value={ResourceGroupCrud.removeAll}>{t('navigation.manage.resource_group.btn_remove_from_all_groups')}</option>
            </select>
          </div>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='btn-group btn-group--eq '>
          <button className='btn' onClick={handleClose} data-testid='btnCanelMeterModal'>
            <span className='btn__text'>{t('navigation.manage.resource_group.btn_cancel')}</span>
          </button>
          <button className='btn btn--primary' onClick={saveMeters} data-testid='btnSaveMeters'>
            <span className='btn__text'>{t('navigation.manage.resource_group.btn_save')}</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
}
