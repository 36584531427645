import React from 'react';

import { getCaretIcon, requestSort } from '../../../Helpers/HelperMethods';

export default function Column(props) {
  const { text, sortKey, sortConfig, setSortConfig } = props;

  return (
    <th>
      <div className='grid grid--no-gutter pointer' onClick={() => requestSort(sortKey, sortConfig, setSortConfig)}>
        <div className='grid__col--lg-11 grid--start-xs'>{text}</div>
        <div className='grid__col--lg-1 grid--end-xs'>{getCaretIcon(sortConfig, sortKey)}</div>
      </div>
    </th>
  );
}
