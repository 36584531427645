import React from 'react';
import ManageTable from '../../Components/Table/ManageTable/ManageTable';

export default function ManageView() {
  return (
    <div className='grid grid--center-lg'>
      <div className='grid__col--md-12 margin'>
        <ManageTable />
      </div>
    </div>
  );
}
