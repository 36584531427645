import React from 'react';
import { useTranslation } from 'react-i18next';

import { NotFoundViewStyles } from './NotFoundView.styles';

export default function NotFoundView() {
  const { t } = useTranslation();

  return (
    <NotFoundViewStyles>
      <div className='notfound shadow'>
        <div className={'panel-header white'}>{'Page not found'}</div>
        <div className={'panel-body'}>
          <h1>{t('routing.404')}</h1>
        </div>
      </div>
    </NotFoundViewStyles>
  );
}
