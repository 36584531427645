import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Column from '../Column/Column';
import { MeterListSorting } from '../../../Helpers/Enums';

export default function ColumnRow(props) {
  const { t } = useTranslation();

  const { sortConfig, setSortConfig, totalElements, handleSelection, allRowsChecked } = props;
  const [checked, setChecked] = useState(allRowsChecked);

  useEffect(() => {
    setChecked(allRowsChecked);
  }, [allRowsChecked]);

  const check = event => {
    setChecked(!checked);
    handleSelection(null, event.target.checked);
  };

  return (
    <thead>
      <tr>
        <th>
          <label className='checkbox checkbox--styled '>
            <input type='checkbox' className='checkbox__input' checked={checked} onChange={e => check(e)} />
            <span className='checkbox__indicator'>
              <svg className='icon checkbox__icon'>
                <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#tick`} />
              </svg>
            </span>
          </label>
        </th>
        <th />
        {Object.keys(MeterListSorting).map(o => {
          return (
            <Column
              key={MeterListSorting[o]}
              text={t(`navigation.manage.manage_table.${[MeterListSorting[o]]}`)}
              sortKey={MeterListSorting[o]}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
            />
          );
        })}
        <th />
        <th />
        <th />
        <th>
          <span className='badge badge--grey'>{totalElements}</span>
        </th>
      </tr>
    </thead>
  );
}
