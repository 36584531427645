import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import DateFnsUtils from '@date-io/date-fns';

import * as AlertActions from '../../../Store/Alert/AlertActions';
import { PostponeAlertModalStyles } from './PostponeAlertModal.styles';
import { AlertStatusEnumMap } from '../../../Helpers/Enums';
import Header from '../Header/Header';

export default function PostponeAlertModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { open, setOpen, alertsToPostpone } = props;
  const [effectiveUntil, setEffectiveUntil] = useState(moment(moment.now()).add('1', 'days').format());

  const handleClose = () => {
    setOpen(false);
  };

  const buttonDisabled = () => {
    return (
      !alertsToPostpone ||
      alertsToPostpone.length === 0 ||
      moment(moment.now()).add('7', 'days').isBefore(effectiveUntil) ||
      moment(moment.now()).isAfter(effectiveUntil)
    );
  };

  const handleDateChange = value => {
    setEffectiveUntil(moment(value).format());
  };

  const changeAlertStatus = () => {
    dispatch(
      AlertActions.changeAlertStatus({
        alerts: alertsToPostpone,
        effectiveUntil: effectiveUntil,
        newStatus: AlertStatusEnumMap['1'],
      })
    );
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <PostponeAlertModalStyles>
        <Header headerText={t('navigation.alerts.alert_list.postpone')} handleClose={handleClose} />
        <div className='modal__body'>
          <div className='grid'>
            <div className='grid__col--sm-4'>
              <span className='h4'>{t('navigation.alerts.postpone_until')}</span>
            </div>
            <div className='grid__col--sm-8'>
              <div>
                <MuiPickersUtilsProvider utils={DateFnsUtils} className={'date-picker'}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='dd.MM.yyyy HH:mm'
                    margin='normal'
                    id='date-picker-inline'
                    inputProps={{ 'data-testid': 'date-picker' }}
                    value={moment(effectiveUntil).valueOf()}
                    onChange={handleDateChange}
                    autoOk
                    disablePast
                    maxDate={moment(moment.now()).add('7', 'days')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
        </div>
        <div className='modal__footer'>
          <div className='btn-group btn-group--eq '>
            <button className='btn' onClick={handleClose}>
              <span className='btn__text'>{t('navigation.manage.resource_group.btn_cancel')}</span>
            </button>
            <button className='btn btn--primary' disabled={buttonDisabled()} onClick={changeAlertStatus} data-testid='postponeBtn'>
              <span className='btn__text'>{t('navigation.alerts.alert_list.postpone')}</span>
            </button>
          </div>
        </div>
      </PostponeAlertModalStyles>
    </Dialog>
  );
}
