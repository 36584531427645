import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';

import StartEndDateCombo from '../../Inputs/DateTimePicker/StartEndDateCombo';
import SearchBox from '../../Inputs/SearchBox/SearchBox';
import MeterList from './MeterList';
import Header from '../Header/Header';
import Loader from '../../Loader/Loader';
import { showWarnNotification } from '../../../Services/Notification/NotificationHandler';
import * as ReportActions from '../../../Store/Report/ReportActions';

export default function MeterHistoryModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { open, setOpen } = props;

  const { meterHistoryDates, devicesForMeterHistoryLoading, devicesForMeterHistory, meterHistoryLoading } = useSelector(
    state => state.reports
  );

  const [startDate, setStartDate] = useState(meterHistoryDates.startDate);
  const [endDate, setEndDate] = useState(meterHistoryDates.endDate);

  useEffect(() => {
    updateDates();
    // eslint-disable-next-line
  }, [startDate, endDate]);

  const handleClick = value => {
    if (devicesForMeterHistory.length > 4) {
      showWarnNotification(t('notification.warn.max5Devices'));
      return;
    }
    if (value) {
      dispatch(
        ReportActions.getDeviceForMeterHistoryRequest({
          searchStr: value,
        })
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateDates = () => {
    dispatch(
      ReportActions.setMeterHistoryDates({
        startDate: startDate,
        endDate: endDate,
      })
    );
  };

  const handleDownload = () => {
    if (moment(startDate).isAfter(moment(endDate))) {
      showWarnNotification(t('notification.warn.wrongDates'));
      return;
    } else if (!devicesForMeterHistory || devicesForMeterHistory.length === 0) {
      showWarnNotification(t('notification.warn.metersToGroupModal'));
      return;
    }
    dispatch(ReportActions.getMeterHistoryRequest({ meterHistoryDates, devicesForMeterHistory }));
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <>
        <Header headerText={t('navigation.reports.meter_history')} handleClose={handleClose} />
        <div className='modal__body'>
          <StartEndDateCombo startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
          <hr className='separator separator--small' />
          <div className='grid'>
            <div className='grid__col--sm-8'>
              <SearchBox
                showClearBtn
                showLabel
                onClick={value => handleClick(value)}
                placeholder={t('navigation.manage.meter_list.search.meter_id')}
                emptyAfterSearch
              />
            </div>
            <div className='grid__col--sm-12'>{!devicesForMeterHistoryLoading ? <MeterList /> : <Loader />}</div>
          </div>
        </div>
        <div className='modal__footer'>
          <div className='btn-group btn-group--eq '>
            <button className='btn' onClick={handleClose}>
              <span className='btn__text'>{t('navigation.manage.resource_group.btn_cancel')}</span>
            </button>
            {!meterHistoryLoading && (
              <button className='btn btn--primary' data-testid='deviceEventLogBtn' onClick={handleDownload}>
                <span className='btn__text'>{t('navigation.reports.export_xls')}</span>
              </button>
            )}
            {meterHistoryLoading && <Loader />}
          </div>
        </div>
      </>
    </Dialog>
  );
}
