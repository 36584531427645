import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ExpandedRow from '../ExpandedRow/ExpandedRow';
import { createUniqueId, getAlertStatus, getAlertType } from '../../../../Helpers/HelperMethods';
import * as AlertActions from '../../../../Store/Alert/AlertActions';
import { dateTimeFormat } from '../../../../Helpers/Common';
import { ROUTE_PATHS } from '../../../../Routes/Routes';
import { AlertDifferenceTypeEnum } from '../../../../Helpers/Enums';

export default function DataRow(props) {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useDispatch();

  const { sortedTable, openPostponeAlertModal, openDeviceEventLogModal, openCloseAlertModal } = props;

  const getAlertsById = item => {
    dispatch(
      AlertActions.getAlertsDetailRequest({
        virtualLocationId: item.virtualLocationId,
        deviceId: item.deviceId,
        sensorTypeId: item.sensorTypeId ? item.sensorTypeId : '',
        resourceGroupId: item.resourceGroupId ? item.resourceGroupId : '',
        alertStatus: getAlertStatus(item.alertStatus),
        alertType: getAlertType(item.alertType),
      })
    );
  };

  const goToGraphPage = (virtualLocationId, deviceId) => {
    history.push(`${ROUTE_PATHS.GRAPHICS}?vlId=${virtualLocationId}&deviceId=${deviceId}`);
  };

  const getThresholdMsg = parseAlertData => {
    if (parseAlertData.threshold) {
      if (parseAlertData.differenceType === AlertDifferenceTypeEnum.PERCENTAGE) {
        return `> ${parseAlertData.threshold}%`;
      } else {
        return `> ${parseAlertData.threshold}`;
      }
    }
    return '';
  };

  return (
    <tbody>
      {sortedTable.map((item, index) => {
        const parseAlertData = JSON.parse(item.alertData);
        const uniqueId = createUniqueId(item.deviceId, item.sensorTypeId, item.resourceGroupId, item.alertStatus);
        return (
          <React.Fragment key={index}>
            <tr>
              <td>{parseAlertData.deviceAddress}</td>
              <td>
                <span className='badge badge--grey'>{item.count}</span>
              </td>
              <td>{moment(item.maxCreatedDate).format(dateTimeFormat)}</td>
              <td>
                {t(`navigation.alerts.alert_type.${item.alertType}`, {
                  readingValue: parseFloat(parseAlertData.readingValue).toFixed(2),
                  sensorTypeName: item.sensorTypeName,
                  threshold: getThresholdMsg(parseAlertData),
                })}
              </td>
              <td>{t(`navigation.alerts.alert_status.${item.alertStatus}`)}</td>
              <td>
                <button
                  className='btn btn--icon'
                  onClick={() => goToGraphPage(item.virtualLocationId, item.deviceId)}
                  data-testid={'btnGoToGraph'}
                >
                  <svg className='icon  btn__icon'>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/iconsELV.svg#data-lg`} />
                  </svg>
                </button>
              </td>
              <td className='cell--icon'>
                <button
                  className='btn btn--icon collapse-trigger collapsed'
                  data-toggle='collapse'
                  data-target={`#${uniqueId}`}
                  onClick={() => getAlertsById(item)}
                  data-testid={'btnGetAlertsById'}
                >
                  <svg className='icon  btn__icon collapse-trigger__icon'>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#caret-down`} />
                  </svg>
                </button>
              </td>
            </tr>
            <ExpandedRow
              id={uniqueId}
              resourceGroupName={item.resourceGroupName}
              meterId={parseAlertData.meterId}
              deviceIdentifier={parseAlertData.deviceIdentifier}
              deviceInfo={parseAlertData.deviceInfo}
              openPostponeAlertModal={openPostponeAlertModal}
              openDeviceEventLogModal={openDeviceEventLogModal}
              openCloseAlertModal={openCloseAlertModal}
            />
          </React.Fragment>
        );
      })}
    </tbody>
  );
}
