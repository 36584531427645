import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js/stable';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'moment/locale/et';
import './i18n';

import App from 'Components/App/App';
import Loader from 'Components/Loader/Loader';
import { Provider } from 'react-redux';
import { store } from 'Store/store';
import { userLocale } from './Services/Development/Development';

moment.locale(userLocale);

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById('multimetering')
);
