import styled from 'styled-components';

export const AppMainStyles = styled.div`
  .img-responsive {
    max-width: 200px;
  }

  .footer {
    margin-top: 1em;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    background-color: var(--sidebar-color);
  }

  .version {
    color: var(--white);
    span {
      margin-right: 0.5em;
    }
  }
`;
