import React from 'react';

import ReportTable from '../../Components/Table/ReportTable/ReportTable';
import { ReportsViewStyles } from './ReportsView.styles';
import ReportsCSV from './ReportsCSV';

export default function ReportsView() {
  return (
    <ReportsViewStyles>
      <div className='grid grid--center-lg'>
        <div className='grid__col--lg-11 margin'>
          <ReportsCSV />
          <ReportTable />
        </div>
      </div>
    </ReportsViewStyles>
  );
}
