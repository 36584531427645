import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';

import Header from '../Header/Header';
import GroupParameterTable from '../../Table/GroupParameterTable/GroupParameterTable';
import { showWarnNotification } from '../../../Services/Notification/NotificationHandler';
import {
  getGroupId,
  getText,
  handleContractCheckbox,
  hasActiveGroup,
  isRealResourceGroup,
  isModeEdit,
} from '../../../Helpers/HelperMethods';
import { CustomAlertNames } from '../../../Helpers/Enums';
import * as ResourceGroupActions from '../../../Store/ResourceGroup/ResourceGroupActions';
import * as ManagementActions from '../../../Store/Management/ManagementActions';

export default function ResourceGroupModal(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { open, setOpen, mode, existingItem, confirmFunc } = props;

  const { sensors } = useSelector(state => state.sensor);

  const [name, setName] = useState(isModeEdit(mode) ? existingItem.name : '');
  const [description, setDescription] = useState(isModeEdit(mode) ? existingItem.description : '');
  const [alertCriteries, setAlertCriteries] = useState(
    isModeEdit(mode) ? existingItem.alertConfigData : { rows: sensors, customAlertNames: [] }
  );

  const resetValues = () => {
    setName('');
    setDescription('');
  };

  const handleClose = () => {
    resetValues();
    setOpen(false);
  };

  const handleModalAction = () => {
    if (!name || !description) {
      showWarnNotification(t('notification.warn.nameDescRequired'));
      return;
    }
    dispatch(
      ResourceGroupActions.resourceGroupRequest({
        description: description,
        name: name,
        id: existingItem && isRealResourceGroup(existingItem) ? getGroupId(existingItem) : null,
        mode: mode,
        alertConfig: alertCriteries,
      })
    );
    updateActiveGroup();
    handleClose();
  };

  const updateActiveGroup = () => {
    dispatch(
      ManagementActions.setActiveGroup({
        ...existingItem,
        name: name,
        description: description,
        alertConfigData: alertCriteries,
      })
    );
  };

  const isContractChecked = () => {
    if (alertCriteries.customAlertNames) {
      return alertCriteries.customAlertNames.includes(CustomAlertNames.CONTRACT_KEY);
    }
    return false;
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
      <Header headerText={t(getText(mode))} handleClose={handleClose} />
      <div className='modal__body'>
        <div className='grid__col--sm-7'>
          <div className='textfield '>
            <label className='textfield__label' htmlFor='input-1'>
              {t('navigation.manage.resource_group.group_name')}
            </label>
            <input
              type='text'
              className='textfield__input '
              data-testid='resourceGroupName'
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </div>
        </div>
        <div className='grid__col--sm-7'>
          <div className='textfield '>
            <label className='textfield__label' htmlFor='input-1'>
              {t('navigation.manage.resource_group.group_desc')}
            </label>
            <input
              type='text'
              className='textfield__input '
              data-testid='resourceGroupDescription'
              value={description}
              onChange={event => setDescription(event.target.value)}
            />
          </div>
        </div>
        <div className='grid__col--sm-12'>
          <label className='textfield__label' htmlFor='input-1'>
            {t('navigation.manage.resource_group.table.parameters')}
          </label>
          <GroupParameterTable alertCriteries={alertCriteries} setAlertCriteries={setAlertCriteries} />
        </div>
        <div className='grid__col--sm-12'>
          <label className='checkbox checkbox--styled '>
            <input
              checked={isContractChecked()}
              onChange={event => handleContractCheckbox(event, alertCriteries, setAlertCriteries)}
              type='checkbox'
              id='contractCheckbox'
              name='checkbox'
              className='checkbox__input'
              data-testid='contractCheckbox'
            />
            <span className='checkbox__indicator'>
              <svg className='icon checkbox__icon'>
                <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#tick`} />
              </svg>
            </span>
            <span className='checkbox__label'>{t('navigation.manage.resource_group.contract_values')}</span>
          </label>
        </div>
      </div>
      <div className='modal__footer'>
        <div className='btn-group btn-group--eq '>
          <div className='btn-group btn-group--left '>
            <button className='btn' onClick={handleClose} data-testid='btnCancelGroupEdit'>
              <span className='btn__text'>{t('navigation.manage.resource_group.btn_cancel')}</span>
            </button>
            {hasActiveGroup(existingItem) && isRealResourceGroup(existingItem) && (
              <button className='btn btn--link' onClick={confirmFunc}>
                <span className='btn__text'>{t('navigation.manage.resource_group.btn_delete')}</span>
              </button>
            )}
          </div>
          <button className='btn btn--primary' onClick={handleModalAction} data-testid='editGroupBtn'>
            <span className='btn__text'>{t('navigation.manage.resource_group.btn_save')}</span>
          </button>
        </div>
      </div>
    </Dialog>
  );
}

ResourceGroupModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  mode: PropTypes.string,
  existingItem: PropTypes.object,
  confirmFunc: PropTypes.func,
};
