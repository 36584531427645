import { put } from 'redux-saga/effects';

import * as CommonActions from './CommonActions';
import { API_ENDPOINTS, getData } from '../../Services/Fetch/FetchService';

export function* getApiVersion() {
  try {
    const response = yield getData(API_ENDPOINTS.API_VERSION);
    if (response.status === 200) {
      const data = yield response.data;
      yield put(CommonActions.setApiVersion(data));
    }
  } catch (error) {
    yield put(CommonActions.setApiVersion('0.0.0'));
  }
}
