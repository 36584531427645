import { createReducer } from '@reduxjs/toolkit';

import packageJson from '../../../package.json';
import * as CommonActions from './CommonActions';

const initialState = {
  apiVersion: '',
  clientVersion: '',
};

export const CommonReducer = createReducer(initialState, {
  [CommonActions.SET_API_VERSION]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      apiVersion: payload.apiVersion,
      clientVersion: packageJson.version,
    };
  },
});
