import { createAction } from '@reduxjs/toolkit';

export const GET_REPORTS_REQUEST = createAction('GET_REPORTS_REQUEST');
export const getReportsRequest = payload => GET_REPORTS_REQUEST(payload);

export const GET_REPORTS_SUCCESS = createAction('GET_REPORTS_SUCCESS');
export const getReportsSuccess = payload => GET_REPORTS_SUCCESS(payload);

export const GET_REPORTS_FAILURE = createAction('GET_REPORTS_FAILURE');
export const getReportsFailure = payload => GET_REPORTS_FAILURE(payload);

export const SET_REPORTS_SORTED_DATA = createAction('SET_REPORTS_SORTED_DATA');
export const setReportsSortedAndFilteredData = payload => SET_REPORTS_SORTED_DATA(payload);

export const SET_REPORT_SORT_CONFIG = createAction('SET_REPORT_SORT_CONFIG');
export const setReportSortConfig = payload => SET_REPORT_SORT_CONFIG(payload);

export const GET_REPORTING_SENSORS_REQUEST = createAction('GET_REPORTING_SENSORS_REQUEST');
export const getReportingSensorsRequest = payload => GET_REPORTING_SENSORS_REQUEST(payload);

export const GET_REPORTING_SENSORS_SUCCESS = createAction('GET_REPORTING_SENSORS_SUCCESS');
export const getReportingSensorsSuccess = payload => GET_REPORTING_SENSORS_SUCCESS(payload);

export const GET_REPORTING_SENSORS_FAILURE = createAction('GET_REPORTING_SENSORS_FAILURE');
export const getReportingSensorsFailure = payload => GET_REPORTING_SENSORS_FAILURE(payload);

export const GET_REPORT_CSV_REQUEST = createAction('GET_REPORT_CSV_REQUEST');
export const getReportCSVRequest = payload => GET_REPORT_CSV_REQUEST(payload);

export const GET_REPORT_CSV_SUCCESS = createAction('GET_REPORT_CSV_SUCCESS');
export const getReportCSVSuccess = payload => GET_REPORT_CSV_SUCCESS(payload);

export const GET_REPORT_CSV_FAILURE = createAction('GET_REPORT_CSV_FAILURE');
export const getReportCSVFailure = payload => GET_REPORT_CSV_FAILURE(payload);

export const SET_REPORT_CSV_DATE = createAction('SET_REPORT_CSV_DATE');
export const setReportCSVDate = payload => SET_REPORT_CSV_DATE(payload);

export const CLEAR_REPORT_CSV_DATA = createAction('CLEAR_REPORT_CSV_DATA');
export const clearReportCSVData = payload => CLEAR_REPORT_CSV_DATA(payload);

export const SET_REPORTS_EXPORT_TABLE_CSV_DATA = createAction('SET_REPORTS_EXPORT_TABLE_CSV_DATA');
export const setReportsExportTableCSVData = payload => SET_REPORTS_EXPORT_TABLE_CSV_DATA(payload);

export const CLEAR_REPORTS_EXPORT_TABLE_CSV_DATA = createAction('CLEAR_REPORTS_EXPORT_TABLE_CSV_DATA');
export const clearReportsExportTableCSVData = payload => CLEAR_REPORTS_EXPORT_TABLE_CSV_DATA(payload);

export const SET_METER_HISTORY_DATES = createAction('SET_METER_HISTORY_DATES');
export const setMeterHistoryDates = payload => SET_METER_HISTORY_DATES(payload);

export const GET_DEVICE_FOR_METER_HISTORY_REQUEST = createAction('GET_DEVICE_FOR_METER_HISTORY_REQUEST');
export const getDeviceForMeterHistoryRequest = payload => GET_DEVICE_FOR_METER_HISTORY_REQUEST(payload);

export const GET_DEVICE_FOR_METER_HISTORY_SUCCESS = createAction('GET_DEVICE_FOR_METER_HISTORY_SUCCESS');
export const getDeviceForMeterHistorySuccess = payload => GET_DEVICE_FOR_METER_HISTORY_SUCCESS(payload);

export const GET_DEVICE_FOR_METER_HISTORY_FAILURE = createAction('GET_DEVICE_FOR_METER_HISTORY_FAILURE');
export const getDeviceForMeterHistoryFailure = payload => GET_DEVICE_FOR_METER_HISTORY_FAILURE(payload);

export const REMOVE_DEVICE_FROM_METER_HISTORY = createAction('REMOVE_DEVICE_FROM_METER_HISTORY');
export const removeDeviceFromMeterHistory = payload => REMOVE_DEVICE_FROM_METER_HISTORY(payload);

export const GET_METER_HISTORY_REQUEST = createAction('GET_METER_HISTORY_REQUEST');
export const getMeterHistoryRequest = payload => GET_METER_HISTORY_REQUEST(payload);

export const GET_METER_HISTORY_SUCCESS = createAction('GET_METER_HISTORY_SUCCESS');
export const getMeterHistorySuccess = payload => GET_METER_HISTORY_SUCCESS(payload);

export const GET_METER_HISTORY_FAILURE = createAction('GET_METER_HISTORY_FAILURE');
export const getMeterHistoryFailure = payload => GET_METER_HISTORY_FAILURE(payload);
