import { createAction } from '@reduxjs/toolkit';

export const GET_ALERTS_REQUEST = createAction('GET_ALERTS_REQUEST');
export const getAlertsRequest = payload => GET_ALERTS_REQUEST(payload);

export const GET_ALERTS_SUCCESS = createAction('GET_ALERTS_SUCCESS');
export const getAlertsSuccess = payload => GET_ALERTS_SUCCESS(payload);

export const GET_ALERTS_FAILURE = createAction('GET_ALERTS_FAILURE');
export const getAlertsFailure = payload => GET_ALERTS_FAILURE(payload);

export const GET_ALERTS_DETAIL_REQUEST = createAction('GET_ALERTS_DETAIL_REQUEST');
export const getAlertsDetailRequest = payload => GET_ALERTS_DETAIL_REQUEST(payload);

export const GET_ALERTS_DETAIL_SUCCESS = createAction('GET_ALERTS_DETAIL_SUCCESS');
export const getAlertsDetailSuccess = payload => GET_ALERTS_DETAIL_SUCCESS(payload);

export const GET_ALERTS_DETAIL_FAILURE = createAction('GET_ALERTS_DETAIL_FAILURE');
export const getAlertsDetailFailure = payload => GET_ALERTS_DETAIL_FAILURE(payload);

export const SET_ALERT_SORT_CONFIG = createAction('SET_ALERT_SORT_CONFIG');
export const setAlertSortConfig = payload => SET_ALERT_SORT_CONFIG(payload);

export const CHANGE_ALERT_STATUS_REQUEST = createAction('CHANGE_ALERT_STATUS_REQUEST');
export const changeAlertStatus = payload => CHANGE_ALERT_STATUS_REQUEST(payload);

export const CHANGE_ALERT_STATUS_FAILURE = createAction('CHANGE_ALERT_STATUS_FAILURE');
export const changeAlertStatusFailure = payload => CHANGE_ALERT_STATUS_FAILURE(payload);
