import React from 'react';
import * as ReportActions from '../../../Store/Report/ReportActions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAddress, getMeterId } from '../../../Helpers/HelperMethods';

export default function MeterList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { devicesForMeterHistory } = useSelector(state => state.reports);

  const removeSeries = deviceIdentifier => {
    dispatch(ReportActions.removeDeviceFromMeterHistory(deviceIdentifier));
  };

  return (
    <React.Fragment>
      {devicesForMeterHistory.map(device => {
        return (
          <React.Fragment key={device.id}>
            <hr className='separator ' />
            <div className='grid grid--no-gutter marginsmth'>
              <div className='grid__col--lg-7'>
                <p>{getAddress(device.address)}</p>
              </div>
              <div className='grid__col--lg-4'>
                <p>
                  {t('navigation.compare.meterId')} {getMeterId(device.meterId)}
                </p>
              </div>
              <div className='grid__col--lg-1'>
                <button
                  className='btn btn--border btn--xs'
                  data-testid='removeDeviceIdentifier'
                  onClick={() => {
                    removeSeries(device.identifier);
                  }}
                >
                  <svg className='icon'>
                    <use xlinkHref={`${process.env.PUBLIC_URL}/assets/svg/icons.svg#x`} />
                  </svg>
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}
